import React from 'react';
import './progress.scss';

const ProgressBar = (props) => {

  const { bgcolor, completed } = props;

  const fillerStyles = {
    width: `${completed}%`,
    backgroundColor: bgcolor
  };

  return (
    <>
      <div className="progress-wrapper">
        <div className="progress-wrapper-child" style={fillerStyles}>
          <span className="progress-wrapper-text"></span>
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
