import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import rightArrow from '../../../assets/image/rightArrow.svg';
import { setHeaderTitle } from "../../../redux/actions/title.action";
import { connect } from "react-redux";

function Breadcrumb(props) {

  const { title, setHeaderTitle } = props;
  const [breadcrums, setBreadcrumbs] = useState(null);

  useEffect(() => {
    setBreadcrumbs(title.breadCrumb);
  }), [title.breadCrumb];

  const history = useHistory();

  const pushToDirect = () => {
    const val = pageTitleThree.split(" ")[1];
    if (val == undefined) {
      // console.log('pageTitleThree', val);
    } else {
      const filter = val.substring(1, pageTitleThree.split(" ")[1].length - 1).trim();
      history.push(filter);
    }
  }

  // console.log('breadcrumb from props', title);
  // console.log('breadcrumb from state', breadcrums);

  function toHome(){
    history.push('/home');
    const breadcrumbSteps = {
      breadOne: "Home",
      breadTwo: "Dashboard",
      breadThree: null
    }
    setHeaderTitle(breadcrumbSteps);
  }

  return (
    <>
      {breadcrums !== null ?
        <>
          <p className="breadcrumb-text" onClick={toHome} > {breadcrums.breadOne}</p>

          {breadcrums.breadTwo !== null ?
            <>
              <div className="breadcrumb-icon">
                <img src={rightArrow} alt="rightArrow" />
              </div>

              <p className="breadcrumb-text" >{breadcrums.breadTwo} </p>
            </>
            : null}

          {breadcrums.breadThree !== null ?
            <>
              <div className="breadcrumb-icon">
                <img src={rightArrow} alt="rightArrow" />
              </div>

              <p className="breadcrumb-text" >{breadcrums.breadThree} </p>
            </>
            : null}

        </>
        : null}
    </>
  );
}

const mapStateToProps = (state) => ({
  title: state.title
});


const mapDispatchToProps = {
  setHeaderTitle: setHeaderTitle
};

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb);
