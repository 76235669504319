import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useSession } from 'react-use-session';
import Modal from '@material-ui/core/Modal';

import { UserService } from '../../utils/userServices';

import bgBlue from '../../assets/image/bg-blue.png';
import thunderBolt from '../../assets/image/thunder-bolt-poster.png';
import executiveProtection from '../../assets/image/executive.png';

import CircularProgress from '@material-ui/core/CircularProgress';
import { apiErrorHandler } from '../../utils/helper';
import Demo from './Demo';
import Athena from './athena';

import './thunderbolt.scss';

const LandingPage = (props) => {

  const history = useHistory();
  const { session } = useSession("cyber-security");
  const sessionToken = session.token;

  const { setClickedService, clickedService, setPageTitleOne, setPageTitleTwo, setPageTitleThree } = props;

  const [modalState, setModalState] = useState(false);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setPageTitleOne("Home");
    setPageTitleTwo("");
    setPageTitleThree("");
  }, [0]);

  async function directToThunder() {
    setLoading(true);
    const apiResponse = await UserService.getServices(sessionToken);
    setLoading(false);
    if (apiResponse.status === 200) {
      direct(apiResponse.data.data.thunderbolt)
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  function direct(dataWith) {

    history.push({
      pathname: `/subscription`,
      state: {
        selectedOne: dataWith
      }
    });

  }


  function openMadalFun() {
    setModalState(true);
  }

  function closeReviewMadal() {
    setModalState(false);
  }

  return (
    <>
      <Athena setClickedService={setClickedService} clickedService={clickedService} />
      <div className="landing-wrapper mt-32px">
        <div className="athena-block">

          <div className="athen-info-wrapper">
            <div className="athena-image-container">
              <img src={thunderBolt} alt="athena poster" />
            </div>
            <div className="athena-text-container">
              <div>
                <div className="athena-header">Thunder Bolt </div>
                <div className="athena-info-text">
                  Thunderbolt is Al and ML-powered digital identity protection platform. It safeguards users' digital identities and helps them maintain a healthy security posture. It is designed to provide comprehensive protection for executives and VIPs. With real-time monitoring, proactive incident response, and extensive threat intelligence, Thunderbolt ensures the safety and security of crucial personnel from targeted cyberattacks and digital risks. Leveraging cutting-edge Al and ML algorithms, the platform continuously assesses and mitigates potential threats, keeping executives one step ahead of cyber adversaries.
                </div>

                {/* <div className="athena-info-text pt-5">
                  Thunderbolt looks at user privacy, cyber safety and fraud prevention to help users sustain a healthy digital well being.
                </div> */}
              </div>
            </div>
          </div>

        </div>

        <div className="athena-offers">What our product Thunder Bolt?</div>

        <div className="product-block">

          <div className="product-item h-auto py-4" style={{ backgroundImage: `url(${bgBlue})` }} >
            <div className="detail-section">
              <div>
                <div className="item-title">Executive <br />Protection Monitoring</div>
                <div className="item-description text-justify">
                  {/* By instantly identifying and remediating cyber and physical vulnerabilities throughout your organization's publicly available attack surface, Athena's artificial intelligence-driven platform allows you to secure your executives and VIPs. */}

                  Thunderbolt is designed to safeguard executives and VIPs from digital risks. Thunderbolt ensures proactive
                  detection and swift response to potential Digital Identity threats. 24/7 surveillance and customizable
                  reporting empower organizations to maintain a strong security posture for their top-level personnel,
                  preserving the brand reputation and staying ahead of evolving threats. It is focussed around providing a
                  holistic and proactive cybersecurity solution for safeguarding digital identities and protecting executives
                  and VIPs. The platform employs cutting-edge Al and ML technologies to continuously monitor and assess
                  potential risks in real-time, ensuring that crucial personnel stay one step ahead of cyber threats.

                  


                </div>
                <div className="item-action">
                  {loading === true ? <CircularProgress /> :
                    <div className="try-now" onClick={directToThunder} >Try Now</div>
                  }
                  {/* <div className="request-demo" onClick={openMadalFun} >Request Demo</div> */}
                </div>
              </div>
            </div>

            <div className="image-section">
              <img src={executiveProtection} alt="digital-risk-assessment-image" />
            </div>

          </div>

        </div>

      </div>

      <Modal
        open={modalState}
        onClose={closeReviewMadal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Demo closeReviewMadal={closeReviewMadal} />
      </Modal>

    </>
  );

}

export default LandingPage;