import React, { useEffect, useState } from "react";

import { useSession } from 'react-use-session';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from "notistack";

import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';

import StepperBuilder from "../../Components/Stepper/Stepper";
import { UserService } from '../../utils/userServices';
import { OrderService } from '../../utils/order';
import { apiErrorHandler } from '../../utils/helper';
import { AuthServices } from '../../utils/auth';
import { FeedbackService } from '../../utils/feedback';
import Feedback from './Feedback';

import happyEmogi from '../../assets/image/happyEmoji.svg';
import sadEmogi from '../../assets/image/sadEmoji.svg';
import normalEmogi from '../../assets/image/normal.svg';

import './service.scss';

function Service(props) {

  const {
    setPageTitleOne,
    setPageTitleTwo,
    setPageTitle,
    setPageTitleThree,
    setCommonPageTitleCheck,
  } = props

  const history = useHistory();

  const [reviewModal, setReviewModal] = useState(false);
  const [feedbackLoader, setFeedbackLoader] = useState(false);
  const [feedbackData, setFeedbackData] = useState({
    star: null,
    description: null
  });


  if (!props.location.state) {
    history.push('/');
    return 0;
  }

  const holdPrevPageData = props.location.state.currentData;
  const unsortedProgress = holdPrevPageData.progress;
  const sortedDataTemp = unsortedProgress.sort(({ state_id: a }, { state_id: b }) => b - a);
  const sortedData = sortedDataTemp.reverse();
  const foundIndex = sortedData.findIndex(x => x.current_status === 1);

  // console.log(' tempHold', tempHold);
  // console.log(' foundIndex', foundIndex);
  // console.log('sortedData', sortedData);


  const { session } = useSession("cyber-security");
  const { enqueueSnackbar } = useSnackbar();

  const [reports, setReports] = useState([]);

  const [filesLoading, setFilesLoading] = useState(false);
  const [files, setfiles] = useState([]);

  useEffect(async () => {
    setPageTitleOne("Home");
    setPageTitleTwo(holdPrevPageData.project);
    setPageTitleThree("Dashboard %dashboard%");
    setPageTitle("Service Name");
    setCommonPageTitleCheck(true);

    feedbackStatusCheck();

    if (holdPrevPageData.document_id !== 0) {
      setFilesLoading(true);
      await fetchFiles(holdPrevPageData.document_id);
      await fetchReports();
      setFilesLoading(false);

    } else {

      setFilesLoading(true);
      await fetchReports();
      setFilesLoading(false);

    }

  }, [0]);

  async function fetchFiles(document_id) {
    const apiResponse = await UserService.getFiles(document_id, session.token);
    if (apiResponse.status === 200) {
      setfiles(apiResponse.data.data);
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  async function fetchReports() {
    const apiResponse = await OrderService.getTicketReports(holdPrevPageData.project, session.token);
    if (apiResponse.status === 200) {
      setReports(apiResponse.data.data);
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  async function uploadFeedback(feedbackData) {
    setFeedbackLoader(true);
    const apiResponse = await AuthServices.feedbackService(feedbackData, session.token);
    setFeedbackLoader(false);
    if (apiResponse.status === 201) {
      enqueueSnackbar(apiResponse.data.message, { variant: "success" });
      shutFeedbackModal();
      history.push('/dashboard');
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  async function uploadTicketFeedback(feedbackData) {
    const apiResponse = await FeedbackService.updateTicketFeedback(feedbackData, session.token);
    if (apiResponse.status === 201) {
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  function feedbackStatusCheck() {
    if (holdPrevPageData.completion == 100) {
      if (holdPrevPageData.feedback_status == 0 || holdPrevPageData.feedback_status == null || holdPrevPageData.feedback_status == "pending" || holdPrevPageData.feedback_status == '') {
        openReviewMadal();
      }
    }
  }

  function openReviewMadal() {
    setReviewModal(true);
  }

  function setStar(starNumber) {
    setFeedbackData({ ...feedbackData, star: starNumber });
  }

  function saveComment(element) {
    setFeedbackData({ ...feedbackData, description: element.target.value });
  };


  async function submitReview() {

    if (feedbackData.star === null) {
      enqueueSnackbar("You must have to select a feedback face.", { variant: "error" });
      return 0;
    }

    const feedbackPayload = {
      role: 'user',
      user_id: session.user_id,
      ticket_id: holdPrevPageData.project,
      description: feedbackData.description,
      stars: parseInt(feedbackData.star),
      question: 0,
      representative: false,
      QAJson: ""
    }

    const updateFeedbackPayload = {
      ticket_id: holdPrevPageData.project,
      feedback_status: "taken"
    }

    // console.log('data to post ', feedbackPayload);

    await uploadTicketFeedback(updateFeedbackPayload);
    // enqueueSnackbar("Feedback Updated", { variant: "success" });
    await uploadFeedback(feedbackPayload);

  }


  function shutFeedbackModal() {
    setFeedbackData({
      star: null,
      description: null
    });
    setReviewModal(false);
  }


  async function ignoreFeedback() {

    const updateFeedbackPayload = {
      ticket_id: holdPrevPageData.project,
      feedback_status: "ignored"
    }

    await uploadTicketFeedback(updateFeedbackPayload);
    shutFeedbackModal();
  }

  async function refreshReport() {
    setFeedbackLoader(true);
    setReports([]);
    await fetchReports();
    setFeedbackLoader(false);
  }


  // console.log('pre page data ', holdPrevPageData);
  // console.log('files uploaded by user', files);
  // console.log('reports uploaded by analyst', reports);

  return (
    <>
      <div className="service-wrapper">
        <div className="service-detail-wrapper">

          <div className="service-detail">

            <div className="servie-name"> {holdPrevPageData.service} </div>

            <div className="service-step">
              {sortedData.map((stepObj, index) => {
                return (
                  <StepperBuilder
                    stepObj={stepObj}
                    index={index}
                    key={index}
                    foundIndex={foundIndex}
                  />
                );
              })}
            </div>
          </div>

          {filesLoading === true ?
            <div className="file-container">
              <div className="file-loading" ><CircularProgress /></div>
            </div>
            :
            <>
              {files.length !== 0 || reports.length !== 0 ?
                <div className="file-container">

                  {files.length !== 0 ?
                    <div className="file-uploaded-with">

                      <div className="file-uploaded-text"> Files uploaded</div>
                      {files.map((item, index) => {
                        return (
                          <div className="file-container-wrapper" key={index}>
                            <div className="file-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                              </svg>
                            </div>
                            <div className="file-upload-time">
                              <a href={item.fileurl} target="_blank" download>{item.created_at}</a>
                            </div>
                            <div style={{ wordBreak: "break-all" }} className="mt-1 px-3 text-xs text-center w-full">{item.details} </div>
                          </div>
                        );
                      })}

                    </div>
                    : null}

                  {reports.length !== 0 ?
                    <div className="file-uploaded-with">

                      <div className="file-uploaded-text"> Reports Generated</div>

                      {reports.map((item, index) => {
                        return (
                          <div className="file-container-wrapper" key={index}>
                            <div className="file-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                              </svg>
                            </div>
                            <div className="file-upload-time">
                              <a href={item.fileurl} target="_blank" download>{item.created_at}</a>
                            </div>
                            <div style={{ wordBreak: "break-all" }} className="mt-1 px-3 text-xs text-center w-full">{item.details.fileName} </div>

                            <Feedback toOprateOn={item} session={session} refreshReport={refreshReport} />

                          </div>
                        );
                      })}


                    </div>
                    : null}

                </div>
                : null}
            </>
          }

        </div>
      </div>

      <Modal
        open={reviewModal}
        onClose={shutFeedbackModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="add-user-modal">
          <div className="feedback-wrapper">

            <div className="feedback-form">

              {feedbackLoader === true ?
                <div className="feedback-loader"><CircularProgress /></div>
                : null}

              <div className="feedback-header">Your Feedback Counts</div>
              <div className="feedback-text">Thanks for choosing Athenian Tech for your assessment.</div>
              <div className="feedback-text-alt">We would really like to know your opinion.</div>
              <div className="feedback-text-three">How would you rate our service.</div>
              <div className="feedback">
                <div
                  className={`circle green  ${feedbackData.star === 3 ? 'active' : ''}`}
                  onClick={setStar.bind('', 3)}
                >
                  <img src={happyEmogi} alt="happy-emogi" />
                </div>
                <div
                  className={`circle yellow  ${feedbackData.star === 2 ? 'active' : ''}`}
                  onClick={setStar.bind('', 2)}
                >
                  <img src={normalEmogi} alt="happy-emogi" />
                </div>
                <div
                  className={`circle red  ${feedbackData.star === 1 ? 'active' : ''}`}
                  onClick={setStar.bind('', 1)}
                >
                  <img src={sadEmogi} alt="happy-emogi" />
                </div>
              </div>

              <div className="feedback-text-four">Please leave your feedback below:</div>

              <div className="feedback-form-wrapper">
                <textarea
                  name="feedback"
                  id="feedback"
                  className="feedback-textarea"
                  rows={3}
                  placeholder="I Think That ..."
                  onChange={saveComment}
                ></textarea>
              </div>

              <div className="feedback-submition">
                <div className="hit-button" onClick={submitReview}>Submit</div>
                <div className="close-modal-alt" onClick={ignoreFeedback}> Close </div>
              </div>

            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Service;
