import React, { useState } from 'react';

import { useSnackbar } from "notistack";
import { OrderService } from '../../utils/order';
import { apiErrorHandler } from '../../utils/helper';

import CircularProgress from '@material-ui/core/CircularProgress';

const Feedback = (props) => {

  const { enqueueSnackbar } = useSnackbar();
  const { toOprateOn, session, refreshReport } = props;

  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState(toOprateOn.accepted == false || toOprateOn.accepted == true ? toOprateOn.comments : "");


  async function reportFeedback(item, actionWay) {

    // console.log(' oprating on ', item);
    // console.log(' opration direction ', actionWay);

    const uploadJson = {
      report_upload_id : item.id,
      accepted : actionWay,
      comments : comment
    }

    setLoading(true);
    const apiResponse = await OrderService.updateReport(uploadJson, session.token);
    setLoading(false);
    if (apiResponse.status === 200) {
      // setfiles(apiResponse.data.data);
      refreshReport();
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }

  }


  // console.log(' comment comment ', comment);


  return (
    <>
      {loading === true ?
        <div className="flex justify-center mt-4 mb-4" ><CircularProgress /></div>
        :
        <>
          <div className="profile-input-box mt-2">
            <textarea
              className="profile-custom-input"
              name="report-feedback"
              id="report-feedback"
              placeholder="Add your feedback"
              rows={3}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              disabled={toOprateOn.accepted == false || toOprateOn.accepted == true ? true : false}
            />
          </div>
          <div className="update-form-submit">

            {toOprateOn.accepted === true ?
              <button className="update-form-submit bg-green-light" >Accepted</button>
              : null}

            {toOprateOn.accepted === false ?
              <button className="update-form-submit bg-pink-light" >Declined</button>
              : null}

            {toOprateOn.accepted === null ?
              <>
                <button className="update-form-submit bg-pink-light" onClick={reportFeedback.bind('', toOprateOn, false)} >Submit Feedback</button>
                <button className="update-form-submit" onClick={reportFeedback.bind('', toOprateOn, true)} >Accept</button>
              </>
              : null}

          </div>
        </>
      }

    </>
  );
}

export default Feedback;