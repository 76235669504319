import React, { useEffect, useState } from "react";

import { useSession } from 'react-use-session';
import { useSnackbar } from "notistack";

import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "@material-ui/core/DialogTitle";

import DashboardTable from "./DashboardTable";
import { UserService } from '../../utils/userServices';
import { apiErrorHandler } from '../../utils/helper';

import './dashboard.scss';
import './feedback.scss';

function DashboardStatusPage(props) {

  const {
    setPageTitleOne,
    setPageTitleTwo,
    setPageTitle,
    setPageTitleThree,
    setCommonPageTitleCheck
  } = props;

  const { session } = useSession("cyber-security");
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [orders, serOrders] = useState([]);
  const [reFetch, setreFetch] = useState(false);


  useEffect(() => {
    setPageTitleOne("Home");
    setPageTitleTwo("Dashboard");
    setPageTitleThree("");
    setPageTitle("");
    setCommonPageTitleCheck(true);
    fetchOrders();
  }, []);

  useEffect(() => {
    if (reFetch === true) {
      setreFetch(false);
      serOrders(null);
      fetchOrders();
    }
  }, [reFetch]);

  const fetchOrders = async () => {
    setLoading(true);
    const apiResponse = await UserService.getOrders(session.user_id, session.token);
    if (apiResponse.status === 200) {
      serOrders(apiResponse.data.data.orders);
      setLoading(false);
    } else {
      setLoading(false);
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }


  // console.log('order list ', orders);

  return (
    <>
      <div className="dashboard-wrapper">

        <div className="dashboard-title-container">
          <div className="dashboard-title"> Dashboard</div>
        </div>

        {loading === false ? (
          <>
            {orders.length > 0 ? (
              <DashboardTable rows={orders} refetch={setreFetch} />
            ) : (
              <div className="w-100 text-center py-1 my-1">You have no orders please create an order to view dashboard.</div>
            )}
          </>
        ) : (
          <Dialog open={true}>
            <DialogTitle> <CircularProgress /></DialogTitle>
          </Dialog>
        )}
      </div>

    </>
  );
}

export default DashboardStatusPage;
