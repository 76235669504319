import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";

function RadioInputComponent({
  width,
  label,
  placeholder,
  title,
  disabled,
  dataEntries,
  setDataEntries,
  formData,
  setFormData,
}) {

  let dataEntriesFromLocalStorage = JSON.parse(localStorage.getItem("dataEntriesInLocal"));
  const [selectedValue, setSelectedValue] = React.useState(false);

  const handleChange = (e) => {
    setSelectedValue(e.target.checked);
    let obj = { ...formData, [`${label}`]: e.target.checked };

    let obj1 = { ...dataEntriesFromLocalStorage, [e.target.label]: e.target.checked };
    setDataEntries(obj1);
    setFormData(obj);
    localStorage.setItem("dataEntriesInLocal", JSON.stringify(obj1));
  };

  return (
    <div
      className="radio-component"
      style={{ width: width ? width : "100%" }}
    >
      <FormControlLabel
        disabled={disabled ? disabled : false}
        control={
          <Checkbox
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<RadioButtonCheckedIcon />}
            onChange={(e) => handleChange(e)}
            checked={
              dataEntriesFromLocalStorage[label]
                ? dataEntriesFromLocalStorage[label]
                : selectedValue
            }
            color="default"
          />
        }
        label={label ? label : ""}
      />
    </div>
  );
}

export default RadioInputComponent;
