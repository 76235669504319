import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { useSession } from 'react-use-session';

import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

import { apiErrorHandler } from '../../../utils/helper';
import { ChatService } from '../../../utils/chat';

import atLogoSmall from "../../../assets/image/logo_new-white.png";
import homeImage from '../../../assets/image/home-white.png';
import homeImageBlack from '../../../assets/image/homeSide.png';
import inboxImage from '../../../assets/image/inbox-white.png';
import inboxImageBlack from '../../../assets/image/inbox-black.png';
import collapseLogo from '../../../assets/image/logo_new-white-fav.png';
import productWhite from '../../../assets/image/product-white.png';
import productBlack from '../../../assets/image/product.png';

// import atLogo from "../../../assets/image/logo_new-white.png";
// import productImage from '../../../assets/image/product-white.png';
// import productImageBlack from '../../../assets/image/product.png';
// import profileImage from '../../../assets/image/profile-white.png';
// import dashboardImage from '../../../assets/image/dashboard-white.png';
// import dashboardImageBlack from '../../../assets/image/dashboard.png';
// import profileImageBlack from '../../../assets/image/edit-black.png';

import { setSidebarTab } from "../../../redux/actions/sidebar.action";

import './sidebar.scss';

const Sidebar = (props) => {

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useSession("cyber-security");

  const { toggleDrawer, sidebar, setSidebarTab } = props;

  const [open, setOpen] = useState(false);
  const [borderControl, setborderControl] = useState("admin");

  const [unreadCount, setUnreadCount] = useState(0);

  const currentPath = history.location.pathname;

  function toggleMenu() {
    toggleDrawer(!open);
    setOpen(!open);
  };

  function reRoute(location, activeMenu) {
    history.push(location);
    setSidebarTab(activeMenu);
  }

  useEffect(() => {
    setborderControl(sidebar.currentTab);
  }, [sidebar.currentTab]);

  useEffect(() => {
    setborderControl(history.location.pathname);
  }, [history.location.pathname]);

  useEffect(() => {
    checkForNewMsg();
    setInterval(function () { checkForNewMsg(); }, 5000);
  }, [0]);

  async function checkForNewMsg() {
    const apiResponse = await ChatService.getNewMsgCount(session.user_id, session.token);
    if (apiResponse.status === 200) {
      setUnreadCount(apiResponse.data.data.newMessages);
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  function directAndToggle() {
    toggleMenu();
  }

  // console.log('sidebar state', sidebar);

  return (
    <>

      <div className="sidebar-header-section">

        <div className="sidebar-header-wrapper">
          {open === true ?
            <img
              className="sidebar-logo collapsed-view"
              src={collapseLogo}
              onClick={directAndToggle}
            />
            :
            <img
              className="sidebar-logo"
              src={atLogoSmall}
              onClick={directAndToggle}
            />
          }

        </div>
      </div>

      <div className="sidebar-item-wrapper">

        {/* <div className={`menu-items disabled ${borderControl === "admin" ? 'borderless-bottom' : ''} `}>
          <div className="item-image d-none">
            <img src={homeImage} alt="Home icon" className="image-white" />
            <img src={homeImageBlack} alt="Home icon" className="image-black" />
          </div>
          <div className="item-name"></div>
        </div> */}

        <Tooltip
          title="Home"
          arrow
          TransitionComponent={Zoom}
        >
          <div
            className={`menu-items 
            ${currentPath === '/home' ? 'active' : ''} 
            ${borderControl === 'notification' ? 'borderless-bottom' : ''} 
            `}
            onClick={reRoute.bind('', "/home", "home")}
          >
            <div className="menu-items-child">
              <div className="item-image">
                <img src={homeImage} alt="Home icon" className="image-white" />
                <img src={homeImageBlack} alt="Home icon" className="image-black" />
              </div>
              <div className="item-name">Dashboard</div>
            </div>
          </div>
        </Tooltip>

        <Tooltip title="Home" arrow TransitionComponent={Zoom}>
          <div
            className={`menu-items 
            ${currentPath === '/inbox' ? 'active' : ''} 
            ${borderControl === 'home' ? 'borderless-top' : ''} 
            ${borderControl === '/inbox' ? 'borderless-bottom' : ''} 
            `}
            onClick={reRoute.bind('', "/inbox", "inbox")}
          >
            <div className="menu-items-child">
              <div className="item-image">
                <img src={inboxImage} alt="inbox icon" className="image-white" />
                <img src={inboxImageBlack} alt="inbox icon" className="image-black" />
              </div>
              <div className="item-name">Inbox</div>
            </div>
          </div>
        </Tooltip>

        <Tooltip
          title="Live Chat"
          arrow
          TransitionComponent={Zoom}
        >
          <div
            className={`menu-items 
            ${currentPath === '/chat-admin' ? 'active' : ''} 
            ${borderControl === 'inbox' ? 'borderless-top' : ''} 
            ${borderControl === '/chat-admin' ? 'borderless-bottom' : ''} 
            `}
            onClick={reRoute.bind('', "/chat-admin", "chat-admin")}
          >
            <div className="menu-items-child">
              <div className="item-image">
                {/* <img src={inboxImageBlack} alt="inbox icon" className="image-black" /> */}
                {/* <img src={inboxImage} alt="inbox icon" className="image-white" /> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 image-white"
                  fill="#ffffff"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"></path>
                </svg>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 image-black"
                  fill="#000000"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"></path>
                </svg>

              </div>

              <div className="item-name">
                Chat
                {unreadCount > 0 ?
                  <sup className="unread-count"> {unreadCount} </sup>
                  : null}

              </div>

            </div>
          </div>
        </Tooltip>

        <Tooltip title="File Manager" arrow TransitionComponent={Zoom}>
          <div
            className={`menu-items 
            ${currentPath === '/files' ? 'active' : ''} 
            ${borderControl === 'files' ? 'borderless-top' : ''} 
            ${borderControl === 'users' ? 'borderless-bottom' : ''} 
            `}
            onClick={reRoute.bind('', "/files", "files")}
          >
            <div className="menu-items-child">
              <div className="item-image">
                {/* <img src={inboxImage} alt="inbox icon" className="image-white" />
                <img src={inboxImageBlack} alt="inbox icon" className="image-black" /> */}
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 image-white" fill="none" viewBox="0 0 24 24" stroke="#ffffff" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 image-black" fill="none" viewBox="0 0 24 24" stroke="#000000" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4" />
                </svg>
              </div>
              <div className="item-name">File Manager</div>
            </div>
          </div>
        </Tooltip>

        <Tooltip title="Users" arrow TransitionComponent={Zoom} >
          <div
            className={`menu-items 
            ${currentPath === '/users' ? 'active' : ''} 
            ${borderControl === 'chat' ? 'borderless-top' : ''} 
            ${borderControl === 'analyst' ? 'borderless-bottom' : ''} 
            `}
            onClick={reRoute.bind('', "/users", "users")}
          >
            <div className="menu-items-child">
              <div className="item-image">
                {/* <img src={inboxImage} alt="inbox icon" className="image-white" />
                <img src={inboxImageBlack} alt="inbox icon" className="image-black" /> */}

                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 image-white" fill="none" viewBox="0 0 24 24" stroke="#ffffff" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 image-black" fill="none" viewBox="0 0 24 24" stroke="#000000" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>

              </div>
              <div className="item-name">Users</div>
            </div>
          </div>
        </Tooltip>

        <Tooltip title="Analyst" arrow TransitionComponent={Zoom}>
          <div
            className={`menu-items 
            ${currentPath === '/analyst' ? 'active' : ''} 
            ${borderControl === 'users' ? 'borderless-top' : ''} 
            ${borderControl === 'admin-profile' ? 'borderless-bottom' : ''} 
            `}
            onClick={reRoute.bind('', "/analyst", "analyst")}
          >
            <div className="menu-items-child">
              <div className="item-image">
                {/* <img src={inboxImage} alt="inbox icon" className="image-white" />
                <img src={inboxImageBlack} alt="inbox icon" className="image-black" /> */}

                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 image-white" fill="none" viewBox="0 0 24 24" stroke="#ffffff" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 image-black" fill="none" viewBox="0 0 24 24" stroke="#000000" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                </svg>

              </div>
              <div className="item-name">Analyst</div>
            </div>
          </div>
        </Tooltip>

        <Tooltip title="Profile" arrow TransitionComponent={Zoom} >
          <div
            className={`menu-items 
            ${currentPath === '/profile-admin' ? 'active' : ''} 
            ${borderControl === 'analyst' ? 'borderless-top' : ''} 
            ${borderControl === 'orders' ? 'borderless-bottom' : ''} 
            `}
            onClick={reRoute.bind('', "/profile-admin", "profile-admin")}
          >
            <div className="menu-items-child">
              <div className="item-image">
                {/* <img src={inboxImage} alt="inbox icon" className="image-white" />
                <img src={inboxImageBlack} alt="inbox icon" className="image-black" /> */}

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="none" strok="#ffffff" className="image-white"><path d="M392 32H56C25.1 32 0 57.1 0 88v336c0 30.9 25.1 56 56 56h336c30.9 0 56-25.1 56-56V88c0-30.9-25.1-56-56-56zM194.9 371.4c0 14.8-12 26.9-26.9 26.9H85.1c-14.8 0-26.9-12-26.9-26.9V117.1c0-14.8 12-26.9 26.9-26.9H168c14.8 0 26.9 12 26.9 26.9v254.3zm194.9-112c0 14.8-12 26.9-26.9 26.9H280c-14.8 0-26.9-12-26.9-26.9V117.1c0-14.8 12-26.9 26.9-26.9h82.9c14.8 0 26.9 12 26.9 26.9v142.3z" stroke="#ffffff" strokeWidth="20" /></svg>

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="none" strok="#000000" className="image-black"><path d="M392 32H56C25.1 32 0 57.1 0 88v336c0 30.9 25.1 56 56 56h336c30.9 0 56-25.1 56-56V88c0-30.9-25.1-56-56-56zM194.9 371.4c0 14.8-12 26.9-26.9 26.9H85.1c-14.8 0-26.9-12-26.9-26.9V117.1c0-14.8 12-26.9 26.9-26.9H168c14.8 0 26.9 12 26.9 26.9v254.3zm194.9-112c0 14.8-12 26.9-26.9 26.9H280c-14.8 0-26.9-12-26.9-26.9V117.1c0-14.8 12-26.9 26.9-26.9h82.9c14.8 0 26.9 12 26.9 26.9v142.3z" stroke="#000000" strokeWidth="30" /></svg>

              </div>
              <div className="item-name">Profile</div>
            </div>
          </div>
        </Tooltip>

        <Tooltip title="Orders" arrow TransitionComponent={Zoom} >
          <div
            className={`menu-items 
            ${currentPath === '/orders' ? 'active' : ''} 
            ${borderControl === 'profile-admin' ? 'borderless-top' : ''} 
            ${borderControl === 'feedbacks' ? 'borderless-bottom' : ''} 
            `}
            onClick={reRoute.bind('', "/orders", "orders")}
          >
            <div className="menu-items-child">
              <div className="item-image">
                <img src={productWhite} alt="inbox icon" className="image-white" />
                <img src={productBlack} alt="inbox icon" className="image-black" />
              </div>
              <div className="item-name">Orders</div>
            </div>
          </div>
        </Tooltip>


        <Tooltip title="Feedbacks" arrow TransitionComponent={Zoom} >
          <div
            className={`menu-items 
            ${currentPath === '/feedbacks' ? 'active' : ''} 
            ${borderControl === 'orders' ? 'borderless-top' : ''} 
            ${borderControl === 'communication' ? 'borderless-bottom' : ''} 
            `}
            onClick={reRoute.bind('', "/feedbacks", "feedbacks")}
          >
            <div className="menu-items-child">
              <div className="item-image">
                {/* <img src={inboxImage} alt="inbox icon" className="image-white" />
                <img src={inboxImageBlack} alt="inbox icon" className="image-black" /> */}

                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 image-white" fill="none" viewBox="0 0 24 24" stroke="#ffffff" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 5c7.18 0 13 5.82 13 13M6 11a7 7 0 017 7m-6 0a1 1 0 11-2 0 1 1 0 012 0z" />
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 image-black" fill="none" viewBox="0 0 24 24" stroke="#000000" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 5c7.18 0 13 5.82 13 13M6 11a7 7 0 017 7m-6 0a1 1 0 11-2 0 1 1 0 012 0z" />
                </svg>

              </div>
              <div className="item-name">Feedbacks</div>
            </div>
          </div>
        </Tooltip>

        <Tooltip title="Email" arrow TransitionComponent={Zoom}>
          <div
            className={`menu-items 
            ${currentPath === '/communication' ? 'active' : ''} 
            ${borderControl === 'demo-request' ? 'borderless-top' : ''} 
            ${borderControl === 'communication' ? 'borderless-bottom' : ''} 
            `}
            onClick={reRoute.bind('', "/communication", "communication")}
          >
            <div className="menu-items-child">
              <div className="item-image">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 image-white" fill="none" viewBox="0 0 24 24" stroke="#ffffff" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 image-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                </svg>

              </div>
              <div className="item-name">Email</div>
            </div>
          </div>
        </Tooltip>

        <Tooltip title="Request of Demo" arrow TransitionComponent={Zoom}>
          <div
            className={`menu-items 
            ${currentPath === '/demo-request' ? 'active' : ''} 
            ${borderControl === 'feedbacks' ? 'borderless-top' : ''} 
            ${borderControl === 'demo-request' ? 'borderless-bottom' : ''} 
            `}
            onClick={reRoute.bind('', "/demo-request", "demo-request")}
          >
            <div className="menu-items-child">
              <div className="item-image">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 image-white" fill="none" viewBox="0 0 24 24" stroke="#ffffff" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                </svg>

                {/* <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                </svg> */}


                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 image-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                </svg>

              </div>
              <div className="item-name">Demo Request</div>
            </div>
          </div>
        </Tooltip>

        <div className={` menu-items disabled ${borderControl === 'demo-request' ? 'borderless-top' : ''} `} >
          <div className="menu-items-child">
            <div className="item-image d-none">
              <img src={inboxImage} alt="Home icon" className="image-white" />
              <img src={inboxImageBlack} alt="Home icon" className="image-black" />
            </div>
            <div className="item-name"></div>
          </div>
        </div>

      </div>

    </>
  );
};

const mapStateToProps = (state) => ({
  sidebar: state.sidebar
});

const mapDispatchToProps = {
  setSidebarTab: setSidebarTab
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);