import React, { useState } from "react";
import { SmallInput } from "../mui_components/muiComponents";
import { MenuItem } from "@material-ui/core";

function SelectInputComponent({
  dropDownFields,
  label,
  placeholder,
  width,
  disabled,
  formData,
  setFormData,
  dataEntries,
  setDataEntries,
}) {


  let dataEntriesFromLocalStorage = JSON.parse(localStorage.getItem("dataEntriesInLocal"));

  const [socialSelect, setSocialSelect] = useState("");

  const handleSelect = (e) => {
    setSocialSelect(e.target.value);
    let obj = { ...formData, [e.target.name]: e.target.value };
    let obj1 = {
      ...dataEntriesFromLocalStorage,
      [e.target.name]: e.target.value,
    };
    setFormData(obj);
    setDataEntries(obj1);
    localStorage.setItem("dataEntriesInLocal", JSON.stringify(obj1));
  };

  return (
    <SmallInput
      variant="outlined"
      label={label ? label : ""}
      name={label ? label : ""}
      placeholder={placeholder ? placeholder : ""}
      select
      disabled={disabled ? disabled : false}
      style={{ width: width ? width : "100%" }}
      value={
        dataEntriesFromLocalStorage[placeholder]
          ? dataEntriesFromLocalStorage[placeholder]
          : ""
      }
      onChange={(e, v) => handleSelect(e)}
    >
      {dropDownFields.map((field, index) => (
        <MenuItem id={field.dropDownFieldId} value={field.name} key={index} disabled={field.avl == false ? true : false}  >
          {field.name}
        </MenuItem>
      ))}
    </SmallInput>
  );
}

export default SelectInputComponent;
