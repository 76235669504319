import { UPDATE_NOTIFICATION_COUNT, UPDATE_PROFILE } from "../../utils/constants";


const initialState = {
  fluctuate: true,
  profileFluctuate: true
}

const notification = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NOTIFICATION_COUNT:
      return {
        ...state,
        fluctuate: action.payload
      };

    case UPDATE_PROFILE:
      return {
        ...state,
        profileFluctuate: action.payload
      };
  

    default:
      return { ...state };
  }
};

export default notification;

