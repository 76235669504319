import React from 'react';

const PasswordMeter = ({ password, passwordPower }) => {

  const regex = new Array();
  regex.push("[A-Z]"); //Uppercase Alphabet.
  regex.push("[a-z]"); //Lowercase Alphabet.
  regex.push("[0-9]"); //Digit.
  regex.push("[$@$!%*#?&]"); //Special Character.

  var passed = 0;

  //Validate for each Regular Expression.
  for (var i = 0; i < regex.length; i++) {
    if (new RegExp(regex[i]).test(password)) {
      passed++;
    }
  }

  const createPassLabel = () => {
    switch (passed) {
      case 0:
        passwordPower(false);
        return;
      case 1:
        passwordPower(false);
        return 'Very weak';
      case 2:
        passwordPower(false);
        return 'Weak';
      case 3:
        passwordPower(false);
        return 'Good';
      case 4:
        passwordPower(true);
        return 'Strong';
      default:
        return '';
    }
  }

  const funcProgressColor = () => {
    switch (passed.score) {
      case 0:
        return '#828282';
      case 1:
        return '#EA1111';
      case 2:
        return '#FFAD00';
      case 3:
        return '#9bc158';
      case 4:
        return '#00b500';
      default:
        return 'none';
    }
  }

  const changePasswordColor = () => ({
    width: `${passed}}%`,
    backgroundColor: funcProgressColor(),
    height: '1px'
  })

  return (
    <>
      <div className="progress" style={{ height: '1px' }}>
        <div className="progress-bar" style={changePasswordColor()}></div>
      </div>
      <div className="mt-10px w-100 d-flex">
        <div className={`password-meter veryWeak pr-12px 
          ${createPassLabel() == 'Very weak' ||
            createPassLabel() == 'Weak' ||
            createPassLabel() == 'Good' ||
            createPassLabel() == 'Strong'
            ? 'active' : null} `}
        >
          <div className=""></div>
        </div>
        <div className={`password-meter weak pr-12px 
          ${createPassLabel() == 'Weak' ||
            createPassLabel() == 'Good' ||
            createPassLabel() == 'Strong'
            ? 'active' : null} `}
        >
          <div className=""></div>
        </div>
        <div className={`password-meter good pr-12px 
          ${createPassLabel() == 'Good' ||
            createPassLabel() == 'Strong'
            ? 'active' : null} `}
        >
          <div className=""></div>
        </div>
        <div className={`password-meter Strong pr-12px 
          ${createPassLabel() == 'Strong' ? 'active' : null} `}
        >
          <div className=""></div>
        </div>
      </div>
    </>
  )
}

export default PasswordMeter