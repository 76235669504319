import React from "react";

import { useHistory } from 'react-router-dom';
import moment from 'moment';

import NoMatch from "../404Page/404Page";
import Home from "../Home/Home";
import AdminRoutes from '../Admin';
import AnalystRoutes from '../Analyst';

function Routing() {

  const history = useHistory();
  const currentPath = history.location.pathname;

  const websiteLocations = [
    '/',
    '/dashboard', '/dashboard/',
    '/athena',
    '/thunderbolt',
    '/selected-products',
    '/cart',
    '/billing',
    '/notification',
    '/order-verify',
    '/service',
    '/product',
    '/profile',
    '/order-delete',
    '/email-unsubscribed',
    '/email-verify',
    '/subscription', '/subscription/',
    '/chat', '/chat/'
  ];

  const userLocations = websiteLocations.includes(currentPath);

  const adminLocations = [
    '/home', '/home/',
    '/analyst', '/analyst/',
    '/users', '/users/',
    '/orders', '/orders/',
    '/profile-admin', '/profile-admin/',
    '/files/', '/files',
    '/feedbacks/', '/feedbacks',
    '/chat-admin/', '/chat-admin',
    '/email/', '/email',
    '/inbox/', '/inbox',
    '/ticket-detail', '/ticket-detail/',
    '/communication', 'communication',
    '/demo-request', 'demo-request'
  ];

  const adminLocationsBool = adminLocations.includes(currentPath);

  const analystLocations = [
    '/analyst-home', '/analyst-home/',
    '/analyst-inbox', '/analyst-inbox/',
    '/analyst-files', '/analyst-files/',
    '/analyst-profile', '/analyst-profile/',
    '/chat-analyst', '/chat-analyst/',
    '/analyst-ticket-detail', '/analyst-ticket-detail/',
    '/analyst-email', '/analyst-email/',
    '/analyst-orders', '/analyst-orders/'
  ];

  const analystLocationsBool = analystLocations.includes(currentPath);

  // console.log('currentPath ', currentPath);

  const prevTimeTemp = localStorage.getItem('tapOutTime');
  if (prevTimeTemp === null) {
    localStorage.setItem('tapOutTime', Date.now());
  } else {

    const currTime = moment(Date.now());
    const prevTime = moment(parseInt(localStorage.getItem('tapOutTime')));

    const timeDiff = moment.duration(currTime.diff(prevTime)).asMinutes();

    if (timeDiff > 1440) {
      localStorage.clear();
      window.location.replace(`https://${window.location.host}/`);
    } else {
      localStorage.setItem('tapOutTime', Date.now());
    }
  }

  return (
    <>
      {userLocations === true ?
        <Home />
        :
        <>
          {adminLocationsBool === true ?
            <AdminRoutes />
            :
            <>
              {analystLocationsBool === true ?
                <AnalystRoutes />
                :
                <NoMatch />
              }
            </>
          }
        </>
      }
    </>
  );
}

export default Routing;
