import React from "react";
import SelectInputComponent from "./SelectInputComponent";
import TextInputComponent from "./TextInputComponent";

function MultipleSocialMedia({
  title,
  fields,
  disabled,
  formData,
  setFormData,
  dataEntries,
  setDataEntries,
  errors
}) {

  const findAndReturn = (findParams) => {
    let val = Object.keys(errors).find((item) => item == findParams);
    return errors[val];
  }

  return (
    <>
      {fields.map((item, index) => {
        return (
          <div className="multiple-social-media" key={index}>
            {item.fields.map((key, index) => {
              if (key.type === "select") {
                return (
                  <SelectInputComponent
                    width={key.width}
                    key={index}
                    label={key.label}
                    placeholder={key.placeholder}
                    dropDownFields={key.dropDownFields}
                    disabled={key.disabled}
                    formData={formData}
                    setFormData={setFormData}
                    dataEntries={dataEntries}
                    setDataEntries={setDataEntries}
                  />
                );
              }
              if (key.type === "text") {
                return (
                  <TextInputComponent
                    width={key.width}
                    key={index}
                    label={key.label}
                    placeholder={key.placeholder}
                    disabled={key.disabled}
                    formData={formData}
                    setFormData={setFormData}
                    dataEntries={dataEntries}
                    setDataEntries={setDataEntries}
                    error={findAndReturn(key.placeholder)}
                  />
                );
              }
            })}
          </div>
        );
      })}
    </>
  );
}

export default MultipleSocialMedia;
