import API from "./API"
import { API_WRONG } from "./messages";
import timeValidator from './tapOutChecker';

const getSubscriptions = async (token) => {

  await timeValidator();

  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.get(`service_subscription/`, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let errorResponse = { status: "", statusText: API_WRONG };
        return resolve(errorResponse);
      }
    }
  });
};


export const SubscriptionService = {
  getSubscriptions
}
