import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';

import { useSnackbar } from "notistack";
import { useSession } from 'react-use-session';
import { UserService } from "../../utils/userServices";
import { apiErrorHandler } from '../../utils/helper';


function TablePaginationActions(props) {

  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const NotificationTable = (props) => {

  const { rows, sethitNotification, paginationData, page, setPage, rowsPerPage, setRowsPerPage } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { session } = useSession("cyber-security");


  // Avoid a layout jump when reaching the last page with empty rows.

  async function updateNotificationState(notificationId) {
    const apiResponse = await UserService.getReadNotificaton(notificationId, session.token)
    if (apiResponse.status === 201) {
      sethitNotification(true);
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    blackOp();
  }, [0]);

  async function blackOp() {
    for (let unitNotification of rows) {
      if (unitNotification.isread === false) {
        await updateNotificationState(parseInt(unitNotification.id));
      }
    }
    sethitNotification(true);
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">

        <TableHead>
          <TableRow>
            <TableCell>Category</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Time</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>

          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">{row.category}</TableCell>
              <TableCell>{row.message}</TableCell>
              <TableCell>{row.created_at}</TableCell>
            </TableRow>
          ))}

          
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={3}>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, { label: 'All', value: paginationData.total_count }]}
                component="div"
                count={paginationData.total_count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default NotificationTable;
