import React, { useEffect, useState } from "react";
import { SmallInput } from "../mui_components/muiComponents";

function TextInputComponent({
  variant,
  label,
  placeholder,
  height,
  width,
  disabled,
  type,
  rows,
  formData,
  setFormData,
  dataEntries,
  setDataEntries,
  dependency,
  error,
  required
}) {

  let dataEntriesFromLocalStorage = JSON.parse(localStorage.getItem("dataEntriesInLocal"));
  // console.log('will see', dataEntriesFromLocalStorage);

  const [text, setText] = useState("");

  const handleChange = (e) => {
    setText(e.target.value);

    let obj = { ...formData, [e.target.placeholder]: e.target.value };
    let obj1 = { ...dataEntriesFromLocalStorage, [e.target.placeholder]: e.target.value };

    setFormData(obj);

    setDataEntries(obj1);
    localStorage.setItem("dataEntriesInLocal", JSON.stringify(obj1));
  };

  useEffect(() => {
    let newObj = JSON.parse(localStorage.getItem("dataEntriesInLocal"));
    Object.keys(formData).map(function (item, index) {
      Object.keys(newObj).map(function (key, index) {
        if (item == key) {
          formData[`${item}`] = `${newObj[key]}`;
        }
      });
    });
  }, [dependency]);

  // console.log('key.label', label);
  return (
    <>
      <div style={{ width: width ? width : "60%" }}>
        <SmallInput
          variant="outlined"
          fullWidth={true}
          label={placeholder ? placeholder : ""}
          multiline={type === "multiline" ? true : false}
          type={type ? type : "text"}
          rows={rows ? rows : ""}
          value={
            dataEntriesFromLocalStorage[placeholder] ? dataEntriesFromLocalStorage[placeholder] : ""
          }
          onChange={(e) => handleChange(e)}
          placeholder={placeholder ? placeholder : ""}
          disabled={disabled ? disabled : false}
          dependency={dependency}
          className={placeholder === "Occupation 1" || placeholder === "Occupation" ? 'lower-size-placeholder' : 'normal'}
          error={
            typeof error === "object" && Object.keys(error).length ? true : false
          }
          helperText={
            typeof error === "object" && Object.keys(error).length ? (
              <>
                {Object.keys(error).map((item, index) => {
                  return (
                    <span className="text-danger fs14px" style={{ lineHeight: 1 }} key={index}>
                      {error[item]}
                    </span>
                  );
                })}
              </>
            ) : null
          }
        />
      </div>
    </>
  );
}

export default TextInputComponent;
