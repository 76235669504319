import React from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/image/thunder-bolt.png";
import "./common.scss";

function ThunderBoltCommonCard({
  height,
  width,
  title,
  marginLeftValue,
  backgroundColorValue,
  handleClick,
  launch
}) {
  
  const history = useHistory();

  const containerCommon = {
    height: "120px",
    width: "240px",
    backgroundColor: backgroundColorValue
  };

  return (
    <>
      <div style={containerCommon} className="proBoxes ">
        <div className="commonLeftContainer">
          <div className="commonTextContainer">
            <p className="textlightAlt  popbold card_title fs18px-wrap">{title}</p>
          </div>
          <div
            className={`serviceCont blue-btn ${launch === true ? '' : 'd-none'} `}
            onClick={() => history.push("/thunderbolt")}
          >
            LAUNCH
          </div>
        </div>

        <div className="commonRightContainer ">
          <img src={logo} className="producImageThunder" />
        </div>
      </div>
      <div className="mobile-box-service">
        <div className="supportive"></div>
        <div className="image-section">
          <img src={logo} className="producImage" />
        </div>
        <div className="title-section">Thunderbold </div>
      </div>
    </>
  );
}

export default ThunderBoltCommonCard;
