import React, { useEffect } from "react";

import './sass/main.scss';
import './assets/css/authenticationStyles.css';
import './assets/css/DashboardProjectDetail.css';
import './assets/css/index.css';
import './assets/css/LandingPage.css';
import './assets/css/main.css';

import { Switch, Route } from "react-router-dom";
import { useSession } from 'react-use-session';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import Routing from './Pages/Routing/Routing';
import SignUp from "./Pages/LoginRegister/SignUp";
import EmailVerify from './Pages/EmailVerify/EmailVerify';

import { updateCounter } from "./redux/actions/counter.action";

// function App() {
const App = () => {

  const { session } = useSession("cyber-security");
 
  useEffect(() => {
    setInterval(() => {
      if (window.navigator.onLine === false) {
        const client = document.getElementById('disableClient');
        client.style.display = "flex";
      }
    }, 5000);
  
  },[0]);


  const history = useHistory();
  const currentPath = history.location.pathname;
  const websiteLocations = ['/', '/signup', '/forgot-password', '/email-verify'];
  const includeBool = websiteLocations.includes(currentPath);

  function redirectTo() {
    window.location.replace(`https://${window.location.host}/`);
  }

  return (
    <div className="App">
      <div className="offline" id="disableClient">Client is OFFLINE</div>
      <Switch>
        {session === null ? (
          <>
            {includeBool === true ?
              <>
                <Route path="/" exact={true}> <SignUp /></Route>
                <Route path="/signup"> <SignUp /></Route>
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/email-verify" render={(props) => (<EmailVerify />)} />
              </>
              :
              <>{redirectTo()}</>
            }
          </>
        ) : (
          <Route path="/"> <Routing /> </Route>
        )}
      </Switch>
    </div>
  );
}

// const mapStateToProps = (state) => ({
//   counter: state.counter
// });

// const mapDispatchToProps = {
//   updateCounter: updateCounter,
// };

export default App;
// export default connect(mapStateToProps, mapDispatchToProps)(App);

