import React, { useEffect, useState } from "react";

import IconButton from "@material-ui/core/IconButton";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useSession } from 'react-use-session';
import { connect } from "react-redux";

import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { updateNotificationCount } from "../../../redux/actions/notification.action";
import { updateProfile } from "../../../redux/actions/profile.action";
import { apiErrorHandler, filterImageUrl } from '../../../utils/helper';
import { AnalystService } from '../../../utils/analyst';

import atLogo from '../../../assets/image/white-logo.png';
import homeImage from '../../../assets/image/home-white.png';
import profileImage from '../../../assets/image/profile-white.png';
import dashboardImage from '../../../assets/image/dashboard-white.png';
import inboxImage from '../../../assets/image/inbox-white.png';

import './appbar.scss';

function AppBarComponent(props) {

  const { notification, updateNotificationCount, updateProfile } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { clear, session } = useSession("cyber-security");
  const historyDirect = useHistory();

  const [menuState, setMenuState] = useState('null');

  const [profileDetails, setprofileDetails] = useState({
    user_name: null,
    profle_image_url: null
  });

  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    fetchProfileDetails();
    setInterval(function () { notificationCount(); }, 5000);
  }, [0]);

  useEffect(() => {
    if (notification.fluctuate === true) {
      notificationCount();
      updateNotificationCount(false);
    }
  }, [notification.fluctuate]);

  useEffect(() => {
    if (notification.profileFluctuate === true) {
      fetchProfileDetails();
      updateProfile(false);
    }
  }, [notification.profileFluctuate]);


  async function notificationCount() {
    const apiResponse = await AnalystService.analystNotificationCount(session.user_id, session.token);
    if (apiResponse.status === 200) {
      setUnreadCount(apiResponse.data.data.unread_count);
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  async function fetchProfileDetails() {
    const apiResponse = await AnalystService.getAnalystDetails(session.user_id, session.token);
    if (apiResponse.status === 200) {
      setprofileDetails({
        user_name: apiResponse.data.data.analyst_name,
        profle_image_url: apiResponse.data.data.s3_profile_photo_url
      });
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  const logout = () => {
    clear();
    localStorage.clear();
    window.location.replace(`https://${window.location.host}/`);
  };

  function toggleMenu() {
    if (menuState === 'active') {
      setMenuState('');
    } else {
      setMenuState('active');
    }
  };


  function toTarget(targetAddress) {
    historyDirect.push(targetAddress);
  };

  function directToProfile() {
    historyDirect.push("/analyst-profile")
  }

  function directToInbox() {
    historyDirect.push("/analyst-inbox")
  }

  // console.log('unread count', unreadCount);
  // console.log('reducer props', notification);
  // console.log('cart items', cartItems);
  // console.log('profile details', profileDetails);


  return (
    <>
      <div className={`header-wrapper ${menuState === 'active' ? 'h-auto' : ''} `}>

        <div className={`header-mobile-section ${menuState === 'active' ? 'h-auto' : ''} `}>

          <div className="static-wrapper">
            <div className="company-logo">
              <img src={atLogo} />
            </div>
            <IconButton onClick={toggleMenu}><MenuIcon className="menu-icon" /></IconButton>
          </div>

          <div className="menu-wrapper">

            <div className="menu-item" onClick={toTarget.bind('', '/')}>
              <div className="menu-icon">
                <img src={homeImage} alt="Home" />
              </div>
              <div className="menu-text">Home </div>
            </div>

            <div className="menu-item" onClick={toTarget.bind('', '/analyst-inbox')}>
              <div className="menu-icon">
                <img src={inboxImage} alt="Inbox" />
              </div>
              <div className="menu-text" > Inbox</div>
            </div>

            <div className="menu-item" onClick={toTarget.bind('', '/profile')}>
              <div className="menu-icon">
                <img src={profileImage} alt="Profile" />
              </div>
              <div className="menu-text" > Profile</div>
            </div>

            <div className="menu-item" onClick={toTarget.bind('', '/orders')}>
              <div className="menu-icon">
                <img src={dashboardImage} alt="Dashboard" />
              </div>
              <div className="menu-text"> Orders</div>
            </div>

          </div>

        </div>

      </div>

      <div className="header-desktop-section header-desktop">

        <div className="breadcrumb-section">
          <Breadcrumb />
        </div>

        <div className="header-tools">

          <div className="header-item">

            <Tooltip title="Inbox" arrow TransitionComponent={Zoom}>
              <NotificationsOutlinedIcon onClick={directToInbox} />
            </Tooltip>

            {unreadCount !== 0 ?
              <div className="cart-items-number">{unreadCount}</div>
              : null}

          </div>

          <div className="header-item-profile" onClick={directToProfile} >
            {profileDetails.user_name !== null ?
              <>
                {profileDetails.profle_image_url !== null ?
                  // <img src={filterImageUrl(profileDetails.profle_image_url)} alt="user-profile-image" />
                  <img src={profileDetails.profle_image_url} alt="user-profile-image" />
                  :
                  <strong className="name-text"> {profileDetails.user_name.charAt(0)} </strong>
                }
              </>
              : null}
          </div>

          <div className="header-item" onClick={logout} >
            <Tooltip title="Logout" arrow TransitionComponent={Zoom}><ExitToAppIcon /></Tooltip>
          </div>

        </div>


      </div>

    </>
  );
}

const mapStateToProps = (state) => ({
  notification: state.notification
});

const mapDispatchToProps = {
  updateNotificationCount: updateNotificationCount,
  updateProfile: updateProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBarComponent);