import React, { useState, useEffect } from 'react';

import { useSession } from 'react-use-session';
import { useSnackbar } from "notistack";
import { connect } from 'react-redux';


import CircularProgress from "@material-ui/core/CircularProgress";
import { apiErrorHandler } from '../../../utils/helper';
import { ChatService } from '../../../utils/chat';
import { updateCounter } from "../../../redux/actions/counter.action";

const Contact = (props) => {

  const { setUserSlected, role, setContactRefresh, contactRefresh, userSlected, updateCounter, counter, contactTobe } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { session } = useSession("cyber-security");

  const [contactList, setContactList] = useState([]);
  const [contactLoading, setContactLoading] = useState(false);

  const [canRequest, setCanRequest] = useState(false);

  useEffect(() => {
    fetchChatList();
    let intervalId = setInterval(fetchChatListNoLoader, 5000);
    return (() => {
      clearInterval(intervalId)
    })
  }, [0]);

  useEffect(() => {
    if (canRequest === true) {
      setTimeout(function () {
        setCanRequest(false)
      }, 700);
    }
  }, [canRequest])

  useEffect(() => {
    if (contactRefresh === true) {
      setContactRefresh(false);
      fetchChatList();
    }
  }, [contactRefresh])

  function useThisUser(userToChat) {
    if (canRequest === false) {
      setUserSlected(userToChat);
      updateSeen(userToChat);
      setCanRequest(true);
    }
  }

  const fetchChatListNoLoader = async () => {

    let filterString;
    if (role === "admin") {
      filterString = `role=admin&user_id=${session.user_id}`;
    } else if (role === "analyst") {
      filterString = `role=analyst&analyst_id=${session.user_id}`;
    } else {
      filterString = `role=user&user_id=${session.user_id}`;

    }
    const apiResponse = await ChatService.getChatList(filterString, session.token);
    if (apiResponse.status === 200) {
      setContactList(apiResponse.data.data);
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  async function fetchChatList() {
    setContactLoading(true);

    let filterString;
    if (role === "admin") {
      filterString = `role=admin&user_id=${session.user_id}`;
    } else if (role === "analyst") {
      filterString = `role=analyst&analyst_id=${session.user_id}`;
    } else {
      filterString = `role=user&user_id=${session.user_id}`;

    }
    const apiResponse = await ChatService.getChatList(filterString, session.token);
    setContactLoading(false);
    if (apiResponse.status === 200) {
      setContactList(apiResponse.data.data);
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  async function updateSeen(userToChat) {

    const updateJson = {
      receiver_id: session.user_id,
      sender_id: userToChat.user_id
    }
    const updateUnreadResponse = await ChatService.updateUnreadCount(updateJson, session.token);
    if (updateUnreadResponse.status === 200) {
    } else {
      apiErrorHandler(updateUnreadResponse, enqueueSnackbar);
    }

    let filterString;
    if (role === "admin") {
      filterString = `role=admin&user_id=${session.user_id}`;
    } else if (role === "analyst") {
      filterString = `role=analyst&analyst_id=${session.user_id}`;
    } else {
      filterString = `role=user&user_id=${session.user_id}`;
    }

    const apiResponse = await ChatService.getChatList(filterString, session.token);
    if (apiResponse.status === 200) {
      setContactList(apiResponse.data.data);
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }

  }

  // console.log('canRequest ', canRequest);
  // console.log('contactList ', contactList);
  // console.log('userSlected ', userSlected);

  return (
    <div className={`contact-list ${contactTobe === true ? ' active ' : ' inactive ' } `}>
      {contactLoading === true ?
        <div className="contact-loading"><CircularProgress /></div>
        :
        <>
          {contactList.length !== 0 ?
            <>
              {contactList.map((item, index) => {
                return (
                  <div className={`contact-item ${userSlected.user_id === item.user_id ? ' active ' : ' none'} `} key={index} onClick={useThisUser.bind('', item)} >
                    <div className="name-inititiol-contact">{item.first_name.slice(0, 1)}{item.last_name.slice(0, 1)}</div>
                    <div className="detail-name">
                      {item.first_name} {item.last_name}

                      {item.unreadCount !== null ?
                        <small> <sup> <div className="unread-count">{item.unreadCount < 99 ? item.unreadCount : "99+"}</div> </sup> </small>
                        : null}

                      <small> <sub> {item.tag ? <>({item.tag})</> : ''}</sub> </small>
                    </div>
                  </div>
                );
              })}
            </>
            :
            <div className="no-chat-reason">You dont have any contact</div>
          }
        </>}
    </div>
  );
}
const mapStateToProps = (state) => ({
  counter: state.counter
});

const mapDispatchToProps = {
  updateCounter: updateCounter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
