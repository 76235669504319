import React from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/image/athena-logo3.png";
import "./common.scss";

function AthenaCommonCard({
  height,
  width,
  title,
  marginLeftValue,
  backgroundColorValue,
  handleClick,
  launch
}) {

  const history = useHistory();

  const containerCommon = {
    height: "120px",
    width: "240px",
    backgroundColor: backgroundColorValue
  };

  return (
    <>
      <div
        style={containerCommon}
        className="proBoxes"
      >
        <div className="commonLeftContainer">
          <div className="commonTextContainer">
            <p className="textlightAlt fs18px popbold card_title">{title}</p>
          </div>

          <div
            className={`serviceCont blue-btn ${launch === true ? '' : 'd-none'} `}
            onClick={() => history.push("/athena")}
          >
            LAUNCH
          </div>
        </div>

        <div className="commonRightContainer  ">
          <img src={logo} className="producImage" />
        </div>
      </div>
      <div className="mobile-box-service">
        <div className="supportive"></div>
        <div className="image-section">
          <img src={logo} className="producImage" />
        </div>
        <div className="title-section">Athena </div>
      </div>
    </>
  );
}

export default AthenaCommonCard;
