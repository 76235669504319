export const
  dataEntriesObj = {
    "Brand Logo / Favicon": "",
    "Name": "",
    "Address": "",
    "Picture": "",
    "Select Platform": "",
    "UserName": "",
    "Email *": "",
    "Phone Number": "",
    "Name of Organization *": "",
    "IP": "",
    "Domain *": "",
    "Mobile Application": "",
    "Start Date *": "",
    "End Date *": "",
    "Mail Server Name": "",
    "User Name": "",
    "Domain Name": "",
    "Time Period": "",
    "VPN Detail": "",
    "Remote Work Place Platform": "",
    "Proxy Server Detail": "",
    "Vendor Phone Number": "",
    "Vendor Email Address": "",
    "Vendor Domain": "",
    "Upload Audit Report": "",
    "Vendor Location": "",
    "Anything Else You'd Like Us To Know": "",
    "Number Of Years": "",
    "Breach History": "",
    "Executive/s Name": "",
    "Client Address": "",
    "Area": "",
    "Street": "",
    "Landmark": "",
    "City": "",
    "Client School/Colleges": "",
    "Number Of Family Members": "",
    "Relation": "",
    "Occupation": "",
    "DOB": "",
    "Upload list of IP's": ""
  };