import React, { useState, useEffect } from "react";

import { useHistory } from "react-router";
import { useSnackbar } from "notistack";

import { useSession } from 'react-use-session';
import { UserService } from '../../utils/userServices';
import { API_WRONG } from "../../utils/messages";

import sadFace from '../../assets/image/sad-face.png';

// order-deleted?order_id=811

const EmailVerify = () => {

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [correctId, setcorrectId] = useState(true),
    [loader, setLoader] = useState(true),
    [verified, setVerified] = useState(true);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  

  useEffect(() => {
    EmailVerify();
  }, [0]);

  async function EmailVerify() {
    if (params.email == undefined || params.email == null || params.email == 'null' || params.email == 'undefined') {
      setcorrectId(false);
      setLoader(false);
    } else {

      const verifyOrderResponse = await UserService.verifyEmail(params.email);
      if (verifyOrderResponse.data.status === true) {
        setLoader(false);
        enqueueSnackbar(verifyOrderResponse.data.message, { variant: "success" });
        setTimeout(() => {
          history.push('/');
        }, 500);
      } else {
        setVerified(false);
        setLoader(false);
        if (verifyOrderResponse.data !== null && Object.keys(verifyOrderResponse.data.errors).length) {
          Object.keys(verifyOrderResponse.data.errors).map(function (key) {
            verifyOrderResponse.data.errors[key].map((item, index) => {
              enqueueSnackbar(`${key} : ${item}`, { variant: "error" });
            });
          });
        } else {
          enqueueSnackbar(API_WRONG, { variant: "error" });
        }
      }
    }
  }

  return (
    <div className="order-verify mt-32px">
      <div className="order-wrapper">
        {correctId == true ?
          <>
            {loader == true ?
              <>
                <div className="loader-section pt-5">
                  <div className="loader-small"></div>
                </div>
                <p className="verify-text">Verifying Email ({params.email})</p>
              </>
              :
              <>
                {verified == true ?
                  <>
                    <div className="loader-section pt-5">
                      <svg width="87" height="86" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="48.5" cy="48" r="45" stroke="#9dff89" strokeWidth="6" />
                        <path fill-rule="evenodd" clipRule="evenodd"
                          d="M43.7126 55.3541L61.275 37.7918C62.3307 36.7361 64.0423 36.7361 65.0979 37.7918C66.1536 38.8474 66.1536 40.559 65.0979 41.6147L43.7126 63L31.2918 50.5791C30.2361 49.5235 30.2361 47.8119 31.2918 46.7562C32.3474 45.7005 34.059 45.7005 35.1147 46.7562L43.7126 55.3541Z"
                          fill="black" />
                        <mask id="mask0_3368:9017" maskUnits="userSpaceOnUse" x="30" y="37" width="36" height="26">
                          <path fillRule="evenodd" clipRule="evenodd"
                            d="M43.7126 55.3541L61.275 37.7918C62.3307 36.7361 64.0423 36.7361 65.0979 37.7918C66.1536 38.8474 66.1536 40.559 65.0979 41.6147L43.7126 63L31.2918 50.5791C30.2361 49.5235 30.2361 47.8119 31.2918 46.7562C32.3474 45.7005 34.059 45.7005 35.1147 46.7562L43.7126 55.3541Z"
                            fill="white" />
                        </mask>
                        <g mask="url(#mask0_3368:9017)">
                        </g>
                      </svg>
                    </div>
                    <p className="verify-text">Your email is verified successfully.</p>
                  </>
                  :
                  <img alt="Wrong order id" width="150px" height="150px" src={sadFace} />
                }
              </>
            }
          </>
          :
          <p className="verify-text-error">No order_id available in url.</p>
        }
      </div>
    </div>
  );
};

export default EmailVerify;
