import React, { useState } from 'react';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useSession } from 'react-use-session';
import { useSnackbar } from "notistack";

import CircularProgress from '@material-ui/core/CircularProgress';

import { apiErrorHandler } from "../../utils/helper";
import { DemoRequest } from "../../utils/demoRequest";
 
import './demo.scss';

const Demo = (props) => {

  const { closeReviewMadal } = props;
  const [loader, setLoader] = useState(false);
  const { session } = useSession("cyber-security");
  const { enqueueSnackbar } = useSnackbar();

  const demoSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .min(3, 'Name must be more than 3 characters.')
      .max(50, 'Only 50 characters are allowed.')
      .required('Name is required.'),

    organization: Yup.string()
      .trim()
      .min(4, 'Organization must be more than 4 characters.')
      .max(50, 'Only 50 characters are allowed.')
      .required('Organization is required.'),

    email: Yup.string()
      .trim()
      .email("Entry should be valid email.")
      .min(4, 'Email must be more than 4 characters.')
      .max(50, 'Only 50 characters are allowed.')
      .required('Email is required.'),

    phone_number: Yup.number().typeError("Only numbers are allowed.")
      .integer("Only integer are allowed.")
      .min(1000000000, 'Phone number must contain 10 digits.')
      .max(9999999999, 'Phone number must contain 10 digits')
      .required('Phone number is required.'),

    comments: Yup.string()
      .trim()
      .min(10, 'Comments must be more than 10 characters.')
      .max(100, 'Only 100 characters are allowed.')
      .required('Comments is required.')
  });

  async function saveRequest(savePayload) {
    setLoader(true);
    const apiResponse = await DemoRequest.seveRequest(savePayload, session.token);
    setLoader(false);
    if (apiResponse.status === 201) {
      enqueueSnackbar("We will contact you soon.", { variant: "success" });
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }


  async function getClosed(formValue) {
    console.log(formValue);
    await saveRequest(formValue);
    closeReviewMadal();
  }

  return (
    <div className="add-user-modal">
      <div className="feedback-wrapper">
        <div className="feedback-form">
          {loader === true ? <div className="demo-loader-section"> <CircularProgress /> </div> : null}
          <div className="title">Schedule a Demo</div>
          <div className="form-area">
            <Formik
              initialValues={{
                name: '',
                organization: '',
                email: '',
                phone_number: '',
                comments: ''
              }}
              validationSchema={demoSchema}
              onSubmit={(values) => {
                console.log('values', values);
                // pushData(values);
                getClosed(values);
              }}
            >
              {({ errors, touched }) => (

                <Form>

                  <div className="profile-input-box">
                    <Field
                      type="text"
                      name="name"
                      id="name"
                      className="profile-custom-input"
                      placeholder="Name"
                    />
                    {errors.name && touched.name ? (
                      <div className="profile-form-error text-danger">{errors.name}</div>
                    ) : null}
                  </div>


                  <div className="profile-input-box">
                    <Field
                      className="profile-custom-input"
                      type="text"
                      name="organization"
                      id="organization"
                      placeholder="Organization Name"
                    />

                    {errors.organization && touched.organization ? (
                      <div className="profile-form-error text-danger">{errors.organization}</div>
                    ) : null}

                  </div>

                  <div className="profile-input-box">
                    <Field
                      className="profile-custom-input"
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Email"
                    />

                    {errors.email && touched.email ? (
                      <div className="profile-form-error text-danger">{errors.email}</div>
                    ) : null}

                  </div>

                  <div className="profile-input-box">
                    <Field
                      className="profile-custom-input"
                      type="number"
                      name="phone_number"
                      id="phone_number"
                      placeholder="Phone Number"
                    />

                    {errors.phone_number && touched.phone_number ? (
                      <div className="profile-form-error text-danger">{errors.phone_number}</div>
                    ) : null}

                  </div>

                  <div className="profile-input-box">

                    <Field
                      className="profile-custom-input"
                      name="comments"
                      id="comments"
                      placeholder="Comments"
                      type="textarea"
                    />

                    {errors.comments && touched.comments ? (
                      <div className="profile-form-error text-danger">{errors.comments}</div>
                    ) : null}

                  </div>

                  <div className="profile-form-submit">
                    <button type="submit">Request Demo </button>
                  </div>

                </Form>
              )}
            </Formik>
          </div>
          <div className="close-modal" onClick={closeReviewMadal}> <button>Close </button> </div>
        </div>
      </div>
    </div>
  )
}
export default Demo;