import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";

import reportWebVitals from "./reportWebVitals";
import store from './redux/store';

import App from "./App";

ReactDOM.render(
  <BrowserRouter basename="">
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={10}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={3500}
      >
        <App />
      </SnackbarProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();