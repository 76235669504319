import { UPDATE_SIDEBAR_TAB } from "../../utils/constants";


const initialState = {
  currentTab: 'admin'
}

const sidebar = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SIDEBAR_TAB:
      return {
        ...state,
        currentTab: action.payload
      };

    default:
      return { ...state };
  }
};

export default sidebar;

