import React from "react";
import TextInputComponent from "./TextInputComponent";

function VariableSizedInputComponent({
  title,
  fields,
  formData,
  setFormData,
  dataEntries,
  setDataEntries,
  dependency,
  errors
}) {

  const findAndReturn = (findParams) => {
    let val = Object.keys(errors).find((item) => item == findParams);
    return errors[val];
  }

  return (
    <>
      {fields.map((item, index) => {
        return (
          <>
            {item.fields.map((key, index) => {
              return (
                <div className="form-box-wrapper">
                  <TextInputComponent
                    key={index}
                    label={key.label}
                    placeholder={key.placeholder}
                    width={key.width}
                    disabled={key.disabled}
                    height={key.height}
                    formData={formData}
                    setFormData={setFormData}
                    dataEntries={dataEntries}
                    setDataEntries={setDataEntries}
                    dependency={dependency}
                    error={findAndReturn(key.placeholder)}
                    required={key.required}
                  />
                </div>
              );
            })}
          </>
        );
      })
      }
    </>
  );
}

export default VariableSizedInputComponent;
