import React, { useEffect } from 'react';

import ChatComponent from '../Admin/ChatComponent/Chat';

const Chat = (props) => {

  const {
    setPageTitleOne,
    setPageTitleTwo,
    setPageTitleThree,
    setChatSidebar
  } = props;

  useEffect(()=> {
    setPageTitleOne("Home");
    setPageTitleTwo("Chat");
    setPageTitleThree("");
    setChatSidebar(true);
  },[0]);

  return (

    <ChatComponent role="user" />

  );
}




export default Chat;