import { SET_LAYOUT_TITLE } from "../../utils/constants";


const initialState = {
  breadCrumb: {
    breadOne: "Home",
    breadTwo: null,
    breadThree: null
  }
}

const title = (state = initialState, action) => {
  switch (action.type) {
    case SET_LAYOUT_TITLE:
      return {
        ...state,
        breadCrumb: action.payload
      };

    default:
      return { ...state };
  }
};

export default title;

