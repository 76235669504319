import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useSession } from 'react-use-session';
import { useSnackbar } from "notistack";

import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import { UserService } from "../../utils/userServices";
import { apiErrorHandler } from '../../utils/helper';
import "date-fns";


import athenaimg from '../../assets/image/athena-logo3.png';
import thunderbolt from '../../assets/image/thunder-bolt.png';

function Billing(props) {

  const {
    setPageTitleOne,
    setPageTitleTwo,
    setPageTitle,
    setPageTitleThree,
    setCommonPageTitleCheck,
    selectedServices,
    setSelectedServices,
    setallSelected,
    sethitCart,
    sethitNotification
  } = props;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const { session } = useSession("cyber-security");

  const [currentOne, setcurrentOne] = useState([]);

  const [cartItem, setcartItem] = useState([]);

  const [allProduct, setallProduct] = useState(null);
  const [loader, setLoader] = useState(true);
  const [totalPrice, setTotalPrice] = useState(null);

  const [uploadLoader, setuploadLoader] = useState(false);

  const [billingDetails, setbillingDetails] = useState({
    fullName: null,
    country: null,
    street1: null,
    street2: null,
    email: null,
  });

  const [deleteLoader, setDeleteLoader] = useState(false);

  useEffect(() => {
    setPageTitleOne("Cart");
    setPageTitleTwo("Cart");
    setPageTitleThree("");
    setPageTitle("");
    setCommonPageTitleCheck(true);
    getCartItem();
  }, []);


  async function getCartItem() {

    const apiResponse = await UserService.getCartItem(session.user_id, session.token);
    setDeleteLoader(false);
    setLoader(false);
    if (apiResponse.status === 200) {
      setcartItem(apiResponse.data.data);
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }

  }

  // useEffect(() => {
  //   let priceCalculated = 15;
  //   if (Object.keys(cartItem).length > 0 && Object.keys(allProduct).length > 0) {
  //     cartItem.map((item, key) => {
  //       let indexOfService = allProduct.map(function (e) { return e.service_id; }).indexOf(item.service_id);
  //       priceCalculated += allProduct[indexOfService].mrp;
  //     });
  //     setTotalPrice(priceCalculated);
  //   }
  // }, [cartItem, allProduct]);


  async function deleteItem(idToDelete) {
    setDeleteLoader(true);
    const apiResponse = await UserService.deleteCartItem(idToDelete, session.token);
    // console.log('apiResponse', apiResponse);
    if (apiResponse.status == 204) {
      getCartItem();
      sethitCart(true);
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  async function placeOrder() {

    setuploadLoader(true);

    const orderId = await fetchOrderId();
    // console.log('orderId', orderId);
    const submitPromise = cartItem.map(async (item, key) => {

      const dataFormat = {
        filled_value_json: item.fill_value_json,
        service_id: item.service_id,
        subscription_id: item.subscription_id,
        order_id: orderId,
        document_id: item.document_id,
        feedback_status: "pending"
      };

      const serviceResponse = await UserService.postServiceData(dataFormat, session.token);

      if (serviceResponse.status == 201) {
        await UserService.deleteCartItem(item.cart_id, session.token);
      }

    });

    Promise.all(submitPromise).then(() => {
      sethitCart(true);
      sethitNotification(true);
      history.push("/dashboard");
    });

  }


  const fetchOrderId = async () => {

    const apiResponse = await UserService.getOrderId({ user_id: session.user_id }, session.token);
    if (apiResponse.status === 201) {
      return apiResponse.data.data.order_id;
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
      return false;
    }
  };

  // console.log('allProduct', allProduct);
  // console.log('cartItem', cartItem);


  return (
    <>
      <div className="cart-wrapper">

        {/* form section */}

        {loader == false ?
          <>
            {cartItem.length !== 0 ?
              <>
                <div className="cart-item-wrapper">
                  {cartItem.map((item, index) => {
                    return (
                      <div className="cart-item" key={index}>
                        <div className="item-image">
                          {item.product.service_type === "athena" ?
                            <img src={athenaimg} alt="athena-product" />
                            :
                            <img src={thunderbolt} alt="thunder-boltproduct" />
                          }
                        </div>
                        <div className="item-detail">
                          <div className="cart-product-name">
                            {item.product.service_name}
                          </div>
                          <div
                            className="remove-button"
                            onClick={deleteItem.bind('', item.cart_id)}
                          >
                            REMOVE
                          </div>
                        </div>
                      </div>
                    );
                  })}

                </div>

                <div className="billing-wrapper">

                  <div className="billing-block">

                    <div className="summary-title">Summary </div>

                    {/* {cartItem.map((item, key) => {
                      let indexOfService = allProduct.map(function (e) { return e.service_id; }).indexOf(item.service_id);
                      return (
                        <div className="dividerC pb-1 d-none" key={key}>
                          <div className="w-100 d-flex">
                            <div className="w-70 text-left fs15px">
                              {allProduct[indexOfService].service_name}
                            </div>
                            <div className="w-30 text-right fs15px">INR &nbsp;
                              {allProduct[indexOfService].mrp}
                            </div>
                          </div>
                        </div>
                      );
                    })} */}

                    <div className="mt-1 dividerC pb-1 d-none">
                      <div className="w-100 d-flex">
                        <div className="w-70 text-left fs15px ">Tax</div>
                        <div className="w-30 text-right fs15px">INR 15.00</div>
                      </div>
                    </div>

                    <div className="total-price d-none">
                      <div className="total-price">TOTAL PRICE</div>
                      <div className="total-amount"> INR&nbsp;{totalPrice}</div>
                    </div>

                    <div className="controls">
                      <div className="button-continue"
                        style={{ color: "#000" }}
                        onClick={() => history.push('/product')}
                      >
                        CONTINUE SHOPPING
                      </div>
                      <div
                        className="button-order"
                        onClick={() => placeOrder()}
                      >
                        PLACE ORDER
                      </div>
                    </div>

                  </div>
                </div>
              </>
              :
              <div className="cart-empity-content">
                Create a new order and add services to the cart by clicking &nbsp;
                <span className="textDark text-primary fw-bold cp tunder" onClick={() => history.push('/product')}>here</span>
              </div>
            }
          </>
          :
          <div className="w-100 h-100 hcenter"> <CircularProgress /> </div>
        }

      </div>

      <Dialog open={uploadLoader}>
        <DialogTitle>
          <CircularProgress />
        </DialogTitle>
      </Dialog>

      <Dialog open={deleteLoader}>
        <DialogTitle>
          <CircularProgress />
        </DialogTitle>
      </Dialog>

    </>
  );
}

export default Billing;
