import React, { useState } from "react";

import { useSnackbar } from "notistack";

import PublishIcon from "@material-ui/icons/Publish";

import { FILE_SIZE_2MB, ALLOWED_DOCS_FILES } from "../../utils/messages";

function DocumentUploadComponent({
  label,
  placeholder,
  width,
  formData,
  setFormData,
  disabled,
  dataEntries,
  setDataEntries,
}) {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [fileDetails, setfileDetails] = useState("Upload");

  const addDocument = (file, id) => {
    if (file.size < 2e+6) {

      let obj = { ...formData, [id.placeholder]: file.name };
      setFormData(obj);

      setfileDetails(file.name);
      let formdata = new FormData();

    } else {
      enqueueSnackbar(FILE_SIZE_2MB, { variant: "error" });
    }
  };

  return (
    <div className="borderBlack h57px br4px vcenter" style={{ width: width ? width : "100%" }}>

      <div className="h38px br6px vcenter px-1 mt10px jc-fs">
        <label className="vhcenter align" htmlFor={label} ><PublishIcon /></label>
        {fileDetails}
      </div>

      <input
        type="file"
        name={label}
        id={label}
        placeholder={placeholder}
        accept={ALLOWED_DOCS_FILES}
        className="h38px br6px vcenter px-1 mt-1 jc-fs d-none"
        onChange={(e) => addDocument(e.target.files[0], e.target)}
      />
    </div>
  );
}

export default DocumentUploadComponent;
