import React, { useEffect, useState } from "react";

import { useSnackbar } from "notistack";
import { useHistory, withRouter } from "react-router-dom";
import { useSession } from 'react-use-session';

import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import IconButton from "@material-ui/core/IconButton";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";

import CommonPageTitle from "../../Components/CommonCards/CommonPageTitle";
import { UserService } from '../../utils/userServices';
import { AuthServices } from '../../utils/auth';
import { AnalystService } from '../../utils/analyst';
import { apiErrorHandler, filterImageUrl } from '../../utils/helper';

import atLogo from '../../assets/image/white-logo.png';
import homeImage from '../../assets/image/home-white.png';
import productImage from '../../assets/image/product-white.png';
import profileImage from '../../assets/image/profile-white.png';
import dashboardImage from '../../assets/image/dashboard-white.png';
import inboxImage from '../../assets/image/inbox-white.png';

function AppBarComponent({
  history,
  pageTitle,
  hitNotification,
  sethitNotification,
  hitCart,
  sethitCart,
  hitProfile,
  setHitProfile,
  pageTitleOne,
  pageTitleTwo,
  pageTitleThree,
  setallSelected,
  commonPageTitleCheck,
}) {

  const { clear, session } = useSession("cyber-security");
  const { enqueueSnackbar } = useSnackbar();
  const historyDirect = useHistory();

  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [cartItems, setCartItems] = useState(null);
  const [menuState, setMenuState] = useState('null');

  const [profileDetails, setprofileDetails] = useState({
    user_name: null,
    profle_image_url: null
  });

  useEffect(() => {
    fetchAllData();
  }, [0]);

  useEffect(() => {
    if (hitNotification == true) {
      getNotification();
      sethitNotification(false);
    }
  }, [hitNotification]);

  useEffect(() => {
    if (hitCart === true) {
      getCartItems();
      sethitCart(false);
    }
  }, [hitCart]);

  useEffect(() => {
    if (hitProfile === true) {
      getUserData();
      setHitProfile(false);
    }
  }, [hitProfile]);

  const getNotification = async () => {
    const apiResponse = await AnalystService.analystNotificationCount(session.user_id, session.token);
    if (apiResponse.status === 200) {
      setUnreadNotifications(apiResponse.data.data.unread_count);
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  };

  const getCartItems = async () => {
    const apiResponse = await UserService.cartItemCount(session.user_id, session.token);
    if (apiResponse.status === 200) {
      setCartItems(apiResponse.data.data.cartItemCount);
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  };

  const getUserData = async () => {
    const apiResponse = await AuthServices.getUserDetails(session.user_id, session.token);
    if (apiResponse.status === 200) {
      const holdVal = apiResponse.data.data;
      setprofileDetails({
        user_name: holdVal.display_name,
        profle_image_url: holdVal.s3_profile_photo_url
      });
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }


  async function fetchAllData() {
    await getNotification();
    await getCartItems();
    await getUserData();
  }

  const logout = () => {
    clear();
    localStorage.clear();
    window.location.replace(`https://${window.location.host}/`);
  };

  function toggleMenu() {
    if (menuState === 'active') {
      setMenuState('');
    } else {
      setMenuState('active');
    }
  };


  function toTarget(targetAddress) {
    historyDirect.push(targetAddress);
  };

  // console.log('cart items', cartItems);
  // console.log('unread notification', unreadNotifications);


  return (
    <>
      <div className={`header-wrapper ${menuState === 'active' ? 'h-auto' : ''} `}>

        <div className={`header-mobile-section ${menuState === 'active' ? 'h-auto' : ''} `}>

          <div className="static-wrapper">
            <div className="company-logo">
              <img src={atLogo} alt="logo" />
            </div>
            <IconButton onClick={toggleMenu}> <MenuIcon className="menu-icon" /> </IconButton>
          </div>

          <div className="menu-wrapper">

            <div className="menu-item" onClick={toTarget.bind('', '/')}>
              <div className="menu-icon">
                <img src={homeImage} alt="Home" />
              </div>
              <div className="menu-text"  >Home </div>
            </div>

            <div className="menu-item" onClick={toTarget.bind('', '/notification')}>
              <div className="menu-icon">
                <img src={inboxImage} alt="Inbox" />
              </div>
              <div className="menu-text" > Inbox</div>
            </div>

            <div className="menu-item" onClick={toTarget.bind('', '/chat')}>
              <div className="menu-icon">
                {/* <img src={productImage} alt="Chat" /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 image-white"
                  fill="#ffffff"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"></path>
                </svg>
              </div>
              <div className="menu-text" > Chat </div>
            </div>

            <div className="menu-item" onClick={toTarget.bind('', '/product')}>
              <div className="menu-icon">
                <img src={productImage} alt="Products" />
              </div>
              <div className="menu-text" > Products </div>
            </div>

            <div className="menu-item" onClick={toTarget.bind('', '/profile')}>
              <div className="menu-icon">
                <img src={profileImage} alt="Profile" />
              </div>
              <div className="menu-text" > Profile</div>
            </div>

            <div className="menu-item" onClick={toTarget.bind('', '/dashboard')}>
              <div className="menu-icon">
                <img src={dashboardImage} alt="Dashboard" />
              </div>
              <div className="menu-text"> Dashboard</div>
            </div>

          </div>

        </div>

      </div>

      <div className="header-desktop-section header-desktop">

        <div className="breadcrumb-section">
          {commonPageTitleCheck ? (
            <CommonPageTitle
              pageTitleOne={pageTitleOne}
              pageTitleTwo={pageTitleTwo}
              pageTitleThree={pageTitleThree}
              setallSelected={setallSelected}
            />
          ) : null}
        </div>

        <div className="header-tools">

          <div className="header-item" onClick={() => history.push("/notification")}>
            <Tooltip title="Notification" arrow TransitionComponent={Zoom}><NotificationsOutlinedIcon /></Tooltip>
            {unreadNotifications !== 0 ? (
              <div className="cart-items-number"> {unreadNotifications}</div>
            ) : null}
          </div>

          <div className="header-item" onClick={() => history.push("/cart")}>
            <Tooltip title="Cart" arrow TransitionComponent={Zoom}><ShoppingCartOutlinedIcon /></Tooltip>
            {cartItems !== 0 ? (
              <div className="cart-items-number"> {cartItems} </div>
            ) : null}
          </div>

          <div className="header-item-profile" onClick={() => history.push("/profile")}>

            {profileDetails.user_name !== null ?
              <>
                {profileDetails.profle_image_url !== null ?
                  // <img src={filterImageUrl(profileDetails.profle_image_url)} alt="user-profile-image" />
                  <img src={profileDetails.profle_image_url} alt="user-profile-image" />
                  :
                  <strong className="name-text">{profileDetails.user_name.charAt(0)}</strong>
                }
              </>
              : null}
          </div>

          <div className="header-item" onClick={logout}>
            <Tooltip title="Logout" arrow TransitionComponent={Zoom}><ExitToAppIcon /></Tooltip>
          </div>

        </div>


      </div>

    </>
  );
}

export default withRouter(AppBarComponent);
