import React, { useState, useEffect } from "react";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import moment from 'moment';

import { CustomDatePicker } from "../mui_components/muiComponents";
import "date-fns";

function DateInputOneTime({
  title,
  label,
  placeholder,
  width,
  disableFuture,
  disabled,
  formData,
  setFormData,
  dataEntries,
  setDataEntries,
  error,
  subscription
}) {

  console.log(' god help ', subscription);


  let dataEntriesFromLocalStorage = JSON.parse(localStorage.getItem("dataEntriesInLocal"));

  const initialStartDate = dataEntriesFromLocalStorage["Start Date *"];
  // console.log('initial start date', initialStartDate);
  const myMomentObject = moment(initialStartDate, 'YYYY-MM-DD');
  // console.log('string to date', myMomentObject);

  const dateAfterFourDays = moment(myMomentObject).add(5, 'days').format('YYYY-MM-DD');
  // console.log('after four days', dateAfterFourDays);


  const endDate = dataEntriesFromLocalStorage["End Date *"];
  // console.log('endDate', endDate, typeof endDate);

  const [dateInp, setDateInp] = useState(null);

  // console.log('default date', dateInp);
  // console.log('disableFuture disableFuture', disableFuture);

  const dateChange = (id, e) => {

    if (e) {
      setDateInp(e.getFullYear() + "-" + (e.getMonth() + 1) + "-" + e.getDate());
      let formattedDate = e.getFullYear() + "-" + (e.getMonth() + 1) + "-" + e.getDate();
      let obj = { ...formData, [id]: formattedDate };

      let obj1 = { ...dataEntriesFromLocalStorage, [id]: formattedDate };
      setFormData(obj);
      setDataEntries(obj1);
      localStorage.setItem("dataEntriesInLocal", JSON.stringify(obj1));
    }

  };

  useEffect(() => {
    setDateInp(null);
    let obj = { ...formData, ['End Date *']: "null" };

    let obj1 = { ...dataEntriesFromLocalStorage, ['End Date *']: "null" };
    setFormData(obj);
    setDataEntries(obj1);
    localStorage.setItem("dataEntriesInLocal", JSON.stringify(obj1));

  }, [0]);


  // console.log('start date', dataEntriesFromLocalStorage["Start Date *"]);

  return (
    <div style={{ width: width ? width : "100%" }} >

      <MuiPickersUtilsProvider utils={DateFnsUtils} fullWidth>

        {placeholder == "Start Date *" ?

          <CustomDatePicker
            inputVariant="outlined"
            fullWidth
            minDate={new Date()}
            variant="dialog"
            format="dd-MM-yyyy"
            openTo="year"
            label={title}
            placeholder={placeholder ? title : ""}
            value={
              dataEntriesFromLocalStorage[placeholder]
                ? dataEntriesFromLocalStorage[placeholder]
                : null
            }
            disabled={disabled ? disabled : false}
            onChange={(e, v) => dateChange(placeholder, e, v)}
            error={
              typeof error === "object" && Object.keys(error).length ? true : false
            }
            helperText={
              typeof error === "object" && Object.keys(error).length ? (
                <>
                  {Object.keys(error).map((item, index) => {
                    return (
                      <span className="text-danger fs14px" key={index}>{error[item]}</span>
                    );
                  })}
                </>
              ) : null
            }
          />

          :

          <span className="d-none" >
            <CustomDatePicker
              inputVariant="outlined"
              fullWidth
              minDate={new Date()}
              variant="dialog"
              format="dd-MM-yyyy"
              openTo="year"
              label={title}
              placeholder={placeholder ? title : ""}
              value="null"
              disabled={disabled ? disabled : false}
              onChange={(e, v) => dateChange(placeholder, e, v)}
              error={
                typeof error === "object" && Object.keys(error).length ? true : false
              }
              helperText={
                typeof error === "object" && Object.keys(error).length ? (
                  <>
                    {Object.keys(error).map((item, index) => {
                      return (
                        <span className="text-danger fs14px" key={index}>{error[item]}</span>
                      );
                    })}
                  </>
                ) : null
              }
            />
          </span>
          
        }

      </MuiPickersUtilsProvider>
    </div>
  );
}

export default DateInputOneTime;
