import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { countries } from "../../utils/country";
import "date-fns";

const useStyles = makeStyles({
  root: {
    "&.MuiFormControlLabel-root": {
      padding: "0px",
      margin: "0px",
    },
    "&.MuiCheckbox-colorPrimary.Mui-checked": {
      color: "#2d2d2d",
    },
  },
  custom: {
    fontSize: "24px",
    color: "#050505",
    marginTop: "20px",
  },
  textfield: {
    focused: {
      color: "red",
      borderColor: "red",
    },
  },
  rootChipStyles: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    margin: 0,
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  noBorder: {
    border: "none",
  },
  checkCust: {
    label: {
      fontSize: 11,
    },
  },
});

function Cart(props) {
  const {
    setPageTitleOne,
    setPageTitleTwo,
    setPageTitle,
    setPageTitleThree,
    setCommonPageTitleCheck,
    selectedServices,
    setSelectedServices,
  } = props;

  // console.log('props', props);

  const history = useHistory();
  const classes = useStyles();
  const [anotherStreet, setanotherStreet] = useState(false);
  const [currentOne, setcurrentOne] = useState([]);

  useEffect(() => {
    setPageTitleOne("Cart");
    setPageTitleTwo("Billing Detail");
    setPageTitleThree("");
    setPageTitle("Cart");
    setCommonPageTitleCheck(true);
  }, []);

  const [billingDetails, setbillingDetails] = useState({
    fullName: null,
    country: null,
    street1: null,
    street2: null,
    email: null,
  });

  const updateFormData = (event) => {
    setbillingDetails({
      ...billingDetails,
      [event.target.name]: event.target.value,
    });
  };

  // console.log('selectedServices ', selectedServices);
  // console.log('currentOne ', currentOne);

  function countryToFlag(isoCode) {
    // console.log(isoCode);
    return typeof String.fromCodePoint !== "undefined"
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  }

  const toBilling = () => {
    // history.push('/billing');
    history.push({
      pathname: `/dashboard`,
      state: {
        selectedOne: currentOne,
      },
    });
  };

  return (
    <>
      <div className="dnfCustomersContainer h-auto px-2">
        <div className="dnfFormContainer">
          {/* form section */}
          <div className="dnfFormFieldsContainer br10px bgtheme oh p-2">
            <Grid xs={12}>
              <Grid
                xs={12}
                container
                spacing={3}
                className=" m-0 popregular pb-3"
              >
                <Grid xs={4} className="cartWr m-0 ">
                  <div className="cardTlt text-left fw-bold mb-2 mt-0 fs19px textblack">
                    BILLING DETAILS
                  </div>
                  <p className="text-left fs7px upLit ">
                    <sup className=" text-danger">*</sup>required fields{" "}
                  </p>

                  {/* full name */}
                  <div className="">
                    <Typography className="cartHeader text-left label-color fs18px my-1">
                      Full Name <sup className="ml-1 text-danger">*</sup>
                    </Typography>
                    <div className="hcenter w-100">
                      <TextField
                        variant="outlined"
                        fullWidth={true}
                        multiline={false}
                        type="text"
                        rows={""}
                        name="fullName"
                        size="small"
                        onChange={updateFormData}
                        label=""
                        placeholder={""}
                        disabled={false}
                      />
                    </div>
                  </div>

                  {/* country */}
                  <div className="">
                    
                    <Typography className="cartHeader text-left label-color fs18px my-1">
                      Country <sup className="ml-1 text-danger">*</sup>
                    </Typography>

                    <div className="hcenter w-100">
                      <Autocomplete
                        id="country-select-demo"
                        style={{ width: "100%" }}
                        options={countries}
                        classes={{ option: classes.option }}
                        autoHighlight
                        name="country"
                        disableClearable
                        size="small"
                        getOptionLabel={(option) => option.label}
                        onSelect={(val) => updateFormData(val)}
                        renderOption={(option) => (
                          <>
                            <span>{countryToFlag(option.code)}</span>
                            {option.label} ({option.code}) + {option.phone}
                          </>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            name="country"
                            onChange={updateFormData}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>

                  {/* street */}
                  <div className="">
                    <div className="">
                      <Typography className="cartHeader text-left label-color fs18px my-1">
                        Street Address<sup className="ml-1 text-danger">*</sup>
                      </Typography>
                      <div className="hcenter w-100">
                        <TextField
                          variant="outlined"
                          fullWidth={true}
                          multiline={false}
                          type="text"
                          rows={""}
                          name="street"
                          size="small"
                          onChange={updateFormData}
                          label=""
                          placeholder={""}
                          disabled={false}
                        />
                      </div>
                    </div>

                    {anotherStreet ? (
                      <div className="hcenter w-100 mt-2">
                        <TextField
                          variant="outlined"
                          fullWidth={true}
                          multiline={false}
                          type="text"
                          rows={""}
                          name="street2"
                          size="small"
                          onChange={updateFormData}
                          label=""
                          placeholder={""}
                          disabled={false}
                        />
                      </div>
                    ) : null}

                    <div className="">
                      <Typography className="cartHeader text-left label-color fs14px hunder cp my-1">
                        <span onClick={() => setanotherStreet(true)}>
                          + Add another address field (optional)
                        </span>
                      </Typography>
                    </div>
                  </div>

                  {/* postcode */}
                  <div className="">
                    <div className="">
                      <Typography className="cartHeader text-left label-color fs18px my-1">
                        Post / Zip <sup className="ml-1 text-danger">*</sup>
                      </Typography>
                      <Grid xs={12} className="hcenter">
                        <Grid xs={6}>
                          <div className="hcenter w-100">
                            <TextField
                              variant="outlined"
                              fullWidth={true}
                              multiline={false}
                              type="number"
                              rows={""}
                              name="zipcode"
                              size="small"
                              onChange={updateFormData}
                              label=""
                              placeholder={""}
                              disabled={false}
                            />
                          </div>
                        </Grid>
                        <Grid xs={6} className="vcenter">
                          <div className="fs12px label-color ps-2">
                            Enter zip for city and state
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>

                  <div className="mt-3 mb-3 text-voilet cardTlt text-left fw-bold fs19px">
                    CONTACT INFORMATION
                  </div>

                  {/* Email */}
                  <div className="">
                    <div className="">
                      <Typography className="cartHeader text-left label-color fs18px my-1">
                        Email <sup className="ml-1 text-danger">*</sup>
                      </Typography>
                      <div className="hcenter w-100">
                        <TextField
                          variant="outlined"
                          fullWidth={true}
                          multiline={false}
                          type={"email"}
                          rows={""}
                          name="email"
                          onChange={updateFormData}
                          label=""
                          size="small"
                          placeholder={""}
                          disabled={false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="textSemiH text-left fs11px mt-1 mb-2 textblack fw-bold">
                    You will be able to create an account after purchasing
                  </div>

                  {/* Phone */}
                  <div className="">
                    <div className="">
                      <div className="vcenter">
                        <Typography className="cartHeader text-left label-color fs18px my-1">
                          Phone <sup className="ml-1 text-danger">*</sup>{" "}
                        </Typography>
                        <span className="fs12px lh18px ml-3 textSemih fw-bold">
                          For delivery-related queries
                        </span>
                      </div>
                      <div className="hcenter w-100">
                        <TextField
                          variant="outlined"
                          fullWidth={true}
                          multiline={false}
                          type={"email"}
                          rows={""}
                          name="email"
                          size="small"
                          onChange={updateFormData}
                          label=""
                          placeholder={""}
                          disabled={false}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid xs={4} className="cartWr">
                  <div className="ps-2 pt-8">
                    <div className=" text-voilet cardTlt text-left fw-bold fs19px  ">
                      ADDITIONAL OPTIONS
                    </div>
                    <div className="cartHeader text-left label-color fs7px hunder cp my-1 textSemiH">
                      <span>+ Add a note to this order</span>
                    </div>
                  </div>
                </Grid>

                <Grid xs={4} className="cartWr">
                  <div className="text-voilet cardTlt text-left fw-bold fs19px">
                    YOUR ORDER
                  </div>
                  <div className="mt-4">
                    <div className="dividerC pb-1">
                      <div className="w-100 d-flex">
                        <div className="w-80 text-left fs13px fw-bold">
                          PRODUCT
                        </div>
                        <div className="w-20 text-right fs13px fw-bold">
                          Total
                        </div>
                      </div>
                    </div>

                    <div className="mt-1 dividerC pb-1">
                      <div className="w-100 d-flex">
                        <div className="w-80 text-left fs15px fw-bold">
                          Trust & Safety Assessment
                        </div>
                        <div className="w-20 text-right fs18px fw-bold">
                          $23
                        </div>
                      </div>
                    </div>

                    <div className="mt-1 dividerC pb-1">
                      <div className="w-100 d-flex">
                        <div className="w-80 text-left fs15px fw-bold">
                          Digital Risk Assessment
                        </div>
                        <div className="w-20 text-right fs18px fw-bold">
                          $23
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <div className="w-100 d-flex">
                        <div className="w-80 text-left fs15px">TOTAL </div>
                        <div className="w-20 text-right fs18px fw-bold">
                          {" "}
                          46$
                        </div>
                      </div>
                    </div>

                    <div className="fs14px mb-1 textblack fw-bold pb-0 mt-3">
                      PAYMENT METHOD
                    </div>

                    {/* Credit and debit card */}
                    <div className="creditBox mt-1">
                      <div className="borderPrimary p-1 bgwhite">
                        <div className="d-flex">
                          <FormControl component="fieldset">
                            <RadioGroup aria-label="gender" name="gender1" />
                            <FormControlLabel
                              value="female"
                              className="textlight fs16px"
                              control={<Radio color="primary" size="small" />}
                              label="Credit & Debit Card (Stripe)"
                            />
                          </FormControl>
                        </div>

                        <div className="d-flex w-100 mt-1">
                          <div className="w-60">
                            <TextField
                              variant="standard"
                              fullWidth={true}
                              multiline={false}
                              type="number"
                              name="cardNumber"
                              onChange={updateFormData}
                              className={classes.noBorder}
                              placeholder="1234 1234 1234 1234"
                              disabled={false}
                              size="small"
                              autoFocus={false}
                            />
                          </div>
                          <div className="w-40 d-flex">
                            <TextField
                              variant="standard"
                              multiline={false}
                              autoFocus={false}
                              type="number"
                              size="small"
                              name="mm"
                              onChange={updateFormData}
                              className={classes.noBorder}
                              placeholder="MM"
                              disabled={false}
                            />
                            <TextField
                              variant="standard"
                              multiline={false}
                              autoFocus={false}
                              type="number"
                              size="small"
                              name="dd"
                              onChange={updateFormData}
                              className={classes.noBorder}
                              placeholder="DD"
                              disabled={false}
                            />
                            <TextField
                              variant="standard"
                              multiline={false}
                              autoFocus={false}
                              type="number"
                              size="small"
                              name="cvv"
                              onChange={updateFormData}
                              className={classes.noBorder}
                              placeholder="CVV"
                              disabled={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* paypal */}
                    <div className="creditBox mt-1">
                      <div className="borderLight bgwhite">
                        <div className="d-flex ml-1 bgwhite">
                          <FormControl
                            component="fieldset"
                            className="text-left"
                          >
                            <RadioGroup aria-label="gender" name="gender1" />
                            <FormControlLabel
                              value="female"
                              className="textlight fs16px"
                              control={<Radio color="primary" size="small" />}
                              label="PayPal"
                            />
                          </FormControl>
                        </div>
                        <div className="fs11px bglight fw-bold textSemiH pb-2 ps-1 pt-05">
                          You can pay with your credit card if you don’t have a
                          PayPal account.
                        </div>
                      </div>
                    </div>

                    {/* confirmation */}
                    <div className="mt-2">
                      <FormControl component="fieldset">
                        <FormControlLabel
                          value="end"
                          className="checkBoxlabel"
                          control={<Checkbox color="primary" />}
                          label="I have read and agree to the website terms and conditions"
                          size="small"
                        />
                      </FormControl>
                    </div>

                    {/* message */}
                    <div className="fs13px lh18px textSemiH mt-3 fw-bold">
                      Your personal data will be used to process your order,
                      support your experience throughout this website, and for
                      other purposes described in our privacy policy.
                    </div>

                    {/* button */}
                    <div className="orederBtn mt-3 cp" onClick={toBilling}>
                      Place Order{" "}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cart;
