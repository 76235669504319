import React, { useState, useEffect } from 'react';

import { RtmTokenBuilder, RtmRole } from 'agora-access-token';
import { useSession } from 'react-use-session';
import { useSnackbar } from "notistack";
import AgoraRTM from 'agora-rtm-sdk';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import CircularProgress from "@material-ui/core/CircularProgress";
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

import { apiErrorHandler, makeId, tokenExpireTime } from '../../../utils/helper';
import { ChatService } from '../../../utils/chat';
import Contact from './Contact';
import InputType from './InputType';
import ChatCard from './ChatCard';

import './chat-new.scss';

// logo_new-white-fav
//creating instance from app id.
const appId = "044fba54cc464c359b1b79d3e197042d";
const certificate = "170ddbcbffa1404db79d29ef6e819077";
const chatClient = AgoraRTM.createInstance(appId);

const Chat = (props) => {

  const { session } = useSession("cyber-security");
  const { role } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [channel, setChannel] = useState();

  const [pageSize, setPageSize] = useState(50);
  const [metaData, setMetaData] = useState(null);

  const [stateIdentifire, setStateIdentifire] = useState(Date.now());

  const [chatDataLoading, setChatDataLoading] = useState(false);
  const [chatData, setChatData] = useState([]);
  const [liveChatData, setLiveChatData] = useState([]);

  const [currentMessage, setCurrentMessage] = useState('');

  const [contactRefresh, setContactRefresh] = useState(false);

  const [contactToggle, setContactToggle] = useState(false);

  const [userSlected, setUserSlected] = useState({
    id: 0,
    first_name: "John",
    last_name: "Doe",
    image: "url"
  });


  useEffect(() => {
    if (userSlected.id !== 0) {
      setPageSize(50);
      setMetaData(null);
      setLiveChatData([]);
      downloadChat(userSlected.user_id, session.user_id);
      if (userSlected.channel_id !== null) {
        connectToChannel(userSlected.channel_id);
      } else {
        createChannel();
      }
    }
  }, [userSlected]);

  useEffect(() => {
    if (pageSize !== 50 && pageSize !== null) {
      setLiveChatData([]);
      downloadChatUpside(userSlected.user_id, session.user_id);
    }
  }, [pageSize]);

  const hitDownloadChat = () => {
    setLiveChatData([]);
    downloadChat(userSlected.user_id, session.user_id);
  }



  const privilegeExpireTime = tokenExpireTime(3600);

  async function connectToChannel(channelName) {
    const uuid = await makeId(5);
    const rtmToken = RtmTokenBuilder.buildToken(appId, certificate, uuid, RtmRole.SUBSCRIBER, privilegeExpireTime);
    await chatLogout();
    await chatClient?.login({ uid: uuid, token: rtmToken });
    const tempChannel = await chatClient.createChannel(channelName);
    tempChannel.join();
    setChannel(tempChannel);
  }

  async function createChannel() {
    const uuid = await makeId(5);
    const rtmToken = RtmTokenBuilder.buildToken(appId, certificate, uuid, RtmRole.SUBSCRIBER, privilegeExpireTime);
    await chatLogout();
    await chatClient?.login({ uid: uuid, token: rtmToken });
    const randomChannelName = `AthenaChannel${uuidv4()}`;
    const tempChannel = await chatClient.createChannel(randomChannelName);
    putChannelToDb(randomChannelName);
    tempChannel.join();
    setChannel(tempChannel);
  }


  async function chatLogout() {
    try {
      return await chatClient?.logout();
    } catch (err) {
      console.log("chat logout failed", err);
    }
  }


  useEffect(() => {
    if (channel !== undefined) {
      channel.on('ChannelMessage', (message, memberId) => {

        if (message.text === '%file%') {
          hitDownloadChat();
        } else {
          reflectMessage(message.text, 'recieved', Date.now());
        }


      });
    }
  }), [channel];

  async function sendAgoraMessage() {
    const messageToSend = document.getElementById('msg_box').value;
    const spaceFilter = messageToSend.trim();
    if (spaceFilter !== '') {
      // setCurrentMessage(spaceFilter);
      await channel?.sendMessage({ text: spaceFilter })
        .then((res) => {
          // console.log('sent message to reflect', spaceFilter);
          reflectSentMessage(spaceFilter, 'sent');
          storeChat(spaceFilter);
        })
        .catch((err) => {
          console.log('error while sending message', err);
        });
    }
  }


  async function storeChat(messageToSend) {
    const saveMsgPayload = {
      sender_id: session.user_id,
      receiver_id: userSlected.user_id,
      message: messageToSend
    }
    const apiResponse = await ChatService.saveChat(saveMsgPayload, session.token);
    if (apiResponse.status === 201) {
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  async function sendAgoraFile(fileMessage) {
    // console.log('fileMessage', fileMessage);
    setCurrentMessage("%file%");
    await channel?.sendMessage({ text: "%file%" })
      .then((res) => {
        storeFileMessage(fileMessage);
      })
      .catch((err) => {
        console.log('error while sending message', err);
      });
  }


  async function storeFileMessage(fileMessage) {
    fileMessage.file = fileMessage.file.split('?')[0];
    const saveMsgPayload = {
      sender_id: session.user_id,
      receiver_id: userSlected.user_id,
      message: fileMessage.file,
      is_file: true,
      file_details: fileMessage
    }

    // console.log('to save', saveMsgPayload);

    const apiResponse = await ChatService.saveChat(saveMsgPayload, session.token);
    if (apiResponse.status === 201) {
      hitDownloadChat();
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }


  async function downloadChat(senderId, recieverId) {
    setChatDataLoading(true);
    const apiResponse = await ChatService.chatData(senderId, recieverId, pageSize, session.token);
    setChatDataLoading(false);
    putChatByToBottomHalf();
    if (apiResponse.status === 200) {
      setChatData(apiResponse.data.data.chats.reverse());
      setMetaData(apiResponse.data.data.meta_data);
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  async function downloadChatUpside(senderId, recieverId) {
    setChatDataLoading(true);
    const apiResponse = await ChatService.chatData(senderId, recieverId, pageSize, session.token);
    setChatDataLoading(false);
    if (apiResponse.status === 200) {
      setChatData(apiResponse.data.data.chats.reverse());
      setMetaData(apiResponse.data.data.meta_data);
      putChatToTop();
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  async function putChannelToDb(channelName) {
    const channelPayload = {
      channel_id: channelName,
      participant_one: userSlected.user_id,
      participant_two: session.user_id,
      user_id: session.user_id,
    }
    const apiResponse = await ChatService.createChannelEntry(channelPayload, session.token);
    if (apiResponse.status === 201) {
    } else if (apiResponse.status === 303) {
      setContactRefresh(true);
      enqueueSnackbar(apiResponse.data.message, { variant: "success" });
      setUserSlected({
        id: 0,
        first_name: "John",
        last_name: "Doe",
        image: "url"
      });
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }


  function putChatByToBottom() {
    setTimeout(function () {
      const objDiv = document.getElementById("chatAreaId");
      objDiv.scrollTop = objDiv.scrollHeight - objDiv.clientHeight;
    }, 500);
  }

  function putChatToTop() {
    const objDiv = document.getElementById("chatAreaId");
    objDiv.scrollTop = 0;
  }

  function putChatByToBottomHalf() {
    setTimeout(function () {
      const objDiv = document.getElementById("chatAreaId");
      objDiv.scrollTop = objDiv.scrollHeight - objDiv.clientHeight;
    }, 100);
  }

  async function reflectMessage(messageToSend, pathway, timeToCompare) {

    const targetObject = parseInt(document.getElementById('stateIdentifire').innerHTML);
    // console.log('old time', targetObject);
    // console.log('new message time', timeToCompare);

    let timeRes = timeToCompare - targetObject;
    setStateIdentifire(timeToCompare);
    // console.log('time difference ', timeRes);

    if (timeRes > 1000) {

      let newData;
      if (pathway === "sent") {
        newData = {
          chat_id: Date.now(),
          message: messageToSend,
          receiver_id: userSlected.user_id,
          sender_id: session.user_id,
          created_at: Date.now(),
          updated_at: Date.now()
        };
      } else {
        newData = {
          chat_id: Date.now(),
          message: messageToSend,
          receiver_id: session.user_id, 
          sender_id: userSlected.user_id,
          created_at: Date.now(),
          updated_at: Date.now()
        };
      }

      setLiveChatData(liveChatData => [...liveChatData, newData]);

      const updateJson = {
        receiver_id: session.user_id,
        sender_id: userSlected.user_id
      }
      const apiResponse = await ChatService.updateUnreadCount(updateJson, session.token);
      if (apiResponse.status === 200) {
      } else {
        apiErrorHandler(apiResponse, enqueueSnackbar);
      }

      putChatByToBottomHalf();

      const targetObject = document.getElementById('msg_box');
      targetObject.value = '';
    }

  }

  function reflectSentMessage(messageToSend, pathway) {
    // console.log('reflect message called', messageToSend);

    let newData;
    if (pathway === "sent") {
      newData = {
        chat_id: Date.now(),
        message: messageToSend,
        receiver_id: userSlected.user_id,
        sender_id: session.user_id,
        created_at: Date.now(),
        updated_at: Date.now()
      };
    } else {
      newData = {
        chat_id: Date.now(),
        message: messageToSend,
        receiver_id: session.user_id,
        sender_id: userSlected.user_id,
        created_at: Date.now(),
        updated_at: Date.now()
      };
    }

    setLiveChatData(liveChatData => [...liveChatData, newData]);

    putChatByToBottomHalf();

    const targetObject = document.getElementById('msg_box');
    targetObject.value = '';

  }


  function loadMore() {
    setPageSize(pageSize + 50);
  }

  function contactToggleAction(){
    setContactToggle( !contactToggle );
  }

  // console.log('chatList', chatList);
  // console.log('userSlected', userSlected);
  // console.log('chatData', chatData);
  // console.log('liveChatData', liveChatData);
  // console.log('channel', channel);
  // console.log('metaData', metaData);
  // console.log('pageSize', pageSize);

  return (
    <>
      <div className="chat-wrapper">

        <div className="page-title" >Chat</div>
        <div className="page-title d-none" id="stateIdentifire" >{stateIdentifire}</div>

        <div className="chat-block">

          <div className={`contact-toggle ${contactToggle === true ? ' active ' : ' inactive ' } `} onClick={contactToggleAction} > <KeyboardArrowLeft /> </div>

          <Contact
            contactRefresh={contactRefresh}
            setContactRefresh={setContactRefresh}
            setUserSlected={setUserSlected}
            role={role}
            userSlected={userSlected}
            contactTobe={contactToggle}
          />

          <div className="chat-section">

            <div className="chat-header-wrapper">
              <div className="name-inititiol-contact ml-2">
                <b>{userSlected.first_name.slice(0, 1)}</b>
                {userSlected.last_name.slice(0, 1) ?
                  <b>{userSlected.last_name.slice(0, 1)}</b>
                  : null}
              </div>
              <div className="sender-name"> {userSlected.first_name} {userSlected.last_name} </div>
            </div>

            <div className="chat-view-block scrollbar" id="chatAreaId">

              {chatDataLoading === true ?
                <CircularProgress />
                :
                <>

                  {chatData.length > 0 && metaData !== null && chatData.length < metaData.total_count ?
                    <div className="flex justify-center"> <span className="cursor-pointer" onClick={loadMore} > Load More </span> </div>
                    : null}

                  {chatData.map((item, index) => {
                    return (
                      <ChatCard chatDetails={item} key={index} />
                    );
                  })}

                </>
              }

              {liveChatData.map((item, index) => {
                return (
                  <div
                    className={` ${item.sender_id === session.user_id ? 'outgoing-message-wrapper' : 'incoming-message-wrapper'} `}
                    key={index}
                  >
                    <div className="incoming-message">
                      <div className='actual-message'>{item.message}</div>
                      <div className='message-time'>{moment(item.created_at).format('DD-MM-YYYY hh:mm A')}</div>
                    </div>
                  </div>
                )
              })}

            </div>

            <InputType sendAgoraMessage={sendAgoraMessage} hitDownloadChat={hitDownloadChat} sendAgoraFile={sendAgoraFile} />

            {userSlected.id == 0 ?
              <div className="user-not-selected">Select a user to proceed.</div>
              : null}

          </div>

        </div>
      </div>
    </>
  );
}

export default Chat;