import React from 'react';

import { useHistory } from 'react-router';

import SignUpForm from './SignUpForm';
import SignInForm from './SignInForm';

import logoalt from '../../assets/image/illustration.svg';
import logoFull from '../../assets/image/logo_new-white.png';
import logonew from '../../assets/image/logo_new-white-fav.png';

import '../../sass/appk.css';

const SignUp = () => {

  const path = useHistory().location.pathname;
  
  return (
    <body className="login">
      <div className="container sm:px-10">
        <div className="block xl:grid grid-cols-2 gap-4">
          <div className="hidden xl:flex flex-col min-h-screen">
            <a href="" className="-intro-x flex items-center pt-5">
              <img alt="athena" src={logoFull} />
            </a>
            <div className="my-auto">
              <img alt="at" className="-intro-x w-1/2 -mt-16" src={logoalt} />
              <div className="-intro-x text-white font-medium text-4xl leading-tight mt-10">
                A few more clicks to
                <br />
                sign in to your account.
              </div>
              <div className="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500">Helping You Protect Your Business</div>
            </div>
          </div>

          {path === "/" ? <SignInForm /> : <SignUpForm />}

        </div>
      </div>
    </body>
  );
}

export default SignUp;