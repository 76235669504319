import API from "./API"
import { API_WRONG, ORDER_CREATED } from "./messages";
import timeValidator from './tapOutChecker';
// var intervalID = {};
// function timeChecker() {
//   intervalID.Id = setInterval(() => {
//     alert(messages.API_CROSS_TIME_LIMIT);
//   }, [10000]);
// }

const getServices = async (token) => {

  await timeValidator();

  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.get("product_operations/", config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let errorResponse = { status: "", statusText: API_WRONG };
        return resolve(errorResponse);
      }
    }
  });
};

const getOrderId = async (orderIdPayload, token) => {
  await timeValidator();
  return new Promise((resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = API.post("get_order_info/", orderIdPayload, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "Hello", statusText: ORDER_CREATED };
        return resolve(error);
      }
    }
  });
};

const postServiceData = async (dataToTransfer, token) => {
  await timeValidator();
  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await API.post("submit_ticket_info/", dataToTransfer, config);
      // console.log('got this', response);
      return resolve(response);
    } catch (error) {
      // console.log('got this', error, error.response);
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  });
};

const getUserNotification = async (user_id, size, page, token) => {
  await timeValidator();
  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await API.get(`notification/all/?user_id=${user_id}&size=${size}&page=${page}`,config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  });
};


//ADDING for making api call onlclick notificaton
const getReadNotificaton = async (get_userId, token) => {
  await timeValidator();
  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await API.put(`/notification/mark_as_read/`, { "notification_id": get_userId }, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  })
}

const getOrders = async (user_id, token) => {
  await timeValidator();
  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await API.get(`get_orders_by_tickets_by_state/?user_id=${user_id}`, config);
      // console.log('got this', response);
      return resolve(response);
    } catch (error) {
      // console.log('got this', error, error.response);
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  })
}

const getTickitDetails = async (ticket_id, token) => {
  await timeValidator();
  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await API.get(`get_ticket_status/${ticket_id}/`, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  })
}

const updateTicketState = async (updateJson, token) => {
  await timeValidator();
  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await API.put("update_running_status/", updateJson, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  })
}

const uploadToCart = async (dataToTransfer, token) => {
  await timeValidator();
  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await API.post("cart/", dataToTransfer, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  });
};

const cartItemCount = async (userId, token) => {
  await timeValidator();
  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await API.get(`cart-count/?user_id=${userId}`, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  });
};

const getCartItem = async (user_id, token) => {
  await timeValidator();
  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await API.get(`cart/?user_id=${user_id}`, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  });
};

const deleteCartItem = async (cart_id, token) => {
  await timeValidator();
  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await API.delete(`cart/?cart_id=${cart_id}`, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  });
};

const verifyOrder = async (order_id, token) => {
  await timeValidator();
  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await API.get(`verify/?order_id=${order_id}`, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  });
};

const orderDelete = async (order_id, token) => {
  await timeValidator();
  return new Promise(async (resolve, reject) => {
    try {
      // const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await API.delete(`order_delete/?order_id=${order_id}`);
      // console.log('got this', response);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  });
};


const getUploadId = async (token) => {
  await timeValidator();
  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await API.post("document/", 'dummy', config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  });
};

const getDocumentId = async (token) => {
  await timeValidator();
  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await API.post("document/", 'dommy', config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  });
};

const fileUpload = async (uploadDetails, token) => {
  await timeValidator();
  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await API.post("file_upload/", uploadDetails, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  });
};

const getFiles = async (document_id, token) => {
  await timeValidator();
  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const response = await API.get(`file_upload/?document_id=${document_id}`, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  });
};

const verifyEmail = async (email) => {
  await timeValidator();
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(`email_verify/?email=${email}`);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  });
};

export const UserService = {
  getServices,
  getOrderId,
  postServiceData,
  getUserNotification,
  getOrders,
  getTickitDetails,
  updateTicketState,
  getReadNotificaton,
  uploadToCart,
  getCartItem,
  deleteCartItem,
  verifyOrder,
  getUploadId,
  orderDelete,
  getDocumentId,
  fileUpload,
  getFiles,
  verifyEmail,
  cartItemCount
}
