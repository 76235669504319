import React, { useEffect, useState } from "react";

import { useSession } from 'react-use-session';
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import AthenaCommonCard from "../../Components/CommonCards/AthenaCommonCard";
import CircularProgress from '@material-ui/core/CircularProgress';


import { UserService } from '../../utils/userServices';
import { apiErrorHandler } from '../../utils/helper';

import logo from "../../assets/image/thunder-bolt.png";

function ProductPage({
  setPageTitleOne,
  setPageTitleTwo,
  setPageTitleThree,
  setCommonPageTitleCheck,
  setPageTitle
}) {

  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const { session } = useSession("cyber-security");
  const sessionToken = session.token;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPageTitleOne("Home");
    setPageTitleTwo("Product");
    setPageTitleThree("");
    setPageTitle("");
    setCommonPageTitleCheck(true);
  }, []);

  function goToService(direction) {
    history.push(direction);
  }


  async function directToThunder() {
    setLoading(true);
    const apiResponse = await UserService.getServices(sessionToken);
    setLoading(false);
    if (apiResponse.status === 200) {
      direct(apiResponse.data.data.thunderbolt)
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  function direct(dataWith) {
  
    history.push({
      pathname: `/subscription`,
      state: {
        selectedOne: dataWith
      }
    });

  }

  return (
    <div className="product-wrapper">
      <div className="product-first-item">
        <AthenaCommonCard
          height="120px"
          width="240px"
          title="Athena"
          backgroundColorValue="#ffffff"
          handleClick={goToService.bind('', "\athena")}
          launch={true}
        />
      </div>
      <div className="product-second-item">

        <div style={{ width: "240px", height: "120px", backgroundColor: "#ffffff" }} className="proBoxes ">
          <div className="commonLeftContainer">
            <div className="commonTextContainer">
              <p className="textlightAlt  popbold card_title fs18px-wrap">Thunder Bolt</p>
            </div>
            {loading === true ?
              <div className="service-loading"> <CircularProgress style={{ width: "20px", height: "20px" }} /> </div>
              :
              <div className="serviceCont blue-btn" onClick={directToThunder} >LAUNCH </div>
            }
          </div>

          <div className="commonRightContainer ">
            <img src={logo} className="producImageThunder" />
          </div>
        </div>
        <div className="mobile-box-service">
          <div className="supportive"></div>
          <div className="image-section">
            <img src={logo} className="producImage" />
          </div>
          <div className="title-section">Thunderbold </div>
        </div>

      </div>
    </div>
  );
}

export default ProductPage;
