import { COUNTER_ACTION } from "../../utils/constants";

const initialState = {
  fluctuate: false,
}

const counter = (state = initialState, action) => {
  switch (action.type) {
    case COUNTER_ACTION:
      return {
        ...state,
        fluctuate: action.payload
      };

    default:
      return { ...state };
  }
};

export default counter;

