import axios from "axios";

//production server
export default axios.create({
  baseURL: "https://athena.atheniantech.com/api/",
  responseType: "json"
});


//local server
// export default axios.create({
//   baseURL: "http://127.0.0.1:8000/api/",
//   responseType: "json"
// });