import React, { Fragment } from 'react';
import VariableSizedInputComponent from "../Components/FormComponents/VariableSizedInputComponent";
import MultipleSelection from "../Components/FormComponents/MultipleSelection";
import ScheduleLaunchComponent from "../Components/FormComponents/ScheduleLaunchComponent";
import MultipleSocialMedia from "../Components/FormComponents/MultipleSocialMedia";

const addAnother = (element) => {

  const maxStep = parseInt(element.currentTarget.id);
  const targetDom = element.currentTarget.previousSibling;
  const newTargetHeight = targetDom.offsetHeight + 80;

  if (newTargetHeight <= (maxStep * 80)) {
    targetDom.style.height = `${newTargetHeight}px`;
  } else {
    element.currentTarget.style.display = "none";
  }

};

const FormBuilder = (
  {
    formsInput,
    formData,
    setFormData,
    dataEntries,
    setDataEntries,
    dependency,
    errors,
    setisFileSelected,
    formName,
    subscriptionTitle
  }
) => {

  // console.log(' god help please ',  subscriptionTitle);

  return (

    formsInput.map((key, index) => {

      if (key.id === "1") {
        return (
          <div className="form-box-wrapper" key={index}>
            <VariableSizedInputComponent
              title={key.title}
              fields={key.fields}
              formData={formData}
              setFormData={setFormData}
              dataEntries={dataEntries}
              setDataEntries={setDataEntries}
              dependency={formName}
              errors={errors}
              required={key.required}
              setisFileSelected={setisFileSelected}
            />
          </div>
        );
      }

      if (key.id === "3") {
        return (
          <div className="form-box-wrapper" key={index}>
            <ScheduleLaunchComponent
              title={key.title}
              fields={key.fields}
              formData={formData}
              setFormData={setFormData}
              dataEntries={dataEntries}
              setDataEntries={setDataEntries}
              errors={errors}
              required={key.required}
              subscriptionTitle={subscriptionTitle}
            />
          </div>
        );
      }

      if (key.id === "4") {
        return (
          <Fragment key={index}>
            <div
              className="d-block oh"
              style={{ height: "80px" }}
            >

              <MultipleSelection
                title={key.title}
                fields={key.fields}
                formData={formData}
                setFormData={setFormData}
                dataEntries={dataEntries}
                setDataEntries={setDataEntries}
                errors={errors}
              />

            </div>
            {Object.keys(key.fields).length > 1 ? (
              <div
                className="add-another-text"
                id={Object.keys(key.fields).length}
                onClick={addAnother}
              >
                Add another Section
              </div>
            ) : null}
          </Fragment>
        );
      }

      if (key.id === "5") {
        return (
          <Fragment key={index}>
            <div
              className="form-box-wrapper d-block oh pt-0"
              style={{ height: "80px" }}
            >
              <MultipleSocialMedia
                title={key.title}
                fields={key.fields}
                formData={formData}
                setFormData={setFormData}
                dataEntries={dataEntries}
                setDataEntries={setDataEntries}
                errors={errors}
                required={key.required}
              />
            </div>
            {Object.keys(key.fields).length > 1 ? (
              <div
                className="add-another-text"
                id={Object.keys(key.fields).length}
                onClick={addAnother}
              >
                Add another Section
              </div>
            ) : null}
          </Fragment>
        );
      }

    }
    )

  );
}

export default FormBuilder;