export const API_WRONG = "Something went wrong with the API";
export const EMAIL_SENT ="An otp has been sent to your email id and it is valid for 10 minutes";
export const INVALID_LOGIN = "Email or Password is wrong. Please try again!";
export const ORDER_CREATED = "Order Created Successfully";
export const ADD_TO_CART = "Product moved to cart";
export const CART_FETCHED = "Product moved to cart";
export const FILE_SIZE_2MB = "File size should be under 2mb";
export const IMAGE_SIZE_2MB = "Image size should be under 2mb";
export const IMAGE_SIZE_1MB = "Image size should be under 1mb";
export const IMAGE_TYPE_FILTER = "Please add image like jpeg or png or jpg";
export const SELECT_ONE_SERVICE = "Please select at least one service from the products";
export const HAVE_TO_AGREE = "You have to agree to the terms and conditions";
export const FILL_MANDATORY = "Please complete all the mandatory fields";
export const API_NOT_FOUND = "API not found";
export const OREDR_DELETED = "Order deleted";
export const PASSWORD_NOT_STRONG = "Password is not strong";
export const FILE_SIZE = "File size should be under 10mb";
export const FILE_SIZE_8MB = "File size should be under 8mb";
export const MUST_SELECT_PRODUCT = "You must have to select a product.";
export const SUBSCRIPTION_FOR_ALL = "You have to select subscription for all.";
export const EXPERTIES_SELECT = "You have to select atleast one experties.";
export const FILE_PDF_ONLY = "Only pdf is allowed.";
export const FILE_SIZE_5MB = "File size should be under 5mb.";
export const SUBJECT_NOT_EMPITY = "Email subject cannot be empty.";
export const SUBJECT_MIN_LENGTH = "Subject minimum length is 3 characters.";
export const SUBJECT_MAX_LENGTH = "Subject maximum length is 100 characters.";
export const EMAIL_BODY_EMPITY = "Mail body cannot be empty.";
export const RECIEVER_REQUIRED = "Please select a reciever.";
export const MAIL_SEND = "Mail send successfully.";
export const MARK_UPDATED = "Mark updated.";
export const FILE_FORMAT = "xlsx, xls, pdf, doc, docx are allowed format";
export const ALLOWED_FILES = ".png, .PNG, .jpg, .JPG, .jpeg, .JPEG, .svg, .SVG, .gif, .GIF, .pdf, .PDF, .xlsx, .docx, .doc, .xls";
export const ALLOWED_IMAGE_FILES = ".png, .PNG, .jpg, .JPG, .jpeg, .JPEG, .svg, .SVG";
export const ALLOWED_DOCS_FILES = " .pdf, .PDF, .xlsx, .docx, .doc, .xls";
export const ALLOWED_PDF_ONLY = " .pdf, .PDF";
export const FILE_SIZE_LARGE = " File Size Too Large";
export const REPORT_BEFORE_CLOSE = "You can only upload file before closing the ticket.";



