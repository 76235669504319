import React, { useEffect, useState } from "react";

import { useSnackbar } from "notistack";

import { IMAGE_SIZE_2MB, IMAGE_TYPE_FILTER, ALLOWED_IMAGE_FILES } from "../../utils/messages";

import pictureDefault from '../../assets/image/input-picture-default.png';

function PictureInputComponent(
  {
    title,
    width,
    formData,
    setFormData,
    dataEntries,
    setDataEntries,
    setisFileSelected,
    documentId,
    placeholder
  }
) {

  const { enqueueSnackbar } = useSnackbar();
  const [imgSrc, setimgSrc] = useState(null);

  let dataRequiredFromLocalStorage = JSON.parse(localStorage.getItem("dataEntriesInLocal"));

  const addPhoto = (file, id) => {
    if (
      file.name.includes("jpeg") ||
      file.name.includes("png") ||
      file.name.includes("jpg")
    ) {
      if (file.size < 2e+6) {
        setisFileSelected(true);

        let holdme = URL.createObjectURL(file);

        setimgSrc(holdme);

      } else {
        enqueueSnackbar(IMAGE_SIZE_2MB, { variant: "error" });
      }
    } else {
      enqueueSnackbar(IMAGE_TYPE_FILTER, { variant: "error" });
    }
  };

  useEffect(() => {
    if (imgSrc !== null) {
      const elephant = document.getElementById("elephant");
      const placeholderObj = document.getElementById("file-input");
      elephant.addEventListener("load", function () {
        let imgCanvas = document.createElement("canvas"),
          imgContext = imgCanvas.getContext("2d");
        imgCanvas.width = elephant.width;
        imgCanvas.height = elephant.height;

        imgContext.drawImage(elephant, 0, 0, elephant.width, elephant.height);

        const imgAsDataURL = imgCanvas.toDataURL("image/png");

        const obj = { ...formData, [placeholderObj.placeholder]: imgAsDataURL };
        setFormData(obj);

        const objForImage = { ...dataRequiredFromLocalStorage, "Picture": imgAsDataURL };
        localStorage.setItem("dataEntriesInLocal", JSON.stringify(objForImage));

      }, false);
    }
  }, [imgSrc]);

  return (
    <>
      <div className="">
        <div className="upload-picture-container">

          <div className="avatar-image-container spanImgCenter">
            {imgSrc === null ?
              <img src={
                dataRequiredFromLocalStorage[placeholder] ? dataRequiredFromLocalStorage[placeholder] : pictureDefault
              } className="uploaded-image" id="elephant"
              />
              :
              <img src={imgSrc} className="uploaded-image" id="elephant" />
            }

          </div>

          <div className="button-wrapper">
            <label htmlFor="file-input" className="upload-picture-button">Upload Picture</label>
            <input
              id="file-input"
              type="file"
              className="d-none"
              accept={ALLOWED_IMAGE_FILES}
              placeholder={placeholder}
              onChange={(file) => { addPhoto(file.target.files[0], file.target); }}
            />
          </div>

        </div>
        <div className="picture-attached">Picture Attached</div>
      </div>
    </>
  );
}

export default PictureInputComponent;
