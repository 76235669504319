import API from "./API"
import { API_WRONG, ORDER_CREATED } from "./messages";
import timeValidator from './tapOutChecker';

const getAllFeedback = async (pageNumber, pageSize, token) => {

  await timeValidator();

  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.get(`feedback/?size=${pageSize}&page_no=${pageNumber}`, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let errorResponse = { status: "", statusText: API_WRONG };
        return resolve(errorResponse);
      }
    }
  });
};

const deleteFeedback = async (feedbackId, token) => {

  await timeValidator();

  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.delete(`feedback/?feedback_id=${feedbackId}`, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let errorResponse = { status: "", statusText: API_WRONG };
        return resolve(errorResponse);
      }
    }
  });
};

const updateFeedback = async (updatePayload, token) => {

  await timeValidator();

  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.put(`feedback/`, updatePayload, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let errorResponse = { status: "", statusText: API_WRONG };
        return resolve(errorResponse);
      }
    }
  });
};

const feedbackStats = async (token) => {

  await timeValidator();

  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.get(`feedback_stats/`, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let errorResponse = { status: "", statusText: API_WRONG };
        return resolve(errorResponse);
      }
    }
  });
};

const getAllFeedbackWithFilter = async (pageNumber, pageSize, filter, token) => {

  await timeValidator();

  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.get(`feedback/?size=${pageSize}&page_no=${pageNumber}&stars=${filter}`, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let errorResponse = { status: "", statusText: API_WRONG };
        return resolve(errorResponse);
      }
    }
  });
};

const updateTicketFeedback = async (updatePayload, token) => {

  await timeValidator();

  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.put(`update_ticket_feedback/`, updatePayload, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let errorResponse = { status: "", statusText: API_WRONG };
        return resolve(errorResponse);
      }
    }
  });
};

export const FeedbackService = {
  getAllFeedback,
  deleteFeedback,
  updateFeedback,
  feedbackStats,
  getAllFeedbackWithFilter,
  updateTicketFeedback
}
