import { API_WRONG, } from "./messages";
import API from "./API";
import timeValidator from './tapOutChecker';


const seveRequest = async (savePayloadad, token) => {

  await timeValidator();

  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.post(`demo-oprations/`, savePayloadad, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let errorResponse = { status: "", statusText: API_WRONG };
        return resolve(errorResponse);
      }
    }
  });
};


const getDemoRequest = async (size, pageNumber, token) => {

  await timeValidator();

  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.get(`demo-oprations/?&size=${size}&page_no=${pageNumber}`, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let errorResponse = { status: "", statusText: API_WRONG };
        return resolve(errorResponse);
      }
    }
  });
};

export const DemoRequest = {
  seveRequest,
  getDemoRequest
}
