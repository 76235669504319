import msg from './messase.json';

export default async function validate(errObj, seterrors, setformSubmit) {

  const errors = {};

  // console.log('sdasdadadsadsadadadasd', errObj);

  var checkSpecialCharacterRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  const checkForNumber = (myString) => { return /\d/.test(myString); };

  var checkValidEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  var checkCharacterInStringRegex = /[a-zA-z]/i;

  var checkValidPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[@$!%*?&])[A-Za-zd@$!%*?&]{8,}$/;

  var alphaAndDash = /^[A-Za-z][A-Za-z0-9-]*$/;
  var alphaAndDot = /^[A-Za-z][A-Za-z0-9-]*$/;
  var domainName = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
  // var domainName = /^((?!-)[A-Za-z0-9-]{1, 63}(?<!-)\\.)+[A-Za-z]{2, 6}$/;
  // var domainName = /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/;
  // var domainName = /^(((?!\-))(xn\-\-)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn\-\-)?([a-z0-9\-]{1,61}|[a-z0-9\-]{1,30})\.[a-z]{2,}$/;

  var ipregex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;


  var containsAnyNumber = /[0-9]/;

  if (typeof (errObj) == 'object' && Object.keys(errObj).length) {

    await Object.keys(errObj).map(async (item, index) => {

      if (item == "Anything Else You'd Like Us To Know") {
        if (errObj[item].length > 500) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "IP") {
        if (errObj[item]) {
          if (ipregex.test(errObj[item]) && errObj[item].length < 32) {
            errors[item] = '';
          } else {
            errors[item] = [msg.INVALID_IP];
          }
        } else {
        }
      }

      if (item == "Start Date *") {
        if (errObj[item]) {
          errors[item] = '';
        } else {
          errors[item] = [msg.FIELD_REQUIRED];
        }
      }

      // if (item == "End Date *") {
      //   if (errObj[item]) {
      //     errors[item] = '';
      //   } else {
      //     errors[item] = [msg.FIELD_REQUIRED];
      //   }
      // }

      if (item == "End Date *") {
        if (errObj[item]) {
          // const startDate = new Date(errObj["Start Date *"]);
          // const endDate = new Date(errObj[item]);
          // if (startDate > endDate) {
          //   errors[item] = [msg.END_DATE];
          // }
        } else {
          errors[item] = [msg.FIELD_REQUIRED];
        }
      }

      if (item == "Domain *") {
        if (errObj[item]) {
          if (domainName.test(errObj[item]) && errObj[item].length < 63) {
            errors[item] = '';
          } else {
            errors[item] = [msg.INVALID_DOMAIN];
          }
        } else {
          errors[item] = [msg.FIELD_REQUIRED];
        }
      }

      if (item == "Domain Name") {
        if (errObj[item]) {
          if (errObj[item].length > 63) {
            errors[item] = [msg.VALUE_LONG];
          } else if (!alphaAndDash.test(errObj[item])) {
            errors[item] = [msg.INCORRECT_ENTRY];
          } else {
            errors[item] = '';
          }
        } else {
          errors[item] = [msg.FIELD_REQUIRED];
        }
      }

      if (item == "Name of Organization *") {
        if (errObj[item]) {
          // console.log('go this', errObj[item].indexOf(' '));
          const whiteSpace = errObj[item].indexOf(' ');
          const torIndex = errObj[item].indexOf('^');
          const percantage = errObj[item].indexOf('%');
          const doller = errObj[item].indexOf('$');
          const hash = errObj[item].indexOf('#');
          if (whiteSpace == 0 || torIndex == 0 || percantage == 0 || doller == 0 || hash == 0) {

            if (whiteSpace == 0) {
              errors[item] = [msg.WHITE_SPACE];
            } else if (torIndex == 0) {
              errors[item] = [msg.SPECIAL_CHAR];
            } else if (percantage == 0) {
              errors[item] = [msg.SPECIAL_CHAR];
            } else if (doller == 0) {
              errors[item] = [msg.SPECIAL_CHAR];
            } else {
              errors[item] = [msg.SPECIAL_CHAR];
            }

          } else {
            if (errObj[item].length > 63) {
              errors[item] = [msg.VALUE_LONG];
            } else {
              errors[item] = '';
            }
          }
        } else {
          errors[item] = [msg.FIELD_REQUIRED];
        }
      }

      // if (item == "Mobile Application") {
      //   if (errObj[item].length > 63) {
      //     errors[item] = [msg.VALUE_LONG];
      //   } else if (!alphaAndDash.test(errObj[item])) {
      //     errors[item] = [msg.INCORRECT_ENTRY];
      //   } else {
      //     errors[item] = '';
      //   }
      // }


      if (item == "Name of Client *") {
        if (errObj[item]) {

          if (containsAnyNumber.test(errObj[item]) || errObj[item].includes('@')) {
            errors[item] = [msg.INVALID_CLIENT_NAME];
          } else {
            errors[item] = '';
          }
        } else {
          errors[item] = [msg.FIELD_REQUIRED];
        }
      }

      if (item == "Designation *") {
        if (errObj[item]) {
          if (errObj[item].includes('%') || errObj[item].includes('$')) {
            errors[item] = [msg.INVALID_DESIGNATION_NAME];
          } else {
            errors[item] = '';
          }
        } else {
          errors[item] = [msg.FIELD_REQUIRED];
        }
      }

      if (item == "Email *") {
        if (errObj[item]) {
          if (checkValidEmailRegex.test(errObj[item])) {
            errors[item] = '';
          } else {
            errors[item] = [msg.INVALID_EMAIL];
          }
        } else {
          errors[item] = [msg.FIELD_REQUIRED];
        }
      }

      if (item == "Email") {
        if (errObj[item]) {
          if (checkValidEmailRegex.test(errObj[item])) {
            errors[item] = '';
          } else {
            errors[item] = [msg.INVALID_EMAIL];
          }
        } else {
          errors[item] = [msg.FIELD_REQUIRED];
        }
      }

      if (item == "Email 1") {
        if (errObj[item]) {
          if (checkValidEmailRegex.test(errObj[item])) {
            errors[item] = '';
          } else {
            errors[item] = [msg.INVALID_EMAIL];
          }
        }
      }

      if (item == "User Name") {
        if (errObj[item].length > 63) {
          errors[item] = [msg.VALUE_LONG];
        } else if (!alphaAndDash.test(errObj[item])) {
          errors[item] = [msg.INCORRECT_ENTRY];
        } else {
          errors[item] = '';
        }
      }

      if (item == "User Name 1") {
        if (errObj[item].length > 63) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "User Name 2") {
        if (errObj[item].length > 63) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "Organization Name") {
        if (errObj[item]) {
          if (errObj[item].length > 63) {
            errors[item] = [msg.VALUE_LONG];
          } else {
            errors[item] = '';
          }
        } else {
          errors[item] = [msg.FIELD_REQUIRED];
        }
      }

      if (item == "Number Of Years") {
        if (errObj[item].length > 15) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          if (isNaN(errObj[item])) {
            errors[item] = [msg.ONLY_NUMBER_ALLOWED];
          } else {
            errors[item] = '';
          }
        }
      }

      if (item == "Mail Server Name") {
        if (errObj[item]) {
          if (errObj[item].length > 63) {
            errors[item] = [msg.VALUE_LONG];
          } else if (!alphaAndDot.test(errObj[item])) {
            errors[item] = [msg.INCORRECT_ENTRY];
          } else {
            errors[item] = '';
          }
        } else {
          errors[item] = '';
        }
      }

      // if (item == "Vendor Domain") {
      //   if (errObj[item].length > 63) {
      //     errors[item] = [msg.VALUE_LONG];
      //   } else {
      //     errors[item] = '';
      //   }
      // }

      if (item == "Vendor Domain *") {
        if (errObj[item]) {
          if (domainName.test(errObj[item]) && errObj[item].length < 63) {
            errors[item] = '';
          } else {
            errors[item] = [msg.INVALID_DOMAIN];
          }
        } else {
          errors[item] = [msg.FIELD_REQUIRED];
        }
      }

      if (item == "Vendor Email Address") {
        if (errObj[item]) {
          if (checkValidEmailRegex.test(errObj[item])) {
            errors[item] = '';
          } else {
            errors[item] = [msg.INVALID_EMAIL];
          }
        } else {
          errors[item] = '';
        }
      }

      if (item == "Vendor Location") {
        if (errObj[item].length > 42) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "Phone Number *") {
        if (errObj[item] == '') {
          errors[item] = [msg.FIELD_REQUIRED];
        } else {
          if (errObj[item].length > 10) {
            errors[item] = [msg.VALUE_LONG];
          } else if (errObj[item].length < 10) {
            errors[item] = [msg.CONTCT_NUMBER];
          } else {
            errors[item] = '';
          }
        }
      }

      if (item == "Phone Number") {
        if (errObj[item] == '') {
          errors[item] = [msg.FIELD_REQUIRED];
        } else {
          if (errObj[item].length > 10) {
            errors[item] = [msg.VALUE_LONG];
          } else if (errObj[item].length < 10) {
            errors[item] = [msg.CONTCT_NUMBER];
          } else {
            errors[item] = '';
          }
        }
      }

      if (item == "Phone Number 1") {
        if (errObj[item].length) {
          if (errObj[item].length > 10) {
            errors[item] = [msg.VALUE_LONG];
          } else if (errObj[item].length < 10) {
            errors[item] = [msg.CONTCT_NUMBER];
          } else {
            errors[item] = '';
          }
        }
      }

      if (item == "Vendor Phone Number") {
        if (errObj[item].length > 10) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "Proxy Server Detail") {
        if (errObj[item].length > 264) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "Remote Work Place Platform") {
        if (errObj[item].length > 63) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "VPN Detail") {
        if (errObj[item].length > 264) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "Time Period") {
        if (errObj[item].length > 100) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "City") {
        if (errObj[item].length > 40) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "Area") {
        if (errObj[item].length > 40) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "Client Address") {
        if (errObj[item].length > 40) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "Client School/Colleges") {
        if (errObj[item].length > 63) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "Company Location") {
        if (errObj[item].length > 40) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "DOB") {
        if (errObj[item].length > 100) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "Executive/s Name") {
        if (errObj[item].length > 63) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "Landmark") {
        if (errObj[item].length > 64) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "Number Of Family Members") {
        if (errObj[item] > 2) {
          errors[item] = [msg.TWO_ALLOW];
        } else {
          errors[item] = '';
        }
      }

      if (item == "Occupation") {
        if (errObj[item].length > 63) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "Relation") {
        if (errObj[item].length > 63) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "Street") {
        if (errObj[item].length > 40) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "Logo URL") {
        if (errObj[item].length > 63) {
          console.log("length of this", errObj[item].length);
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "Favicon Hash Value") {
        if (errObj[item].length > 20) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "Name") {
        if (errObj[item].length > 63) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }

      if (item == "Address") {
        if (errObj[item].length > 100) {
          errors[item] = [msg.VALUE_LONG];
        } else {
          errors[item] = '';
        }
      }


      if (item == "Primary Email *") {
        if (errObj[item]) {
          if (checkValidEmailRegex.test(errObj[item])) {
            errors[item] = '';
          } else {
            errors[item] = [msg.INVALID_EMAIL];
          }
        } else {
          errors[item] = [msg.FIELD_REQUIRED];
        }
      }

      if (item == "Secondary Email") {
        if (errObj[item]) {
          if (checkValidEmailRegex.test(errObj[item])) {
            errors[item] = '';
          } else {
            errors[item] = [msg.INVALID_EMAIL];
          }
        }
      }


      if (item == "Alternate Phone Number") {
        if (errObj[item]) {
          if (errObj[item].length > 10) {
            errors[item] = [msg.VALUE_LONG];
          } else if (errObj[item].length < 10) {
            errors[item] = [msg.CONTCT_NUMBER];
          } else {
            errors[item] = '';
          }
        }
      }

      if (item == "Vendor Phone Number") {
        if (errObj[item]) {
          if (errObj[item].length > 10) {
            errors[item] = [msg.VALUE_LONG];
          } else if (errObj[item].length < 10) {
            errors[item] = [msg.CONTCT_NUMBER];
          } else {
            errors[item] = '';
          }
        }
      }

      if (item == "Alternate Vendor Phone Number") {
        if (errObj[item]) {
          if (errObj[item].length > 10) {
            errors[item] = [msg.VALUE_LONG];
          } else if (errObj[item].length < 10) {
            errors[item] = [msg.CONTCT_NUMBER];
          } else {
            errors[item] = '';
          }
        }
      }


      if (item == "Vendor Primary Email") {
        if (errObj[item]) {
          if (checkValidEmailRegex.test(errObj[item])) {
            errors[item] = '';
          } else {
            errors[item] = [msg.INVALID_EMAIL];
          }
        }
      }

      if (item == "Vendor Secondary Email") {
        if (errObj[item]) {
          if (checkValidEmailRegex.test(errObj[item])) {
            errors[item] = '';
          } else {
            errors[item] = [msg.INVALID_EMAIL];
          }
        }
      }

    });

    // console.log('waht we got ', errors);
    Promise.resolve()
      .then(() => {
        errors['item'] = '';
        seterrors(errors);
      })
      .then(() =>
        setformSubmit(true)
      )
  }
  return null;
}