import React, { useState, useEffect } from "react";

import { useSession } from 'react-use-session';
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

import { UserService } from '../../utils/userServices';
import { MUST_SELECT_PRODUCT } from '../../utils/messages';
import { apiErrorHandler } from '../../utils/helper';

import athenaProductLogo from '../../assets/image/athena-mod.png';

import './athena.scss';
import validate from "../../utils/validations";

function Dashboard({
  setPageTitleOne,
  setPageTitleTwo,
  setPageTitle,
  setPageTitleThree,
  setCommonPageTitleCheck,
  setSelectedServices,
  allSelected,
  clickedService,
  setClickedService
}) {

  
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useSession("cyber-security");
  const sessionToken = session.token;
  const history = useHistory();

  const [allServices, setallServices] = useState([]);
  const [serviceLoading, setServiceLoading] = useState(true);

  const [pickedService, setpickedService] = useState([]);

  useEffect(() => {
    setSelectedServices({});
    setPageTitleOne("Home");
    setPageTitleTwo("");
    setPageTitle("");
    setPageTitleThree("Product");
    setCommonPageTitleCheck(true);
    setpickedService([]);
    fetchServices();
  }, [0]);


  useEffect(() => {
    if (clickedService !== null & allServices.length > 0) {
      oprateSelected(clickedService);
    }
  }, [allServices]);

  function oprateSelected(clickedService) {
    const holdVal = Object.create(allServices);
    for (let value of holdVal) {
      if (value.service_name == clickedService) {
        console.log('will see ', value);
        toAddition(pickedService, value);
        break;
      }
    }
  }

  async function fetchServices() {
    const apiResponse = await UserService.getServices(sessionToken);
    setServiceLoading(false);
    // console.log('service list', apiResponse);
    if (apiResponse.status === 200) {
      setallServices(apiResponse.data.data.athena);
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  function selectThis(item) {
    try {
      const newOrgin = Object.create(pickedService);
      for (let value of newOrgin) {
        if (value.service_id == item.service_id) {
          toRemove(newOrgin, item);
          willsee
        }
      }
      toAddition(newOrgin, item);
    } catch (error) {
      console.log('error', error);
    }

  }

  function toRemove(origin, toOprate) {
    let newArrray = [];
    for (let value of origin) {
      if (value.service_id !== toOprate.service_id) {
        newArrray.push({
          description: value.description,
          img: value.img,
          index: value.index,
          service_id: value.service_id,
          service_name: value.service_name,
          service_inputformjson: value.service_inputformjson
        });
      }
    }

    setpickedService(newArrray);

  }

  function toAddition(origin, toOprate) {

    // console.log('will see where this goes ', value);

    let newArrray = [];
    for (let value of origin) {
      newArrray.push({
        description: value.description,
        img: value.img,
        index: value.index,
        service_id: value.service_id,
        service_name: value.service_name,
        service_inputformjson: value.service_inputformjson
      });
    }

    const newData = {
      description: toOprate.description,
      img: toOprate.img,
      index: toOprate.index,
      service_id: toOprate.service_id,
      service_name: toOprate.service_name,
      service_inputformjson: toOprate.service_inputformjson
    };

    newArrray.push(newData);
    setpickedService(newArrray);
  }

  function ifHave(toCompare) {
    if (pickedService.length !== 0) {
      for (let item of pickedService) {
        if (item.service_id === toCompare.service_id) {
          return true;
          break;
        }
      }
    } else {
      return false;
    }
  }

  function submition() {
    setClickedService(null);
    if (pickedService.length !== 0) {

      history.replace({
        pathname: `/subscription`,
        state: {
          selectedOne: pickedService
        }
      });

    } else {
      enqueueSnackbar(MUST_SELECT_PRODUCT, { variant: "error" });
    }
  }

  // console.log('clicked service', clickedService);
  // console.log('all services', allServices);
  // console.log('picked services', pickedService);


  return (
    <>
      <div className="athena-product-wrapper">

        <div className="product-tag-block">
          <div className="product-tag-image">
            <img src={athenaProductLogo} alt="athena-product-logo" />
          </div>
        </div>

        <div className="service-block">

          <div className="athena-title">Would you like to include any other services ?</div>

          <div className="athena-sub-title">Digital Risk Monitoring Services</div>

          {serviceLoading === true ?
            <div className="service-loading"> <CircularProgress /> </div>
            :
            <div className="prouct-listing-wrapper">
              {allServices.map((item, index) => {
                return (
                  <div className="product-final-block" key={index}>
                    <div className="product-block">
                      <div className="circle-block">
                        <div className="circle-to-select" onClick={selectThis.bind('', item)} >
                          <div className={`circle-child-bg ${ifHave(item) === true ? 'active' : 'inactive'} `}></div>
                        </div>
                      </div>
                      <div className="product-name">
                        <Tooltip size="large" title={<h1 style={{ fontSize: "12px" }}>{item.description}</h1>} arrow TransitionComponent={Zoom}>
                          <div className="text-ellipese">{item.service_name}</div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                );
              })}

            </div>
          }

        </div>

        <div className="next-hit-wrapper">
          {serviceLoading === false ?
            <div className="next-page" onClick={submition} >Launch</div>
          :null}
        </div>

      </div>

    </>
  );
}

export default Dashboard;
