import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, FormControl, FormControlLabel, FormGroup } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import CropSquareRoundedIcon from "@material-ui/icons/CropSquareRounded";
import StopRoundedIcon from '@material-ui/icons/StopRounded';

import ThunderBoltCommonCard from "../../Components/CommonCards/ThunderBoltCommonCard";
import AthenaCommonCard from "../../Components/CommonCards/AthenaCommonCard";

import { useHistory } from "react-router-dom";
import { useSession } from 'react-use-session';
import { useSnackbar } from "notistack";

import { UserService } from "../../utils/userServices";

const useStyles = makeStyles({
  root: {
    "&.MuiSvgIcon-root": {
      fontSize: "26px",
      transform: "scale(1.5)",
    },
    "&.MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#2d2d2d",
    },
  },
  rootChecked: {
    "&.MuiSvgIcon-root": {
      fontSize: "26px",
      transform: "scale(2)",
    },
  },
  rootFormControlLabelStyle: {
    fontSize: "13px",
    textAlign: "left",
    color: "#000000",
  },
  rootFormControl: {
    "&.MuiFormControlLabel-labelPlacementStart": {
      width: "100%",
      justifyContent: "space-between",
      wordBreak: "break-word",
      textAlign: "left",
    },
  },
  rootPaper: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      width: "80%",
      height: "80%",
    }
  }
});

function DashboardThunderbolt({
  setPageTitleOne,
  setPageTitleTwo,
  setPageTitle,
  setPageTitleThree,
  setCommonPageTitleCheck,
  selectedServices,
  setSelectedServices,
  setallSelected,
  allSelected
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useSession("cyber-security");
  const sessionToken = session.token;
  const history = useHistory();
  const classes = useStyles();
  const [pickedService, setpickedService] = useState({});
  const [allServices, setallServices] = useState();
  const [loading, setloading] = useState(true);
  const [disableButton, setdisableButton] = useState(false);
  const [thunderServices, setthunderServices] = useState();
  const [servicesSelectedArray, setServicesSelectedArray] = useState([]);
  const [toDisable, settoDisable] = useState(true);
  const [open, setOpen] = useState(false);
  const [cartItems, setcartItems] = useState({});

  const handleChangeDigitalRiskAssessment = (event, id) => {
    if (event.target.checked === false) {
      delete selectedServices[id];
      delete pickedService[id];
    } else {
      setSelectedServices({ ...selectedServices, [id]: event.target.checked });
      let indexOfService = allServices
        .map(function (e) {
          return e.service_name;
        })
        .indexOf(id);
      setpickedService({ ...pickedService, [id]: allServices[indexOfService] });
    }
    // Object.keys(thunderServices).forEach(function (key) { thunderServices[key] = false });
    setthunderServices({ ...thunderServices, [event.target.name]: event.target.checked });
  };

  // console.log(pickedService);

  useEffect(() => {
    setSelectedServices({});
    setPageTitleOne("Home");
    setPageTitleTwo("");
    setPageTitle("");
    setPageTitleThree("Product");
    setCommonPageTitleCheck(true);
    setpickedService(allSelected.thunderbolt);
  }, []);



  useEffect(async () => {

    if (Object.keys(pickedService).length > 0) {
      setallSelected({ ...allSelected, thunderbolt: pickedService });
    } else {
      if (toDisable == true) {
        await getCartItems();
        await fetchServices(allSelected.thunderbolt);
        settoDisable(false);
      }
    }
  }, [pickedService]);

  // console.log('all picked services', pickedService);
  // console.log('all athen services', allSelected.athen);
  // console.log('all thunderbolt services', allSelected.thunderbolt);

  const goToNextPage = () => {
    // if (!pickedService || Object.keys(pickedService).length < 1) {
    //   enqueueSnackbar("Please select at least one service to continue", { variant: "error" });
    //   return;
    // }

    Object.keys(allSelected.athena).map((item, key) => {
      // console.log('thunderbolt loop', allSelected.athena[item].service_class);
      if (allSelected.athena[item].service_class == "3") {
        delete allSelected.athena[item];
      }
      // allSelected.athena[item] = allSelected.thunderbolt[item];
    });

    if (Object.keys(allSelected.thunderbolt).length > 0) {
      Object.keys(allSelected.thunderbolt).map((item, key) => {
        // console.log('thunderbolt loop', allSelected.thunderbolt[item]);
        allSelected.athena[item] = allSelected.thunderbolt[item];
      });
    }
    // console.log('got this', allSelected.athena);

    if (Object.keys(allSelected.athena).length > 0) {


      history.push({
        pathname: `/selected-products`,
        state: {
          selectedOne: allSelected.athena,
        },
      });

    } else {
      enqueueSnackbar("Please select at least one service from the products", { variant: "error" });
    }

  };

  const fetchServices = async (selectedObjects) => {
    const fetchedServices = await UserService.getServices(sessionToken);
    setloading(false);
    // console.log(fetchedServices);
    if (fetchedServices && fetchedServices.data.data) {
      let arrayOfServices = Object.values(fetchedServices.data.data);
      setallServices(arrayOfServices);
      let oneGroup = {};
      let toCompare = [];
      if (Object.keys(selectedObjects).length > 0) {
        const toArray = Object.entries(selectedObjects);
        toArray.forEach((item, key) => {
          toCompare.push(item[0]);
        });
      }

      for (let i = 0; i <= arrayOfServices.length - 1; i++) {
        if (arrayOfServices[i].service_type === "thunderbolt") {

          if (Object.keys(selectedObjects).length > 0) {

            // console.log('checking state', toArray);
            for (let j = 0; j < toCompare.length; j++) {
              if (arrayOfServices[i].service_name == toCompare[j]) {
                // console.log(`mathch found ${arrayOfServices[i].service_name} and ${toCompare[j]}`);
                oneGroup[arrayOfServices[i].service_name] = true;
                j = toCompare.length;
              } else {
                oneGroup[arrayOfServices[i].service_name] = false;
              }
            }

          } else {
            // console.log('running through false section');
            oneGroup[arrayOfServices[i].service_name] = false;
          }


        }
      }
      setthunderServices(oneGroup);
      setdisableButton(false);
    } else {
      enqueueSnackbar(API_WRONG, { variant: "error" });
      setdisableButton(true);
    }
  };

  const getCartItems = async () => {
    const notiData = await UserService.getCartItem(session.user_id, session.token);
    if (notiData.data && notiData.data.data) {
      setcartItems(notiData.data.data);
    } else {
      enqueueSnackbar(API_WRONG, { variant: "error" });
    }
  };

  return (
    <>
      <div className="dashboardContainer px-2">
        <div className="dashboardPaperContainer mt-32px">
          <div className="product-first-card">
            <ThunderBoltCommonCard
              height="150px"
              title="Thunder Bolt"
              backgroundColorValue="#d9d9d9"
              launch={false}
            />
          </div>
        </div>

        <div className="dtbBottomContainer w-100 mt-40px">

          <div className="form-title athen-product-title">What Our Product Thunder Bolt Offers?</div>

          {loading === false ? (
            <div className="thunderbolt-service-wrapper d-flex" style={{ flexDirection: 'row-reverse' }} >
              {thunderServices &&
                Object.keys(thunderServices).map(function (key, index) {
                  let indexOfService = allServices.map(function (e) { return e.service_name; }).indexOf(key);
                  let ifSelected;
                  if (Object.keys(cartItems).length > 0) {
                    ifSelected = cartItems.map(function (e) { return e.service_id; }).indexOf(allServices[indexOfService].service_id);
                  } else {
                    ifSelected = -1;
                  }

                  return (
                    <div className="w-50 float-left" key={index}>
                      <div className="dashboardFormFieldSize m-0">
                        <FormControl
                          component="fieldset"
                          style={{ height: "fit-content" }}
                          className="thunderbolt-fieldset"
                        >
                          <FormGroup>
                            <FormControlLabel
                              className={classes.rootFormControl}
                              control={
                                <Checkbox
                                  checkedIcon={<div className="checked-circle" ><div></div></div>}
                                  icon={<div className="unchecked-circle" ><div></div></div>}
                                  name={key}
                                  onChange={(e) =>
                                    handleChangeDigitalRiskAssessment(e, e.target.name)
                                  }
                                  checked={thunderServices[key]}
                                  className={`${classes.root} ml-2 remove-shading`}
                                  disabled={ifSelected !== -1 ? true : false}
                                />
                              }
                              label={
                                <Tooltip title={allServices[indexOfService].description} arrow TransitionComponent={Zoom}>
                                  <span className={classes.rootFormControlLabelStyle}>{key} </span>
                                </Tooltip>
                              }
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="w-100 hcenter"><CircularProgress /> </div>
          )}

          {loading !== true && disableButton !== true ? (
            <div className="dashboardButtonContainer">
              <button onClick={() => goToNextPage()} className="btn-next-page">Next Page</button>
            </div>
          ) : null}
        </div>
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.root}
      >
        <DialogTitle id="alert-dialog-title">{"Alert!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select atleast one service to continue.
          </DialogContentText>
          {/*  <DialogContentText id="alert-dialog-description">
            PROJECT ID - ABC0000000002
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DashboardThunderbolt;
