import React, { useEffect, useState, useCallback } from "react";

import { useSession } from 'react-use-session';
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from 'formik';
import PureModal from 'react-pure-modal';
import { useSnackbar } from "notistack";
import Cropper from 'react-easy-crop';
import * as Yup from 'yup';

import CircularProgress from "@material-ui/core/CircularProgress";
import Slider from '@material-ui/core/Slider';

import { apiErrorHandler, DataURIToBlobAlt, imageData, filterImageUrl } from '../../utils/helper';
import { IMAGE_SIZE_2MB, ALLOWED_IMAGE_FILES } from "../../utils/messages";
import { AuthServices } from '../../utils/auth';

import 'react-pure-modal/dist/react-pure-modal.min.css';
import './profile.scss';

function ProductPage(props) {

  const {
    setPageTitleOne,
    setPageTitleTwo,
    setPageTitleThree,
    setCommonPageTitleCheck,
    setPageTitle,
    setHitProfile,
    sethitNotification
  } = props;

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const { session } = useSession("cyber-security");
  const history = useHistory();

  const [profileDetails, setprofileDetails] = useState({
    user_name: '',
    organization: '',
    designation: '',
    billing_address: '',
    phone_number: '',
    otp: '',
    isNumberVerified: false,
    email: '',
    profle_image_url: imageData
  });

  const [loading, setLoading] = useState(true);
  const [updateProcess, setUpdateProcess] = useState(false);
  const [profileImage, setProfileImage] = useState(null);

  const [upImg, setUpImg] = useState();
  const [showModal, setShowModal] = useState(false);
  const [rotation, setRotation] = useState(0);



  useEffect(() => {
    setPageTitleOne("Home");
    setPageTitleTwo("Profile");
    setPageTitleThree("");
    setPageTitle("");
    setCommonPageTitleCheck(true);
    getUserData();
  }, []);

  async function getUserData() {
    const apiResponse = await AuthServices.getUserDetails(session.user_id, session.token);
    setLoading(false);
    setUpdateProcess(false);
    if (apiResponse.status === 200) {
      // console.log('what i got', apiResponse.data.data);
      // enqueueSnackbar(apiResponse.data.message, { variant: "success" });
      const holdVal = apiResponse.data.data;
      setprofileDetails({
        user_name: holdVal.display_name,
        organization: holdVal.organization_name,
        designation: holdVal.designation,
        billing_address: holdVal.billing_address,
        phone_number: holdVal.phone_number,
        otp: holdVal.otp,
        isNumberVerified: false,
        email: holdVal.email,
        profle_image_url: holdVal.s3_profile_photo_url
      });
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  const profileSchema = Yup.object().shape({
    user_name: Yup.string()
      .trim()
      .min(4, 'Name must be more than 4 characters.')
      .max(50, 'Only 50 characters are allowed.')
      .required('This is a mandatory field.'),
    organization: Yup.string()
      .trim()
      .min(4, 'Organization must be more than 4 characters.')
      .max(50, 'Only 50 characters are allowed.'),
    designation: Yup.string()
      .trim()
      .min(4, 'Designation must be more than 4 characters.')
      .max(50, 'Only 50 characters are allowed.'),
    billing_address: Yup.string()
      .trim()
      .min(10, 'Billing address must be more than 10 characters.')
      .max(100, 'Only 100 characters are allowed.'),
    phone_number: Yup.number().typeError("Only numbers are allowed.")
      .integer("Only integer are allowed.")
      .min(1000000000, 'Phone number must contain 10 digits.')
      .max(9999999999, 'Phone number must contain 10 digits')
  });

  function setDefaultImage() {

    setprofileDetails({ ...profileDetails, profle_image_url: imageData });
    const dataUri = DataURIToBlobAlt(imageData);
    setProfileImage(dataUri);
  }

  async function pushData(values) {
    setUpdateProcess(true);
    const formData = new FormData();
    formData.append('user_id', session.user_id);
    formData.append('display_name', values.user_name.trim());
    formData.append('organization_name', values.organization.trim());
    formData.append('designation', values.designation.trim());
    formData.append('billing_address', values.billing_address.trim());
    formData.append('phone_number', values.phone_number);
    // console.log('profileDetails', profileImage);

    if (profileImage !== null) {
      formData.append('file', profileImage);
    }
    const apiResponse = await AuthServices.putUserDetails(formData, session.token);
    setHitProfile(true);
    sethitNotification(true);
    if (apiResponse.status === 200) {
      enqueueSnackbar(apiResponse.data.message, { variant: "success" });
      getUserData();
    } else {
      setUpdateProcess(false);
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }

  }

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size < 2e+6) {
        const reader = new FileReader();
        reader.addEventListener('load', () => setUpImg(reader.result));
        reader.readAsDataURL(e.target.files[0]);
        setShowModal(true);
      } else {
        enqueueSnackbar(IMAGE_SIZE_2MB, { variant: "error" });
      }
    }
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });


  function closeModal() {
    setUpImg();
    setShowModal(false);
  }

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
  }

  function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation)

    return {
      width:
        Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
      height:
        Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    }
  }

  async function getCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
  ) {

    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return null;
    }

    const rotRad = getRadianAngle(rotation);

    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation
    );

    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    ctx.drawImage(image, 0, 0);

    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height
    );

    // console.log('ctxdata', data);

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0);

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        // console.log('ctxdata', file);
        setProfileImage(file);

        resolve(URL.createObjectURL(file))
      }, 'image/jpeg')
    });

  }

  const showCroppedImage = useCallback(async () => {
    // console.log('getting run');
    try {
      const croppedImage = await getCroppedImg(
        upImg,
        croppedAreaPixels,
        rotation
      )
      // console.log('donee', { croppedImage });
      setprofileDetails({ ...profileDetails, profle_image_url: croppedImage });
      closeModal();
    } catch (error) {
      console.error('error', error);
    }
  }, [croppedAreaPixels, rotation]);


  // console.log('current session', session);
  // console.log('upImg', upImg);
  // console.log('completedCrop', completedCrop);
  // console.log('previewCanvasRef', previewCanvasRef);

  return (
    <div className="base-card-container">

      <div className="profile-container">
        <div className="profile-header"> Personal Profile</div>

        {profileDetails.user_name !== '' ?

          <div className="profile-body">
            <div className="image-section">

              <div className="image-section-child">

                <div className="profile-image flex justify-center align-center">
                  {profileDetails.profle_image_url !== null ?
                    <img src={profileDetails.profle_image_url} alt="profile image" className="default-image-profile" />
                    // <img src={filterImageUrl(profileDetails.profle_image_url)} alt="profile image" />
                    :
                    <img src={imageData} alt="profile image" style={{ width: "auto" }} />
                  }

                  <svg onClick={setDefaultImage} xmlns="http://www.w3.org/2000/svg" fill="#d32a29" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.597 17.954l-4.591-4.55-4.555 4.596-1.405-1.405 4.547-4.592-4.593-4.552 1.405-1.405 4.588 4.543 4.545-4.589 1.416 1.403-4.546 4.587 4.592 4.548-1.403 1.416z" /></svg>
                </div>

                <div className="profile-change-button">
                  <label htmlFor="profileImage" >Change Photo</label>
                  <input
                    type="file"
                    accept={ALLOWED_IMAGE_FILES}
                    name="profileImage"
                    id="profileImage"
                    className="d-none"
                    onChange={onSelectFile}
                  />
                </div>

              </div>

            </div>

            <div className="form-section">

              <div className="form-area">
                {/* {updateProcess === false && loading === false? */}
                <Formik
                  initialValues={{
                    user_name: profileDetails.user_name,
                    organization: profileDetails.organization,
                    designation: profileDetails.designation,
                    billing_address: profileDetails.billing_address,
                    phone_number: profileDetails.phone_number
                  }}
                  validationSchema={profileSchema}
                  onSubmit={(values) => {
                    pushData(values);
                  }}
                >
                  {({ errors, touched }) => (

                    <Form>

                      <div className="profile-input-box">
                        <div className="profile-label">Display Name</div>
                        <Field
                          type="text"
                          name="user_name"
                          id="user_name"
                          className={`profile-custom-input ${errors.user_name && touched.user_name ? 'input-error-outline' : ''}`}
                          placeholder="Display Name"
                        />
                        {errors.user_name && touched.user_name ? (
                          <div className="profile-form-error text-danger">{errors.user_name}</div>
                        ) : null}
                      </div>


                      <div className="profile-input-box">
                        <div className="profile-label">Name Of Organization</div>
                        <Field
                          className={`profile-custom-input ${errors.organization && touched.organization ? 'input-error-outline' : ''}`}
                          type="text"
                          name="organization"
                          id="organization"
                          placeholder="Name Of Organization"
                        />

                        {errors.organization && touched.organization ? (
                          <div className="profile-form-error text-danger">{errors.organization}</div>
                        ) : null}

                      </div>

                      <div className="profile-input-box">
                        <div className="profile-label">Designation</div>
                        <Field
                          className={`profile-custom-input ${errors.designation && touched.designation ? 'input-error-outline' : ''}`}
                          type="text"
                          name="designation"
                          id="designation"
                          placeholder="Designation"
                        />

                        {errors.designation && touched.designation ? (
                          <div className="profile-form-error text-danger">{errors.designation}</div>
                        ) : null}

                      </div>

                      <div className="profile-input-box">
                        <div className="profile-label">Billing Address</div>
                        <Field
                          className={`profile-custom-input profile-custom-textarea ${errors.billing_address && touched.billing_address ? 'input-error-outline' : ''}`}
                          name="billing_address"
                          id="billing_address"
                          placeholder="Billing Address"
                          type="textarea"
                        />

                        {errors.billing_address && touched.billing_address ? (
                          <div className="profile-form-error text-danger">{errors.billing_address}</div>
                        ) : null}

                      </div>

                      <div className="profile-input-box">
                        <div className="profile-label">Phone Number</div>
                        <div className="profile-number-wrapper">

                          <div className="profile-number-section w-100">
                            <Field
                              className={`profile-custom-input ${errors.phone_number && touched.phone_number ? 'input-error-outline' : ''}`}
                              name="phone_number"
                              id="phone_number"
                              placeholder="Phone Number"
                              type="number"
                            />
                          </div>

                          {/* {profileDetails.isNumberVerified === false ?
                            <div className="profile-otp-button">
                              <button type="button">Get OTP</button>
                            </div>
                            : null} */}

                        </div>

                        {errors.phone_number && touched.phone_number ? (
                          <div className="profile-form-error text-danger">{errors.phone_number}</div>
                        ) : null}

                      </div>

                      <div className="profile-input-box">
                        <div className="profile-label">Email</div>

                        <input
                          className="profile-custom-input"
                          name="email"
                          id="email"
                          placeholder="Email"
                          readOnly={true}
                          defaultValue={profileDetails.email}
                        />

                      </div>

                      <div className="profile-form-submit">
                        <button type="submit">Save </button>
                      </div>

                    </Form>

                  )}
                </Formik>
              </div>
            </div>

            <div className={`profile-absolute-loader ${updateProcess === true ? 'd-flex' : 'd-none'}`}>
              <CircularProgress />
            </div>
          </div>
          :
          <div className="loader-box">
            {loading === true ?
              <CircularProgress />
              : null}
          </div>
        }

      </div>

      <PureModal
        header="Crop your image"
        footer={
          <div> <button onClick={closeModal}>Cancel</button></div>
        }
        isOpen={showModal}
        // isOpen={true}
        // closeButton="x"
        // closeButtonPosition="bottom"
        onClose={closeModal}
        width="60%"
      >

        <div className="crop-area">
          <div className="react-crop-wrapper">
            <Cropper
              image={upImg}
              crop={crop}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>

          <div className="cropped-image-wrapper">
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(zoom)}
              classes={{ root: 'slider' }}
            />
          </div>

          <div className="crop-controls">
            <div
              className="crop-close"
              onClick={closeModal}
            >Close</div>
            <div className="crop-set" onClick={showCroppedImage} >Select</div>
          </div>

        </div>

      </PureModal>

    </div>
  );
};

export default ProductPage;
