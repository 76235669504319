import React, { useEffect, useState } from "react";

import { useSnackbar } from "notistack";

import { extractFileName } from '../../utils/helper';
import { FILE_SIZE_2MB, FILE_FORMAT, ALLOWED_DOCS_FILES } from "../../utils/messages";

import uploadIcon from '../../assets/image/upload-document.svg';

function UploadAuditReport({
  label,
  placeholder,
  width,
  formData,
  setFormData,
  disabled,
  dataEntries,
  setDataEntries,
  setisFileSelected,
  documentId
}) {

  const { enqueueSnackbar } = useSnackbar();
  const [fileDetails, setfileDetails] = useState("Upload Audit Report");
  const [file, setFile] = useState(null);

  let dataRequiredFromLocalStorage = JSON.parse(localStorage.getItem("dataEntriesInLocal"));
  // console.log(' will sees', dataRequiredFromLocalStorage);

  async function addDocument(file, id) {
    const extension = file.name.split('.').pop();
    if (
      extension == 'txt' ||
      extension == 'xlsx' ||
      extension == 'xls' ||
      extension == 'doc' ||
      extension == 'docx' ||
      extension == 'ppt' ||
      extension == 'pptx' ||
      extension == 'txt' ||
      extension == 'pdf' ||
      extension == 'json'
    ) {
      if (file.size < 2e+6) {
        setfileDetails(file.name);
        setFile(file);
      } else {
        enqueueSnackbar(FILE_SIZE_2MB, { variant: "error" });
      }
    } else {
      enqueueSnackbar(FILE_FORMAT, { variant: "error" });
    }
  };

  useEffect(() => {
    if (file !== null) {

      let blob, fileReader = new FileReader();

      blob = new Blob([file]);

      fileReader.readAsDataURL(blob);

      fileReader.onload = function (evt) {
        const result = evt.target.result;

        const obj = { ...formData, [placeholder]: [result, file.name, file.type] };
        setFormData(obj);

        setDataEntries(obj);
        const objForImage = { ...dataRequiredFromLocalStorage, [placeholder]: [result, file.name, file.type] };
        localStorage.setItem("dataEntriesInLocal", JSON.stringify(objForImage));
        // console.log('will see', result);
      };

    }
  }, [file]);

  return (
    <div className="audit-report-wrapper" style={{ width: width ? width : "100%" }}>

      <div className="audit-report-name">
        <span className="upload-audit-placeholder">

          {fileDetails === "Upload Audit Report" ?
            <>
              {dataRequiredFromLocalStorage[placeholder] ?
                <>{extractFileName(dataRequiredFromLocalStorage[placeholder])} </>
                : fileDetails}
            </>
            : fileDetails}

        </span>
        <label className="vhcenter" htmlFor={label} >
          <img src={uploadIcon} className="upload-audit-icon" />
        </label>
      </div>

      <input
        type="file"
        name={label}
        id={label}
        placeholder={placeholder}
        accept={ALLOWED_DOCS_FILES}
        className="d-none"
        onChange={(e) => addDocument(e.target.files[0], e.target)}
      />
    </div>
  );
}

export default UploadAuditReport;
