import React, { useState, useEffect } from "react";

import { Switch, Route } from "react-router-dom";
import { useSession } from 'react-use-session';
import { useHistory } from 'react-router';
// import CommonPageTitle from "../../Components/CommonCards/CommonPageTitle";
// import DashboardProjectDetail from "../../../else/DashboardProjectDetail/DashboardProjectDetail";

import AppBarComponent from "../../Components/AppBar/AppBarComponent";
import ProductPage from "../ProductPage/ProductPage";
import Athena from "../Athena/Athena";
import Sidebar from "../../Components/Sidebar/Sidebar";
import DataNeededFromCustomers from "../DataNeeded/DataNeededFromCustomers";
import DashboardThunderbolt from "../DashboardThunderbolt/DashboardThunderbolt";
import DashboardStatusPage from "../DashboardStatusPage/DashboardStatusPage";
import Profile from "../Profile/Profile";
import Service from "../Service/Service";
import Cart from '../Cart/Cart';
import Billing from '../Billing/Billing';
import Notification from '../Notification/Notification';
import OrderVerify from '../OrderVerify/OrderVerify';
import LandingPage from "../LandingPage/thunderbolt";
import Subscription from "../Subscription/Subscription";
import Chat from "../Chat/Chat";
import Unsubscribe from '../Unsubscribe/index';
// import EmailVerify from '../EmailVerify/EmailVerify';
// import OrderDelete from '../OrderDelete/OrderDelete';

import './home.scss';

function Home() {

  const { session } = useSession("cyber-security");
  const history = useHistory();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [pageTitle, setPageTitle] = useState("");
  const [pageTitleOne, setPageTitleOne] = useState("");
  const [pageTitleTwo, setPageTitleTwo] = useState("");
  const [pageTitleThree, setPageTitleThree] = useState("");

  const [commonPageTitleCheck, setCommonPageTitleCheck] = useState(false);

  const [selectedServices, setSelectedServices] = useState({});

  const [hitNotification, sethitNotification] = useState(false);
  const [hitCart, sethitCart] = useState(false);
  const [hitProfile, setHitProfile] = useState(false);

  const [allSelected, setallSelected] = useState({
    athena: {},
    thunderbolt: {}
  });

  const [clickedService, setClickedService] = useState(null);

  const [chatSidebar, setChatSidebar] = useState(false);

  const [middleData, setMiddleData] = useState(null);

  useEffect(() => {
    if (session !== null) {

      if (session.is_admin === true) {
        history.push('/home');
        location.reload();
      }

      if (session.is_analyst === true) {
        history.push('/analyst-home');
        location.reload();
      }
    }
  }, [session]);

  useEffect(() => {
    setClickedService(null);
  }, [0]);

  return (
    <div className="home-container">

      <div className="sidebar-wrapper">
        <div className={`sidebar-fixed ${isDrawerOpen === true ? 'close' : 'open'} `}>
          <Sidebar
            toggleDrawer={(val) => setIsDrawerOpen(val)}
            setChatSidebar={setChatSidebar}
            chatSidebar={chatSidebar}
          />
        </div>
      </div>

      <div className={`page-wrapper ${isDrawerOpen === true ? 'close' : 'open'} `}>

        <div className="content-area">

          <div className="appbar-wrapper-section">
            <AppBarComponent
              pageTitle={pageTitle}
              hitNotification={hitNotification}
              sethitNotification={sethitNotification}
              sethitCart={sethitCart}
              hitCart={hitCart}
              hitProfile={hitProfile}
              setHitProfile={setHitProfile}
              pageTitleOne={pageTitleOne}
              pageTitleTwo={pageTitleTwo}
              pageTitleThree={pageTitleThree}
              setallSelected={setallSelected}
              commonPageTitleCheck={commonPageTitleCheck}
            />
          </div>

          <Switch>

            <Route
              exact
              path="/"
              render={(props) => (
                <LandingPage
                  {...props}
                  setPageTitleOne={setPageTitleOne}
                  setPageTitleTwo={setPageTitleTwo}
                  setPageTitleThree={setPageTitleThree}
                  setPageTitle={setPageTitle}
                  setCommonPageTitleCheck={setCommonPageTitleCheck}
                  setallSelected={setallSelected}
                  allSelected={allSelected}
                  setClickedService={setClickedService}
                  clickedService={clickedService}
                />
              )}
            />

            <Route
              exact
              path="/profile"
              render={(props) => (
                <Profile
                  {...props}
                  setPageTitleOne={setPageTitleOne}
                  setPageTitleTwo={setPageTitleTwo}
                  setPageTitleThree={setPageTitleThree}
                  setPageTitle={setPageTitle}
                  setCommonPageTitleCheck={setCommonPageTitleCheck}
                  setHitProfile={setHitProfile}
                  sethitNotification={sethitNotification}
                />
              )}
            />

            <Route
              exact
              path="/product"
              render={(props) => (
                <ProductPage
                  {...props}
                  setPageTitleOne={setPageTitleOne}
                  setPageTitleTwo={setPageTitleTwo}
                  setPageTitleThree={setPageTitleThree}
                  setPageTitle={setPageTitle}
                  setCommonPageTitleCheck={setCommonPageTitleCheck}
                />
              )}
            />

            <Route
              exact
              path="/athena"
              render={(props) => (
                <Athena
                  {...props}
                  setPageTitleOne={setPageTitleOne}
                  setPageTitleTwo={setPageTitleTwo}
                  setPageTitleThree={setPageTitleThree}
                  setPageTitle={setPageTitle}
                  setCommonPageTitleCheck={setCommonPageTitleCheck}
                  selectedServices={selectedServices}
                  setSelectedServices={setSelectedServices}
                  setallSelected={setallSelected}
                  allSelected={allSelected}
                  clickedService={clickedService}
                  setClickedService={setClickedService}
                />
              )}
            />

            <Route
              exact
              path="/selected-products"
              render={(props) => (
                <DataNeededFromCustomers
                  {...props}
                  setPageTitleOne={setPageTitleOne}
                  setPageTitleTwo={setPageTitleTwo}
                  setPageTitleThree={setPageTitleThree}
                  setPageTitle={setPageTitle}
                  setCommonPageTitleCheck={setCommonPageTitleCheck}
                  selectedServices={selectedServices}
                  setSelectedServices={setSelectedServices}
                  hitNotification={hitNotification}
                  sethitNotification={sethitNotification}
                  hitCart={hitCart}
                  sethitCart={sethitCart}
                  setMiddleData={setMiddleData}
                  middleData={middleData}
                />
              )}
            />

            <Route
              exact
              path="/thunderbolt"
              render={(props) => (
                <DashboardThunderbolt
                  {...props}
                  setPageTitleOne={setPageTitleOne}
                  setPageTitleTwo={setPageTitleTwo}
                  setPageTitleThree={setPageTitleThree}
                  setPageTitle={setPageTitle}
                  setCommonPageTitleCheck={setCommonPageTitleCheck}
                  selectedServices={selectedServices}
                  setSelectedServices={setSelectedServices}
                  setallSelected={setallSelected}
                  allSelected={allSelected}
                />
              )}
            />

            <Route
              exact
              path="/cart"
              render={(props) => (
                <Billing
                  {...props}
                  setPageTitleOne={setPageTitleOne}
                  setPageTitleTwo={setPageTitleTwo}
                  setPageTitleThree={setPageTitleThree}
                  setPageTitle={setPageTitle}
                  setCommonPageTitleCheck={setCommonPageTitleCheck}
                  selectedServices={selectedServices}
                  hitCart={hitCart}
                  sethitCart={sethitCart}
                  sethitNotification={sethitNotification}
                />
              )}
            />

            <Route
              exact
              path="/order-verify"
              render={(props) => (
                <OrderVerify />
              )}
            />

            {/* <Route
              exact
              path="/email-verify"
              render={(props) => (
                <EmailVerify />
              )}
            /> */}

            {/* <Route
              exact
              path="/order-delete"
              render={(props) => (
                <OrderDelete />
              )}
            /> */}

            <Route
              exact
              path="/email-unsubscribed"
              render={(props) => (
                <Unsubscribe setHitProfile={setHitProfile} />
              )}
            />

            <Route
              exact
              path="/dashboard"
              render={(props) => (
                <DashboardStatusPage
                  {...props}
                  setPageTitleOne={setPageTitleOne}
                  setPageTitleTwo={setPageTitleTwo}
                  setPageTitleThree={setPageTitleThree}
                  setPageTitle={setPageTitle}
                  setCommonPageTitleCheck={setCommonPageTitleCheck}
                  setHitProfile={setHitProfile}
                />
              )}
            />

            <Route
              exact
              path="/service"
              render={(props) => (
                <Service
                  {...props}
                  setPageTitleOne={setPageTitleOne}
                  setPageTitleTwo={setPageTitleTwo}
                  setPageTitleThree={setPageTitleThree}
                  setPageTitle={setPageTitle}
                  setCommonPageTitleCheck={setCommonPageTitleCheck}
                />
              )}
            />

            <Route
              exact
              path="/billing"
              render={(props) => (
                <Cart
                  {...props}
                  setPageTitleOne={setPageTitleOne}
                  setPageTitleTwo={setPageTitleTwo}
                  setPageTitleThree={setPageTitleThree}
                  setPageTitle={setPageTitle}
                  setCommonPageTitleCheck={setCommonPageTitleCheck}
                  selectedServices={selectedServices}
                  setSelectedServices={setSelectedServices}
                />
              )}
            />

            {/* <Route
              exact
              path="/project-detail"
              render={(props) => (
                <DashboardProjectDetail
                  {...props}
                  setPageTitleOne={setPageTitleOne}
                  setPageTitleTwo={setPageTitleTwo}
                  setPageTitleThree={setPageTitleThree}
                  setPageTitle={setPageTitle}
                  setCommonPageTitleCheck={setCommonPageTitleCheck}
                />
              )}
            /> */}

            <Route
              exact
              path="/notification"
              render={(props) => (
                <Notification
                  {...props}
                  setPageTitleOne={setPageTitleOne}
                  setPageTitleTwo={setPageTitleTwo}
                  setPageTitleThree={setPageTitleThree}
                  setPageTitle={setPageTitle}
                  setCommonPageTitleCheck={setCommonPageTitleCheck}
                  sethitNotification={sethitNotification}
                  sethitCart={sethitCart}
                />
              )}
            />

            <Route
              exact
              path="/chat"
              render={(props) => (
                <Chat
                  {...props}
                  setPageTitleOne={setPageTitleOne}
                  setPageTitleTwo={setPageTitleTwo}
                  setPageTitleThree={setPageTitleThree}
                  setChatSidebar={setChatSidebar}
                />
              )}
            />

            <Route
              exact
              path="/subscription"
              render={(props) => (
                <Subscription
                  {...props}
                  setPageTitleOne={setPageTitleOne}
                  setPageTitleTwo={setPageTitleTwo}
                  setPageTitleThree={setPageTitleThree}
                  setChatSidebar={setChatSidebar}
                  setMiddleData={setMiddleData}
                />
              )}
            />

          </Switch>

        </div>

      </div>
    </div>
  );
};

export default Home;
