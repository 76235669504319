import React, { useState, useEffect } from 'react';

import { Switch, Route } from "react-router-dom";
import { useSession } from 'react-use-session';
import { useHistory } from 'react-router';
import Loadable from "react-loadable";

import AppBarComponent from '../../Components/AnalystComponents/AppBar/AppBarComponent';
import Sidebar from '../../Components/AnalystComponents/Sidebar/Sidebar';


import '../Home/home.scss';
import '../../sass/pagination.scss';

const AnalystRoutes = () => {

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { session } = useSession("cyber-security");
  const history = useHistory();

  useEffect(() => {
    if (session !== null) {

      if (session.is_admin === false && session.is_analyst === false) {
        location.reload();
      }

      if (session.is_admin === true) {
        history.push('/home');
        location.reload();
      }
    }
  }, [session]);



  function Loading({ error }) {

    function reload() {
      window.location.reload();
    }

    if (error) {
      return (
        <div className="reload-page">
          <div className="align-middle">
            <p>You have new changes to be loaded.</p>
            <button className="button blue" onClick={reload}>
              RELOAD
            </button>
          </div>
        </div>
      );
    } else {
      return <div className="component-loading"></div>;
    }

  }

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => <Component {...props} />}
    />
  );

  const DynamicLoadablePage = (page, path) => {
    return Loadable({
      loader: () => import(`../Analyst/${path}/${page}`),
      loading: Loading
    });
  };


  return (

    <div className="home-container">
      <div className="sidebar-wrapper">
        <div className={`sidebar-fixed ${isDrawerOpen === true ? 'close' : 'open'} `}>
          <Sidebar toggleDrawer={(val) => setIsDrawerOpen(val)} />
        </div>
      </div>

      <div className={`page-wrapper ${isDrawerOpen === true ? 'close' : 'open'} `}>

        <div className="content-area">

          <div className="appbar-wrapper-section">
            <AppBarComponent />
          </div>

          <Switch>

            <PrivateRoute
              exact
              path="/analyst-home/"
              component={DynamicLoadablePage("Dashboard", "Dashboard")}
            />

            <PrivateRoute
              exact
              path="/analyst-inbox/"
              component={DynamicLoadablePage("AnalystInbox", "AnalystInbox")}
            />

            <PrivateRoute
              exact
              path="/analyst-files/"
              component={DynamicLoadablePage("AnalystFiles", "AnalystFiles")}
            />

            <PrivateRoute
              exact
              path="/analyst-profile/"
              component={DynamicLoadablePage("AnalystProfile", "AnalystProfile")}
            />

            <PrivateRoute
              exact
              path="/chat-analyst"
              component={DynamicLoadablePage("Chat", "Chat")}
            />

            <PrivateRoute
              exact
              path="/analyst-orders"
              component={DynamicLoadablePage("Orders", "Orders")}
            />

            <PrivateRoute
              exact
              path="/analyst-ticket-detail"
              component={DynamicLoadablePage("TicketDetails", "TicketDetails")}
            />

            <PrivateRoute
              exact
              path="/analyst-email"
              component={DynamicLoadablePage("Email", "Email")}
            />


          </Switch>

        </div>
      </div>
    </div >
  );
}

export default AnalystRoutes;