import React, { useState } from 'react';

import { useSnackbar } from "notistack";
import { useHistory } from 'react-router';

import { Button, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

import { apiErrorHandler } from "../../utils/helper";
import { AuthServices } from '../../utils/auth';
import PasswordMeter from '../LoginRegister/PasswordMeter';

const SmallInput = withStyles(() => ({
  root: {
    backgroundColor: "white",

    "& * ": {
      fontSize: "14px",
      color: "#000000",
      textAlign: "left",
    },
    "& label ": {
      color: "#000000",
    },
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  }
}))(TextField);

const CustomButton = withStyles(() => ({
  root: {
    color: "#ffffff",
    height: "40px",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "1.53",
    border: "2px solid #174ea2",
    backgroundColor: "#174ea2",
    transition: "0.2s all",
    padding: "0px 20px",
    cursor: "pointer",
    "&:hover": {
      fontSize: "15px",
      backgroundColor: " #174ea2",
      color: "#ffffff",
    },
  },
}))(Button);

const CreateNewPassword = () => {

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [strongPassword, setstrongPassword] = useState(false);
  const [loginSubmition, setloginSubmition] = useState(false);
  const [newDet, setnewDet] = useState(false);
  const [otpSent, setotpSent] = useState(false);
  const [userEmail, setuserEmail] = useState('');
  const [loader, setLoader] = useState(false);

  const [newPasswordDet, setnewPasswordDet] = useState({
    otp: '',
    password: '',
    passwordVisibility: false,
    confirmPassword: '',
    confirmPasswordVisibility: false
  });

  var checkValidEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var singleSpace = /\s\s+/g;


  const sendEmail = async () => {
    setloginSubmition(true);
    setLoader(true);
    if (userEmail.length > 1 && checkValidEmailRegex.test(userEmail)) {
      const sendOtpResponse = await AuthServices.sendPasswordOtp({ email: userEmail });
      setLoader(false);
      if (sendOtpResponse.status === 200) {
        setotpSent(true);
        enqueueSnackbar(sendOtpResponse.data.message, { variant: "success" });
      } else {
        apiErrorHandler(sendOtpResponse, enqueueSnackbar);
      }
    } else {
      setLoader(false);
    }
  }



  const resetPassword = async () => {

    setnewDet(true);
    if (
      newPasswordDet.otp === '' ||
      singleSpace.test(newPasswordDet.otp)
    ) {
      return true;
    }
    // console.log('getting break');
    if (
      newPasswordDet.password === '' ||
      newPasswordDet.password.length < 9
    ) {
      return true;
    }

    if (
      newPasswordDet.confirmPassword === '' ||
      newPasswordDet.confirmPassword !== newPasswordDet.password
    ) {
      return true;
    }

    if (strongPassword === false) {
      return true;
    }

    setLoader(true);
    const postJson = {
      token: newPasswordDet.otp,
      password: newPasswordDet.password,
      password2: newPasswordDet.confirmPassword
    }
    const resetPasswordResponse = await AuthServices.resetPassword(postJson);
    setLoader(false);
    if (resetPasswordResponse.status === 201) {
      enqueueSnackbar(resetPasswordResponse.data.message, { variant: "success" });
      history.push('/');
    } else {
      apiErrorHandler(resetPasswordResponse, enqueueSnackbar);
    }

  }



  function verifyOtpEnter(event) {
    if (event.keyCode == 13) {
      resetPassword();
    }
  }

  function enterHitOtp(event) {
    if (event.keyCode == 13) {
      sendEmail();
    }
  }

  // console.log('wil bee seen', userEmail, userEmail.length);
  // console.log('wil bee seen', newPasswordDet);

  return (
    <>
      {otpSent === false ?
        <div className="newContainer pb-5 h-screen">
          <div className="textContainerSignIn fs30px my-1 text-white">Forgot Password</div>
          <div className="formContainer">

            <div className="formInputContainer">

              <div className="mt-1 w-100">
                <SmallInput
                  variant="outlined"
                  label=""
                  placeholder="Email"
                  type="email"
                  name="loginName"
                  onKeyDown={enterHitOtp}
                  fullWidth
                  value={userEmail}
                  error={
                    (
                      userEmail === '' ||
                      !checkValidEmailRegex.test(userEmail)
                    ) ? loginSubmition === true ? true : false : false
                  }
                  helperText={
                    <>
                      {!checkValidEmailRegex.test(userEmail) && loginSubmition && (
                        <span className="d-block text-danger fs12px">You need to pass correct email.</span>
                      )}
                    </>
                  }
                  onChange={(val) => setuserEmail(val.target.value)}
                />
              </div>

              <div className="loginButtonContainer ">
                <CustomButton fullWidth onClick={sendEmail} disabled={loader} >
                  {loader ? <CircularProgress className="text-white" /> : "Send otp"}
                </CustomButton>
              </div>

            </div>

          </div>

        </div>
        :
        <div className="newContainer pb-5 h-screen">
          <div className="textContainerSignIn fs30px my-1 text-white">Create new password</div>
          <div className="formContainer">
            <div className="formInputContainer">

              <div className="mt-1 w-100">
                <SmallInput
                  variant="outlined"
                  onKeyDown={verifyOtpEnter}
                  label=""
                  placeholder="otp"
                  fullWidth
                  name="otp"
                  autoComplete="otp"
                  type="number"
                  value={newPasswordDet.otp}
                  onChange={(val) => setnewPasswordDet({ ...newPasswordDet, otp: val.target.value })}
                  error={
                    (
                      newPasswordDet.otp === '' ||
                      singleSpace.test(newPasswordDet.otp)
                    ) ? newDet === true ? true : false : false
                  }
                  helperText={
                    <>
                      {newPasswordDet.otp === '' && newDet && (
                        <span className="d-block text-danger fs12px"> You must have to fill otp.</span>
                      )}
                      {singleSpace.test(newPasswordDet.otp) && newDet && (
                        <span className="d-block text-danger fs12px"> Only single space is allowed between charaters.</span>
                      )}
                    </>
                  }
                />
              </div>

              <div className="mt-1 w-100">
                <SmallInput
                  variant="outlined"
                  name="Password"
                  label=""
                  placeholder="Password"
                  fullWidth
                  onKeyDown={verifyOtpEnter}
                  autoComplete="new-password"
                  inputProps={{ maxLength: 30, minLength: 8 }}
                  type={newPasswordDet.passwordVisibility ? "text" : "password"}
                  value={newPasswordDet.password}
                  onChange={(val) => setnewPasswordDet({ ...newPasswordDet, password: val.target.value })}
                  error={
                    (
                      newPasswordDet.password === '' ||
                      newPasswordDet.password.length < 4
                    ) ? newDet === true ? true : false : false
                  }
                  helperText={
                    <>
                      {newPasswordDet.password === '' && newDet && (
                        <span className="d-block text-danger fs12px"> You must have to fill the Password.</span>
                      )}
                      {newPasswordDet.password.length < 9 && newDet && (
                        <span className="d-block text-danger fs12px"> Password must be greater then 8 character.</span>
                      )}
                      {strongPassword !== true && newDet && (
                        <span className="d-block text-danger fs12px"> Password is not strong.</span>
                      )}
                    </>
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setnewPasswordDet({ ...newPasswordDet, passwordVisibility: !newPasswordDet.passwordVisibility })}
                        >
                          {newPasswordDet.passwordVisibility ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              {!newPasswordDet.password ? "" : <PasswordMeter password={newPasswordDet.password} passwordPower={setstrongPassword} />}

              <Tooltip title="Password should be Minimum of 8 Characters(min. 1 special character like @, #, $, !, &, *), Minimum 1 Capital letter, Minimum 1 integer and  Maximum of 45 characters" arrow TransitionComponent={Zoom}>
                <div className="securePwd">What is a secure password? </div>
              </Tooltip>

              <div className="mt-2 w-100">
                <SmallInput
                  variant="outlined"
                  label=""
                  placeholder="Confirm Password"
                  fullWidth
                  onKeyDown={verifyOtpEnter}
                  autoComplete="confirm-password"
                  inputProps={{ maxLength: 30 }}
                  name="confirm password"
                  type={newPasswordDet.confirmPasswordVisibility ? "text" : "password"}
                  value={newPasswordDet.confirmPassword}
                  onChange={(val) => setnewPasswordDet({ ...newPasswordDet, confirmPassword: val.target.value })}
                  error={
                    (
                      newPasswordDet.confirmPassword === '' ||
                      newPasswordDet.confirmPassword !== newPasswordDet.password
                    ) ? newDet === true ? true : false : false
                  }
                  helperText={
                    <>
                      {newPasswordDet.confirmPassword === '' && newDet && (
                        <span className="d-block text-danger fs12px"> You must have to fill the Confirm Password.</span>
                      )}
                      {newPasswordDet.confirmPassword !== newPasswordDet.password && newDet && (
                        <span className="d-block text-danger fs12px"> password and confirm password are not same.</span>
                      )}
                    </>
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setnewPasswordDet({ ...newPasswordDet, confirmPasswordVisibility: !newPasswordDet.confirmPasswordVisibility })}
                        >
                          {newPasswordDet.confirmPasswordVisibility ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className="loginButtonContainer">
                <CustomButton
                  fullWidth
                  onClick={resetPassword}
                  disabled={loader}
                >
                  {loader ?
                    <CircularProgress style={{ color: "white" }} />
                    :
                    "Reset Password"
                  }
                </CustomButton>
              </div>
            </div>
          </div>

        </div>
      }

    </>
  );
}

export default CreateNewPassword;
