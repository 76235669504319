import React from "react";
import "./stepper.scss";

function StepperBuilder({ steps, activeStep, stepObj, index, foundIndex }) {

  // console.log("text", foundIndex, stepObj.current_status);
  // console.log(stepObj);
  //   console.log(activeStep); //undefined

  return (
    <>
      {index % 2 === 0 ? (
        <div className="fcc">
          <div
            className={foundIndex >= index ? "borderAccom" : "notBorderAccom"}
          ></div>
          <div className="rightside">
            <p className="mfr">{stepObj.state_name}</p>
            <div
              className={ foundIndex >= index ? "accomplished" : "notaccomplished" }
            ></div>
          </div>
        </div>
      ) : (
        <div className="fcc">
          <div
            className={foundIndex >= index ? "borderAccom" : "notBorderAccom"}
          ></div>
          <div className="leftside">
            <div
              className={ foundIndex >= index ? "accomplished" : "notaccomplished" }
            ></div>
            <p className="mfl">{stepObj.state_name}</p>
          </div>
        </div>
      )}
    </>
  );
}
export default StepperBuilder;
