import React, { useState } from "react";

import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { useHistory } from "react-router-dom";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import ProgressBar from "../ProgressBar/ProgressBar";
import Switch from "@material-ui/core/Switch";

import { useSession } from 'react-use-session';
import { useSnackbar } from "notistack";

import { UserService } from '../../utils/userServices';
import { API_WRONG } from '../../utils/messages';

import { IOSSwitch } from "../mui_components/muiComponents";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#f1f3f9",
    height: "40px",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "11px",
    lineHeight: "1.18",
    textAlign: "left",
    color: "#8898aa",
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);


function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "Ticket Id", numeric: false, disablePadding: true, label: "TICKED ID" },
  { id: "service", numeric: false, disablePadding: false, label: "SERVICE NAME" },
  { id: "analyst", numeric: true, disablePadding: false, label: "ANALYST" },
  { id: "organization", numeric: false, disablePadding: false, label: "ORGANIZATION" },
  { id: "createdAt", numeric: true, disablePadding: false, label: "CREATED AT" },
  { id: "status", numeric: true, disablePadding: false, label: "STATUS" },
  { id: "completion", numeric: true, disablePadding: false, label: "COMPLETION" },
  { id: "switch", numeric: false, disablePadding: false, label: "HOLD/START" }
  // { id: "button", numeric: false, disablePadding: false, label: "" }
];


function EnhancedTableHead(props) {

  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <StyledTableCell key={index}>{headCell.label}</StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  circularStyle: {
    display: "inline-block",
    backgroundColor: "#2d2d",
    borderRadius: "50%",
    width: 10,
    height: 10,
    marginRight: 5,
  },
  textStyles: {
    fontSize: "15px",
    color: "#172b4d",
    textAlign: "center",
  },
  checkboxStyles: {
    "&.MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#0e0e0e",
    },
  },
  rowStyles: {
    "&.MuiTableRow-root.Mui-selected": {
      backgroundColor: "#ffffff",
    },
    "&.MuiTableRow-root": {
      cursor: "pointer",
    },
  },
}));

export default function EnhancedTable(props) {

  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const { ordersList, refetch } = props;
  const { session } = useSession("cyber-security");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  // console.log('ordersList ', ordersList);

  function createData(project, createdAt, status, completion, analyst, organization, service, progress, document_id, analyst_name, feedback_status) {
    return { project, createdAt, status, completion, analyst, organization, service, progress, document_id, analyst_name, feedback_status };
  }

  const rowsArray = [
    ordersList.map((item, index) => {
      // console.log('item ', item.filled_json['Name of Organization *']);
      // const organization = item.filled_json['Name of Organization *'];
      let status;
      if (item.running_status === true) {
        status = "Inprogress";
      } else {
        status = "Hold";
      }

      let indexHold = item.completion.findIndex(p => p.current_status == 1);
      indexHold = item.completion[indexHold].state_id;
      indexHold = (indexHold / 8) * 100;

      if (indexHold === 12.5) {
        indexHold = 0;
      } else {
        indexHold = indexHold;
      }

      if (indexHold === 100) {
        status = "Completed";
      }

      return createData(item.ticket_id, item.ticket_created_at, status, indexHold, item.analyst_name, item.filled_json['Name of Organization *'], item.service_name, item.completion, item.document_id, item.analyst_name, item.feedback_status)

    })
  ];

  const rows = rowsArray[0];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.project);
      setSelected(newSelecteds);
      return;
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, project) => {
    const selectedIndex = selected.indexOf(project);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, project);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, value) => {
    console.log(event, value);
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const isSelected = (project) => selected.indexOf(project) !== -1;

  const toDetails = (currentData) => {
    // console.log('currentDatacurrentData', currentData);
    history.push({
      pathname: `/service`,
      state: { currentData }
    });
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handlePageChange = (event, value) => {
    // console.log('current page', value);
    // console.log('current page', currentPage.target);
    // console.log('current page', currentPage.target.innerText);
  }

  const updateStatus = async (data) => {
    // console.log('data', data.project);
    let state = data.status === "Inprogress" ? false : true;
    const putJson = {
      ticket_id: data.project,
      running_status: state
    }

    const updateResponse = await UserService.updateTicketState(putJson, session.token);
    // console.log('updateResponse', updateResponse);
    if (updateResponse && updateResponse.data && updateResponse.data.status) {

      enqueueSnackbar(updateResponse.data.message, { variant: "success" });
      // window.location.reload()
      refetch(true);
    } else {
      enqueueSnackbar(API_WRONG, { variant: "error" });
    }

  }

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    // console.log('order data', row);
                    const isItemSelected = isSelected(row.project);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        className={`${classes.rowStyles} dashboard-table-row`}
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                      >

                        {/* <TableCell
                        padding="checkbox"
                        style={{ height: "15%" }}
                      >
                        <Checkbox
                          className={classes.checkboxStyles}
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell> */}

                        <TableCell
                          onClick={() => toDetails(row)}
                          className="tickitIdHover"
                        >
                          {row.project}
                        </TableCell>

                        <TableCell
                          onClick={() => toDetails(row)}
                          className="analyst-name"
                        >
                          {row.service}
                        </TableCell>

                        <TableCell
                          onClick={() => toDetails(row)}
                          className="created-at"
                        >
                          {row.analyst_name}
                          {/* Gaurv shukla */}
                        </TableCell>

                        <TableCell
                          onClick={() => toDetails(row)}
                          className="organization-name"
                        >
                          {row.organization}
                        </TableCell>

                        <TableCell
                          onClick={() => toDetails(row)}
                          className="created-at"
                        >
                          <span>{row.createdAt}</span>
                        </TableCell>

                        <TableCell
                          onClick={() => toDetails(row)}
                          className="status"
                        >
                          <div className="vcenter fdrow jc-start  ">
                            {(() => {
                              if (row.status === "Pending") {
                                return (
                                  <div className="tabcomp"></div>
                                );
                              } else if (row.status === "Completed") {
                                return (
                                  <div style={{ backgroundColor: "#2dce98" }} className="tabcomp"></div>
                                );
                              } else if (row.status === "Hold") {
                                return (
                                  <div style={{ backgroundColor: "#fb6340" }} className="tabcomp"></div>
                                );
                              } else {
                                return (
                                  <div style={{ backgroundColor: "#11cdef" }} className="tabcomp"></div>
                                );
                              }
                            })()}

                            <div className={classes.textStyles}>{row.status} </div>

                          </div>
                        </TableCell>

                        {/* completion progressbar */}
                        <TableCell
                          onClick={() => toDetails(row)}
                          className="completion"
                        >
                          <div className="vcenter fdrow jc-start " >
                            <div className={`${classes.textStyles} mr-1`}>
                              {parseInt(row.completion)}%
                            </div>
                            {(() => {
                              if (row.completion < 20) {
                                return (
                                  <ProgressBar bgcolor="#f53c56" completed={row.completion} />
                                );
                              } else if (row.completion > 80) {
                                return (
                                  <ProgressBar bgcolor="#2dce98" completed={row.completion} />
                                );
                              } else if (
                                row.completion > 20 &&
                                row.completion < 60
                              ) {
                                return (
                                  <ProgressBar bgcolor="#11cdef" completed={row.completion} />
                                );
                              } else {
                                return (
                                  <ProgressBar bgcolor="#fb6340" completed={row.completion} />
                                );
                              }
                            })()}
                          </div>
                        </TableCell>

                        <TableCell className="hold-start">

                          {row.status !== "Completed" ?
                            <IOSSwitch onClick={() => updateStatus(row)} defaultChecked={row.status === "Inprogress" ? true : false} />
                            :
                            <IOSSwitch disabled={true} onClick={() => updateStatus(row)} defaultChecked={row.status === "Inprogress" ? true : false} />
                          }

                        </TableCell>
                      </TableRow>
                    );

                  })}
              </TableBody>
            </Table>
          </TableContainer>


        </Paper>

      </div>

    </>

  );
}
