import React, { useState, useEffect, useMemo } from "react";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { TextField } from "@material-ui/core";

import moment from 'moment';

import { CustomDatePicker } from "../mui_components/muiComponents";
import "date-fns";

function DateInputQuarter({
  title,
  label,
  placeholder,
  width,
  disableFuture,
  disabled,
  formData,
  setFormData,
  dataEntries,
  setDataEntries,
  error,
  subscription
}) {

  let dataEntriesFromLocalStorage = JSON.parse(localStorage.getItem("dataEntriesInLocal"));

  // console.log('month from localstorage ', dataEntriesFromLocalStorage["quarterNumber"]);
  // console.log('start date from localstorage ', dataEntriesFromLocalStorage["Start Date *"]);

  const [dateInp, setDateInp] = useState(null);
  const [quarterNumber, setMonthNumber] = useState(dataEntriesFromLocalStorage["quarterNumber"] ? dataEntriesFromLocalStorage["quarterNumber"] : 0);
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    // console.log('i am getting render');
    if (dataEntriesFromLocalStorage["Start Date *"]) {
      saveEndDate( dataEntriesFromLocalStorage["quarterNumber"] ? dataEntriesFromLocalStorage["quarterNumber"] : 1 );
      setMonthNumber( dataEntriesFromLocalStorage["quarterNumber"] ? dataEntriesFromLocalStorage["quarterNumber"] : 1 );
    }
  }, [0]);

  const dateChange = (id, e) => {

    if (e) {
      setDateInp(e.getFullYear() + "-" + (e.getMonth() + 1) + "-" + e.getDate());
      let formattedDate = e.getFullYear() + "-" + (e.getMonth() + 1) + "-" + e.getDate();
      // console.log('start date change', formattedDate);
      // let obj = { ...formData, ['Start Date *']: formattedDate, ['quarterNumber']: 1 };
      // let obj1 = { ...dataEntriesFromLocalStorage, ['Start Date *']: formattedDate, ['quarterNumber']: 1 };
      // let obj = { ...formData, ['Start Date *']: formattedDate };
      // let obj1 = { ...dataEntriesFromLocalStorage, ['Start Date *']: formattedDate };
      // setFormData(obj);
      // setDataEntries(obj1);
      // localStorage.setItem("dataEntriesInLocal", JSON.stringify(obj1));
      saveEndDate(1, formattedDate);
      setMonthNumber(1);
    }

  };

  function monthChange(value, val) {
    // console.log('getting triggered with ', value.target.value);
    const parsedVal = parseInt(value.target.value);
    if (parsedVal === 0) {
      setMonthNumber(1);
      saveEndDate(1);
    } else if(parsedVal === NaN ) {
      setMonthNumber(1);
      saveEndDate(1);
    }else{
      setMonthNumber(parsedVal);
      saveEndDate(parsedVal);
    }

  }

  function saveEndDate(monthCount, passedStartDate) {
    let dateStart;
    if(passedStartDate !== undefined) {
      dateStart = passedStartDate;
    }else{
      dateStart = dataEntriesFromLocalStorage["Start Date *"];
    }
    
    // console.log('save end date getting called with month', monthCount);
    // console.log('start date taken from local ', dateStart);
    const inDate = moment(dateStart, 'YYYY-MM-DD');

    const futureMonthDate = moment(inDate).add((monthCount*3), 'months').format('DD-MM-YYYY');
    setEndDate(futureMonthDate);

    let obj = { ...formData, ['End Date *']: futureMonthDate, ['quarterNumber']: monthCount, ['Start Date *']: dateStart };
    let obj1 = { ...dataEntriesFromLocalStorage, ['End Date *']: futureMonthDate, ['quarterNumber']: monthCount, ['Start Date *']: dateStart };
    setFormData(obj);
    setDataEntries(obj1);
    localStorage.setItem("dataEntriesInLocal", JSON.stringify(obj1));
  }

  // console.log(' number of month ', quarterNumber);
  // console.log(' end date ', endDate);


  return (
    <div style={{ width: width ? width : "100%" }} >

      <MuiPickersUtilsProvider utils={DateFnsUtils} fullWidth>

        {placeholder == "Start Date *" ?
          <div className="flex  ">

            <CustomDatePicker
              inputVariant="outlined"
              fullWidth
              minDate={new Date()}
              variant="dialog"
              format="dd-MM-yyyy"
              openTo="year"
              label={title}
              placeholder={placeholder ? title : ""}
              value={dataEntriesFromLocalStorage["Start Date *"] ? dataEntriesFromLocalStorage["Start Date *"] : null}
              disabled={disabled ? disabled : false}
              onChange={(e, v) => dateChange(placeholder, e, v)}
              error={
                typeof error === "object" && Object.keys(error).length ? true : false
              }
              helperText={
                typeof error === "object" && Object.keys(error).length ? (
                  <>
                    {Object.keys(error).map((item, index) => {
                      return (
                        <span className="text-danger fs14px" key={index}>{error[item]}</span>
                      );
                    })}
                  </>
                ) : null
              }
            />

            <input
              id="outlined-basic"
              variant="outlined"
              style={{ width: "35px", border: "1px solid black", borderRadius: "5px", textAlign: "center" }}
              className="ml-2 custom-input-padding"
              type="number"
              value={quarterNumber === 0 || quarterNumber === NaN ? 1 : quarterNumber}
              disabled={dataEntriesFromLocalStorage["Start Date *"] === "" ? true : false}
              onChange={(e, v) => monthChange(e, v)}
            />

          </div>
          :

          <TextField
            id="outlined-basic-alter"
            variant="outlined"
            label="End Date *"
            value={dataEntriesFromLocalStorage["End Date *"] ? dataEntriesFromLocalStorage["End Date *"] : ""}
            disabled={true}
          />

        }


      </MuiPickersUtilsProvider>
    </div>
  );
}

export default DateInputQuarter;
