import React from "react";
import { useHistory } from "react-router-dom";
import rightArrow from '../../assets/image/rightArrow.svg';

function CommonPageTitle({ pageTitleOne, pageTitleTwo, pageTitleThree, setallSelected }) {

  const history = useHistory();

  // const showthem = pageTitleThree;
  // let holdurl = pageTitleThree.split(' ')[1].substring(1, holdurl.length-1).trim();
  // let holdurl = pageTitleThree.split(' ')[1].substring(1, pageTitleThree.split(' ')[1].length - 1).trim();
  // console.log('wil see', holdurl);

  // console.log('pageTitleThree', pageTitleThree);
  // console.log('pageTitleTwo', pageTitleTwo, pageTitleTwo.length, typeof pageTitleTwo);

  const pushToDirect = () => {
    const val = pageTitleThree.split(" ")[1];
    // console.log('pageTitleThree', val);
    if (val == undefined) {

    } else {
      const filter = val.substring(1, pageTitleThree.split(" ")[1].length - 1).trim();
      history.push(filter);
    }
  }

  return (
    <>

      {pageTitleOne.length > 0 ? (
        <p
          className="breadcrumb-text"
          onClick={() => {
            setallSelected({
              athena: {},
              thunderbolt: {}
            });
            history.push('/')
          }}
        > Home</p>
      ) : null}

      {pageTitleThree.length ?
        <div className="breadcrumb-icon">
          <img src={rightArrow} alt="rightArrow" />
        </div>
        : null}

      {pageTitleThree.length > 0 ? (
        <p className="breadcrumb-text"
          onClick={() => pushToDirect(pageTitleThree)}
        >
          {pageTitleThree.split(" ")[0]}
        </p>
      ) : null}

      {pageTitleTwo.length || typeof pageTitleTwo === 'number' ?
        <div className="breadcrumb-icon">
          <img src={rightArrow} alt="rightArrow" />
        </div>
        : null}

      {pageTitleTwo ?
        <p className="breadcrumb-text active"> {pageTitleTwo}</p>
        : null}

    </>
  );
}

export default CommonPageTitle;
