import React, { useState, useEffect } from "react";
import { useSession } from 'react-use-session';
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";

import { AuthServices } from '../../utils/auth';
import { apiErrorHandler } from '../../utils/helper';

import sadFace from '../../assets/image/sad-face.png';

const Unsubscribe = ({ setHitProfile }) => {

  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [positive, setPositive] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useSession("cyber-security");

  useEffect(() => {
    UnsubscribeEmail();
  }, [0]);

  async function UnsubscribeEmail() {

    const formData = new FormData();
    formData.append('user_id', session.user_id);
    formData.append('is_email_subscribed', "False");

    const apiResponse = await AuthServices.putUserDetails(formData, session.token);
    // console.log('email unsubscribed response', apiResponse);
    setLoader(false);
    if (apiResponse.status !== undefined && apiResponse.status === 200) {
      setHitProfile(true);
      setPositive(true);
      redirectToDashboard();
      enqueueSnackbar(apiResponse.data.message, { variant: "success" });
    } else {
      setPositive(false);
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }


  function redirectToDashboard() {
    setTimeout(() => {
      history.push('/dashboard');
    }, 1000);
  }

  return (
    <div className="order-verify mt-32px">
      <div className="order-wrapper">
        {loader === true ?
          <>
            <div className="loader-section">
              <div className="loader-small"></div>
            </div>
            <p className="verify-text">Unsubscribing</p>
          </>
          :
          <>
            {positive === true ?
              <>
                <div className="loader-section">
                  <svg width="87" height="86" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="48.5" cy="48" r="45" stroke="#9dff89" strokeWidth="6" />
                    <path fillRule="evenodd" clipRule="evenodd"
                      d="M43.7126 55.3541L61.275 37.7918C62.3307 36.7361 64.0423 36.7361 65.0979 37.7918C66.1536 38.8474 66.1536 40.559 65.0979 41.6147L43.7126 63L31.2918 50.5791C30.2361 49.5235 30.2361 47.8119 31.2918 46.7562C32.3474 45.7005 34.059 45.7005 35.1147 46.7562L43.7126 55.3541Z"
                      fill="black" />
                    <mask id="mask0_3368:9017" maskUnits="userSpaceOnUse" x="30" y="37" width="36" height="26">
                      <path fillRule="evenodd" clipRule="evenodd"
                        d="M43.7126 55.3541L61.275 37.7918C62.3307 36.7361 64.0423 36.7361 65.0979 37.7918C66.1536 38.8474 66.1536 40.559 65.0979 41.6147L43.7126 63L31.2918 50.5791C30.2361 49.5235 30.2361 47.8119 31.2918 46.7562C32.3474 45.7005 34.059 45.7005 35.1147 46.7562L43.7126 55.3541Z"
                        fill="white" />
                    </mask>
                    <g mask="url(#mask0_3368:9017)">
                    </g>
                  </svg>
                </div>
                <div className="mt-5"> You are now unsubscribed from email notifications </div>
              </>
              :
              <img alt="Wrong order id" width="150px" height="150px" src={sadFace} />
            }
          </>
        }

      </div>
    </div>
  );
}

export default Unsubscribe;
