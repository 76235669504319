import React, { useMemo } from "react";
import DateInputComponent from "./DateInputComponent";
import DateInputOneTime from "./DateInputOneTime";
import DateInputMonthly from "./DateInputMonthly";
import DateInputQuarter from "./DateInputQuarter";

import "date-fns";

function ScheduleLaunchComponent({
  title,
  fields,
  formData,
  setFormData,
  dataEntries,
  setDataEntries,
  errors,
  subscriptionTitle
}) {

  const findAndReturn = (findParams) => {
    let val = Object.keys(errors).find((item) => item == findParams);
    return errors[val];
  }

  // console.log(' god help ', subscriptionTitle);

  return (
    <div className="date-component">

      {subscriptionTitle === "24x7" ?
        <>
          {
            fields.map((key, index) => {
              if (key.type === "date") {
                return (
                  <DateInputComponent
                    title={key.title}
                    label={key.label}
                    key={index}
                    placeholder={key.placeholder}
                    width={key.width}
                    disableFuture={key.disableFuture}
                    disabled={key.disabled}
                    formData={formData}
                    setFormData={setFormData}
                    dataEntries={dataEntries}
                    setDataEntries={setDataEntries}
                    error={findAndReturn(key.placeholder)}
                    subscription={subscriptionTitle}
                  />
                );
              }
            })
          }
        </>
        : null}

      {subscriptionTitle === "One Time" ?
        <>
          {
            fields.map((key, index) => {
              if (key.type === "date") {
                return (
                  <DateInputOneTime
                    title={key.title}
                    label={key.label}
                    key={index}
                    placeholder={key.placeholder}
                    width={key.width}
                    disableFuture={key.disableFuture}
                    disabled={key.disabled}
                    formData={formData}
                    setFormData={setFormData}
                    dataEntries={dataEntries}
                    setDataEntries={setDataEntries}
                    error={findAndReturn(key.placeholder)}
                    subscription={subscriptionTitle}
                  />
                );
              }
            })
          }
        </>
        : null}

      {subscriptionTitle === "Monthly" ?
        <>
          {
            fields.map((key, index) => {
              if (key.type === "date") {
                return (
                  <DateInputMonthly
                    title={key.title}
                    label={key.label}
                    key={index}
                    placeholder={key.placeholder}
                    width={key.width}
                    disableFuture={key.disableFuture}
                    disabled={key.disabled}
                    formData={formData}
                    setFormData={setFormData}
                    dataEntries={dataEntries}
                    setDataEntries={setDataEntries}
                    error={findAndReturn(key.placeholder)}
                    subscription={subscriptionTitle}
                  />
                );
              }
            })
          }
        </>
        : null}

      {subscriptionTitle === "Quarterly" ?
        <>
          {
            fields.map((key, index) => {
              if (key.type === "date") {
                return (
                  <DateInputQuarter
                    title={key.title}
                    label={key.label}
                    key={index}
                    placeholder={key.placeholder}
                    width={key.width}
                    disableFuture={key.disableFuture}
                    disabled={key.disabled}
                    formData={formData}
                    setFormData={setFormData}
                    dataEntries={dataEntries}
                    setDataEntries={setDataEntries}
                    error={findAndReturn(key.placeholder)}
                    subscription={subscriptionTitle}
                  />
                );
              }
            })
          }
        </>
        : null}

    </div>
  );
}

export default ScheduleLaunchComponent;
