import React from "react";
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { Button, TextField, Chip, Tooltip } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, DatePicker  } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";

const CustomDatePicker = withStyles((theme) => ({
  root: {
    backgroundColor: "white",
    width: "100%",
    "& * ": {
      fontSize: "16px",
      color: "#000000",
      textAlign: "left",
    },
    "& label ": {
      color: "#000000",
      fontSize: "16px",
    },
    "& .MuiInputBase-input": {
      fontSize: "16px",
    },
    // "& label.Mui-focused": {
    //   color: "black",
    // },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        // borderColor: "black",
      },
      "&.MuiOutlinedInput-adornedEnd": {
        height: "54px",
      }
    },
  },
}))(DatePicker);

const NextButton = withStyles((theme) => ({
  root: {
    fontFamily: "Roboto-SemiBold",
    width: "90px",
    height: "35px",
    fontSize: "8px",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "0.1",
    textAlign: "left",
    border: "2px solid #174ea2",
    backgroundColor: "#174ea2",
    color: "white",
    "&:hover": {
      fontFamily: "Roboto-SemiBold",
      width: "90px",
      height: "40px",
      fontSize: "9px",
      fontWeight: "small",
      fontStyle: "normal",
      letterSpacing: "0.1",
      textAlign: "left",
      border: "2px solid #174ea2 ",
      backgroundColor: "#174ea2",
      color: "white",
    },
  },
}))(Button);

const ReviewOrSubmit = withStyles((theme) => ({
  root: {
    fontFamily: "Roboto-SemiBold",
    width: "150px",
    height: "50px",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "0.1",
    textAlign: "left",
    border: "2px solid #174ea2",
    backgroundColor: "#174ea2",
    color: "white"
  },
}))(Button);

const SmallInput = withStyles({
  root: {
    color: "#989fa4",
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInputLabel-outlined": {
      color: "#989fa4",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
})(TextField);

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 36,
    height: 20,
    padding: 0,
    width: "37px",
    height: "20px",
    margin: theme.spacing(1),
    border: "1px solid black",
    borderRadius: 26 / 2,
  },
  switchBase: {
    padding: 1,
    color: "#8898aa",
    whiteSpace: "nowrap",
    "&$checked": {
      transform: "translateX(15px)",
      color: "#7764e4",
      "& + $track": {
        backgroundColor: "#ffffff",
        opacity: 1,
      },
    },
    "&$focusVisible $thumb": {
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 17,
    height: 15,
  },
  track: {
    borderRadius: 26 / 2,
    color: "#000000",
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#ffffff",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const BigInput = withStyles((theme) => ({
  root: {
    backgroundColor: "white",
    "& * ": {
      width: "400px",
      height: "220px",
      borderRadius: "2px",
      fontSize: "18px",
      color: "#ffffff",
      borderColor: "#00000",
      marginTop: "-41px",
    },
    "& label ": {
      fontSize: "18px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "left",
      padding: "60px",
      paddingLeft: "18px",
      color: "#989fa4",
    },
  },
}))(TextField);

export {
  SmallInput,
  NextButton,
  BigInput,
  IOSSwitch,
  CustomDatePicker,
  ReviewOrSubmit,
};
