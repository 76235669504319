
import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useSession } from "react-use-session";
import { useSnackbar } from "notistack";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import { DataURIToBlob, imageToBlob, arrayOfUploadFields, apiErrorHandler } from '../../utils/helper';
import { ADD_TO_CART, HAVE_TO_AGREE } from "../../utils/messages";
import { dataEntriesObj } from '../../utils/localEntries';
import { UserService } from "../../utils/userServices";
import FormBuilder from '../../utils/dataForms';
import validate from "../../utils/validations";
import ReviewViewer from '../../utils/review';
import MSG from '../../utils/messase.json';
import "date-fns";

function DataNeededFromCustomers(props) {

  const {
    setPageTitleOne,
    setPageTitleTwo,
    setPageTitle,
    setPageTitleThree,
    setCommonPageTitleCheck,
    sethitNotification,
    sethitCart,
    middleData,
    setMiddleData,
    selectedServices,
    setSelectedServices,
    hitNotification,
    hitCart,
  } = props;

  const history = useHistory();

  if (props.location.state === undefined) {
    history.goBack();
  }

  // if (middleData == undefined || middleData == null) {
  //   setMiddleData(props.location.state.selectedOne);
  // }

  const { enqueueSnackbar } = useSnackbar();
  const { session } = useSession("cyber-security");
  const sessionToken = session.token;

  const [chipData, setChipData] = useState([]);
  const [termsCheckbox, setTermsCheckbox] = useState(false);
  const [formName, setFormName] = useState(0);
  const [formData, setFormData] = useState({});
  const [dataEntries, setDataEntries] = useState(dataEntriesObj);
  const [errors, seterrors] = useState({});
  const [fromSubmim, setformSubmit] = useState(false);
  const [json3, setjson3] = useState(null);
  const [loading, setloading] = useState(false);
  const [liveReview, setliveReview] = useState(false);
  const [reviewOrEdit, setReviewOrEdit] = useState("REVIEW");

  const [isFileSelected, setisFileSelected] = useState(false);
  const [documentId, setDocumentId] = useState(false);


  useEffect(async () => {

    defaultLocalStorage();


    setPageTitleOne("Home");
    setPageTitleTwo("Get Started");
    setPageTitleThree("Product %/product%");
    setPageTitle("Service Form");
    setCommonPageTitleCheck(true);
  }, []);

  useEffect(() => {
    if (fromSubmim === true) {
      setformSubmit(false);
      finalSubmition();
    }
  }, [fromSubmim]);

  useEffect(async () => {
    if (middleData !== undefined && middleData !== null) {
      await loadPrePageData();
    } else {

      let tempArray = [];
      const bgColorJson = localStorage.getItem('bgcolor');
      if (bgColorJson == null) {
        setMiddleData(props.location.state.selectedOne);
      } else {
        const parsedArr = JSON.parse(bgColorJson);
        let holdTempData = props.location.state.selectedOne;
        for (let item of parsedArr) {
          delete holdTempData[item];
        }
        setMiddleData(holdTempData);
      }


    }
  }, [middleData]);

  function loadPrePageData() {

    // const dataIgot = props.location.state.selectedOne;
    const dataIgot = middleData;
    // console.log('data i got', dataIgot);

    let chipArr = [];
    Object.entries(dataIgot).map(([key, value]) => {
      chipArr.push(key);
    });
    setChipData(chipArr);

    setFormName(Object.keys(dataIgot)[0]);

    setFormData(dataIgot[chipArr[0]].service_inputformjson.dataFields);
    seterrors(dataIgot[chipArr[0]].service_inputformjson.dataFields);
    setjson3(dataIgot);
  }

  function finalSubmition() {
    if (Object.keys(errors).length !== 0) {
      Object.keys(errors).some((item, key) => {
        if (typeof errors[item] == "object") {
          // console.log('got error', errors);
          enqueueSnackbar(MSG.COMPLETE_MANDATORY, { variant: "error" });
          return true;
        } else {
          if (key + 1 === Object.keys(errors).length) {
            setloading(true);
            submitData(formData);
          }
        }
      });
    } else {
      setloading(true);
      submitData(formData);
    }
  }

  function defaultLocalStorage() {
    if (
      localStorage.dataEntriesInLocal &&
      localStorage.dataEntriesInLocal.length > 0
    ) {
      let dataRequiredFromLocalStorage = JSON.parse(localStorage.getItem("dataEntriesInLocal"));
      localStorage.setItem("dataEntriesInLocal", JSON.stringify(dataRequiredFromLocalStorage));
    } else {
      localStorage.setItem("dataEntriesInLocal", JSON.stringify(dataEntriesObj));
    }
  }

  function onChipClick(item) {
    const newSet = json3[item].service_inputformjson.dataFields;
    setFormData(newSet);
    seterrors(newSet);
    setFormName(item);
  }

  const submitData = async (postData) => {

    // console.log('data to submit', postData);
    let formData = new FormData(), document_id = 0, fileInForm = false;

    for (let item in arrayOfUploadFields) {
      if (arrayOfUploadFields[item] in postData) {
        if (postData[arrayOfUploadFields[item]] !== '') {
          fileInForm = true;
        }
      }
    }

    // console.log('fileInForm', fileInForm);

    if (fileInForm == true) {
      fileInForm = false;

      const documentRes = await UserService.getDocumentId(sessionToken);
      if (documentRes.status === 201) {
        document_id = documentRes.data.data.document_id;
      } else {
        apiErrorHandler(documentRes, enqueueSnackbar);
      }

      // console.log('document_id', document_id);
      // console.log('post data file i have', postData);

      if ("Upload list of IP's" in postData) {
        if (postData["Upload list of IP's"] !== '') {
          const IpFile = await DataURIToBlob(postData["Upload list of IP's"]);
          formData.append('file', IpFile);
          formData.append('document_id', document_id);
          formData.append('json', postData["Upload list of IP's"][1]);
        }
      }

      if ("Upload Audit Report" in postData) {
        // console.log('Upload Audit Report detected');
        if (postData["Upload Audit Report"] !== '') {
          const IpFile = await DataURIToBlob(postData["Upload Audit Report"]);
          formData.append('file', IpFile);
          formData.append('document_id', document_id);
        }
      }

      if ("Picture" in postData) {
        // console.log('Picture detected');
        if (postData["Picture"] !== '') {
          const IpFile = await imageToBlob(postData["Picture"]);
          formData.append('file', IpFile);
          formData.append('document_id', document_id);
          // formData.append('json', document_id);
        }
      }

      const apiResponse = await UserService.fileUpload(formData, session.token);
      if (apiResponse.status == 201) {
        enqueueSnackbar(apiResponse.data.message, { variant: "success" });
      } else {
        apiErrorHandler(apiResponse, enqueueSnackbar);
      }
    }

    delete postData["Upload list of IP's"];
    delete postData["Upload Audit Report"];
    delete postData["Picture"];

    const dataFormat = {
      fill_value_json: postData,
      service_id: json3[formName].service_id,
      subscription_id: json3[formName].subscription_id,
      user_id: session.user_id,
      document_id: document_id
    };

    const apiResponse = await UserService.uploadToCart(dataFormat, sessionToken);
    setloading(false);

    if (apiResponse.status === 201) {

      enqueueSnackbar(ADD_TO_CART, { variant: "success" });
      reflectOpration();
      sethitNotification(true);
      sethitCart(true);

    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }

  };

  async function reflectOpration() {

    // let dataIgot = props.location.state.selectedOne;
    let dataIgot = middleData;
    delete dataIgot[formName];
    console.log('confirming form name ', formName);

    let tempArray = [];
    const bgColorJson = localStorage.getItem('bgcolor');
    if (bgColorJson == null) {
      tempArray.push(formName);
      localStorage.setItem('bgcolor', JSON.stringify(tempArray));
    } else {
      const parsedArr = JSON.parse(bgColorJson);
      tempArray.push(formName);
      console.log('parsed array i have ', parsedArr);
      let concatedArr = tempArray.concat(parsedArr);
      console.log('concated array ', concatedArr);
      localStorage.setItem('bgcolor', JSON.stringify(concatedArr));
    }


    if (Object.keys(dataIgot).length) {

      let chipArr = [];
      Object.entries(dataIgot).map(([key, value]) => {
        chipArr.push(key);
      });
      setChipData(chipArr);

      setFormData(dataIgot[chipArr[0]].service_inputformjson.dataFields);
      seterrors(dataIgot[chipArr[0]].service_inputformjson.dataFields);
      setjson3(dataIgot);
      setFormName(Object.keys(dataIgot)[0]);

    } else {
      localStorage.removeItem("dataEntriesInLocal");
      history.replace('/cart');
    }
  }




  const showReview = () => {
    setliveReview(!liveReview);
    const formobj = document.getElementById("formSection");
    const formobjWill = document.getElementById("formConsentSection");

    liveReview ? setReviewOrEdit("REVIEW") : setReviewOrEdit("EDIT");

    formobj.classList.toggle("d-none");
    formobjWill.classList.toggle("d-none");
  };

  async function submit() {
    if (termsCheckbox === true) {
      await validate(formData, seterrors, setformSubmit);
    } else {
      enqueueSnackbar(HAVE_TO_AGREE, { variant: "error" });
    }
  };


  // console.log(' bg color i get ', localStorage.getItem('bgcolor'));

  // console.log('json3', json3);
  // console.log('form data', formData);
  // console.log('form data', documentId);
  // console.log('chip data', chipData);
  // console.log('form name', formName);

  return (
    <>
      <div className="mt-32px">

        <div className="cips-wrapper">
          {
            chipData.length >= 1 &&
              json3 !== null &&
              Object.keys(json3).length
              ?
              <>
                {
                  chipData.map((item, index) => {
                    return (
                      <Chip
                        className="form-chips"
                        style={{ backgroundColor: item === formName ? "#C8C8C8" : "#f1f3f9" }}
                        onClick={onChipClick.bind('', item)}
                        key={index}
                        value={item}
                        label={json3[item].service_name}
                      />
                    );
                  })
                }
              </>
              : null}
        </div>

        <div className="data-form-container">


          {formName !== 0 && formName && json3[parseInt(formName)] && json3[parseInt(formName)].service_name ?
            <div className="current-form-name">{json3[parseInt(formName)].service_name} </div>
            : null}


          {errors !== undefined &&
            Object.keys(errors).length > 0 &&
            json3 !== null ? (
            <>
              <div className="form-area-container" id="formSection">

                <div className="form-area-wrapper">

                  {json3[formName] &&
                    json3[formName].service_inputformjson.sideLeft &&
                    FormBuilder(
                      {
                        formsInput: json3[formName].service_inputformjson.sideLeft,
                        formData: formData,
                        setFormData: setFormData,
                        dataEntries: dataEntries,
                        setDataEntries: setDataEntries,
                        dependency: formName,
                        errors: errors,
                        setisFileSelected: setisFileSelected,
                        formName: formName,
                        subscriptionTitle: json3[parseInt(formName)].title
                      }
                    )
                  }

                </div>

                <div className="form-area-wrapper">

                  {json3[formName] &&
                    json3[formName].service_inputformjson.sideRight &&
                    FormBuilder(
                      {
                        formsInput: json3[formName].service_inputformjson.sideRight,
                        formData: formData,
                        setFormData: setFormData,
                        dataEntries: dataEntries,
                        setDataEntries: setDataEntries,
                        dependency: formName,
                        errors: errors,
                        setisFileSelected: setisFileSelected,
                        formName: formName,
                        subscriptionTitle: json3[parseInt(formName)].title
                      }
                    )
                  }

                </div>

              </div>

            </>
          ) : null}


          <div className="data-from-terms" id="formConsentSection">
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  checked={termsCheckbox}
                  color="default"
                  onClick={(element) => setTermsCheckbox(!termsCheckbox)}
                />
              }
              label="I agree that I have read the privacy policy and consent to the
                    given information being used by Atheniantech for the requested
                    service."
              labelPlacement="end"
            />
          </div>



          {formData && liveReview == true ? (
            <div id="reviewSection" className="bg-white px-1 w-100">
              <div className="revWr reviewBgup">
                <div className="border-around d-none">
                  {Object.keys(formData).length ? (
                    <>
                      {Object.keys(formData).map((item, index) => {
                        return (
                          <div
                            className="w-100 border-bottom-review d-flex"
                            key={index}
                          >
                            <div className="w-25 border-right text-left p-1">
                              {item}
                            </div>
                            <div className="w-75 text-left p-1">
                              {formData[item]}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : null}
                </div>

                <div className="border-around">

                  {Object.keys(json3[formName].service_inputformjson.sideLeft).length ? (
                    <ReviewViewer data={json3[formName].service_inputformjson.sideLeft} formData={formData} />
                  ) : null}

                  {Object.keys(json3[formName].service_inputformjson.sideRight).length ? (
                    <ReviewViewer data={json3[formName].service_inputformjson.sideRight} formData={formData} />
                  ) : null}

                </div>

              </div>
            </div>
          ) : null}


          <div className="d-flex hcenter -mb20px">

            <div
              onClick={showReview}
              className="mr10px form-submission"
            >{reviewOrEdit}</div>

            {liveReview === false ?
              <div
                onClick={submit}
                className="mr10px form-submission"
              >SUBMIT</div>
              : null}

          </div>

        </div>

      </div>

      <Dialog open={loading}>
        <DialogTitle>
          <CircularProgress />
        </DialogTitle>
      </Dialog>
    </>
  );
}

export default DataNeededFromCustomers;
