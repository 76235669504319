import { create } from 'domain';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import rootReducer from './reducers/rootReducer';
//const store = createStore(rootReducer);

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

export default store;
