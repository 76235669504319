import API from "./API";
import { API_WRONG } from './messages';

const registraion = (registrationParas) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await API.post('register/', registrationParas);
      // console.log('responseresponse.', response);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  })
}

const login = (loginParams) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await API.post("login/", loginParams);
      return resolve(response);
    } catch (error) {
      // console.log('Something went wrong with the api.', error);
      // console.log('Something went wrong with the api.', error.response);
      // clearInterval(intervalID.Id);
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  });
};

const sendPasswordOtp = (otpParams) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await API.post("password_reset/", otpParams);
      return resolve(response);
    } catch (error) {
      // console.log('Something went wrong with the api.', error);
      // console.log('Something went wrong with the api.', error.response);
      // clearInterval(intervalID.Id);
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  });
};

const resetPassword = (resetPasswordParams) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await API.post("password_reset/confirm/", resetPasswordParams);
      return resolve(response);
    } catch (error) {
      // console.log('Something went wrong with the api.', error);
      // console.log('Something went wrong with the api.', error.response);
      // clearInterval(intervalID.Id);
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  });
};

const getUserDetails = (user_id, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.get(`profile_data/?user_id=${user_id}`, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  });
};

const putUserDetails = (profileData, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.post("profile_data/", profileData, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  });
};

const feedbackService = (feedbackData, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let response = await API.post("feedback/", feedbackData, config);
      return resolve(response);
    } catch (error) {
      if (error.response) {
        return resolve(error.response);
      } else {
        let error = { status: "", statusText: API_WRONG };
        return resolve(error);
      }
    }
  });
};

export const AuthServices = {
  registraion,
  login,
  sendPasswordOtp,
  resetPassword,
  getUserDetails,
  putUserDetails,
  feedbackService
};
