import React from "react";
import DocumentUploadComponent from "./DocumentUploadComponent";
import PictureInputComponent from "./PictureInputComponent";
import RadioInputComponent from "./RadioInputComponent";
import TextInputComponent from "./TextInputComponent";
import SelectInputComponent from "./SelectInputComponent";
import UploadButton from "./UploadButton";
import UploadAuditReport from "./UploadAuditReport";

function VariableSizedInputComponent({
  title,
  fields,
  formData,
  setFormData,
  dataEntries,
  setDataEntries,
  dependency,
  errors,
  isFileSelected,
  setisFileSelected,
  documentId
}) {

  const findAndReturn = (findParams) => {
    let val = Object.keys(errors).find((item) => item == findParams);
    return errors[val];
  }

  return (
    <>
      {fields.map((key, index) => {

        if (key.type === "text") {
          return (
            <TextInputComponent
              key={index}
              label={key.label}
              placeholder={key.placeholder}
              width={key.width}
              disabled={key.disabled}
              height={key.height}
              formData={formData}
              setFormData={setFormData}
              dataEntries={dataEntries}
              setDataEntries={setDataEntries}
              dependency={dependency}
              error={findAndReturn(key.placeholder)}
              required={key.required}
            />
          );
        }
        
        if (key.type === "number") {
          return (
            <TextInputComponent
              key={index}
              label={key.label}
              placeholder={key.placeholder}
              width={key.width}
              disabled={key.disabled}
              type={key.type}
              height={key.height}
              formData={formData}
              setFormData={setFormData}
              dataEntries={dataEntries}
              setDataEntries={setDataEntries}
              dependency={dependency}
              error={findAndReturn(key.placeholder)}
              required={key.required}
            />
          );
        } else if (key.type === "multiline") {
          return (
            <TextInputComponent
              key={index}
              label={key.label}
              placeholder={key.placeholder}
              width={key.width}
              disabled={key.disabled}
              type={key.type}
              height={key.height}
              rows={key.rows}
              formData={formData}
              setFormData={setFormData}
              dataEntries={dataEntries}
              setDataEntries={setDataEntries}
              dependency={dependency}
              error={findAndReturn(key.placeholder)}
              required={key.required}
            />
          );
        } else if (key.type === "Picture") {
          return (
            <PictureInputComponent
              title={key.title}
              placeholder={key.placeholder}
              width={key.width}
              key={index}
              formData={formData}
              setFormData={setFormData}
              dataEntries={dataEntries}
              setDataEntries={setDataEntries}
              required={key.required}
              setisFileSelected={setisFileSelected}
              documentId={documentId}
            />
          );
        } else if (key.type === "upload") {
          return (
            <DocumentUploadComponent
              label={key.label}
              placeholder={key.placeholder}
              key={index}
              width={key.width}
              disabled={key.disabled}
              formData={formData}
              setFormData={setFormData}
              dataEntries={dataEntries}
              setDataEntries={setDataEntries}
              required={key.required}
            />
          );
        } else if (key.type === "radio") {
          return (
            <RadioInputComponent
              label={key.label}
              placeholder={key.placeholder}
              width={key.width}
              key={index}
              disabled={key.disabled}
              dataEntries={dataEntries}
              setDataEntries={setDataEntries}
              formData={formData}
              setFormData={setFormData}
              required={key.required}
            />
          );
        } else if (key.type === "selection") {
          return (
            <SelectInputComponent
              width={key.width}
              key={index}
              label={key.label}
              placeholder={key.placeholder}
              dropDownFields={key.dropDownFields}
              disabled={key.disabled}
              formData={formData}
              setFormData={setFormData}
              dataEntries={dataEntries}
              setDataEntries={setDataEntries}
              required={key.required}
            />
          );
        } else if (key.type === "uploadButton") {
          return (
            <UploadButton
              key={index}
              label={key.label}
              placeholder={key.placeholder}
              width={key.width}
              disabled={key.disabled}
              formData={formData}
              setFormData={setFormData}
              dataEntries={dataEntries}
              setDataEntries={setDataEntries}
              required={key.required}
              setisFileSelected={setisFileSelected}
              documentId={documentId}
            />
          );
        } else if (key.type === "uploadAuditReport") {
          return (
            <UploadAuditReport
              key={index}
              label={key.label}
              placeholder={key.placeholder}
              width={key.width}
              disabled={key.disabled}
              formData={formData}
              setFormData={setFormData}
              dataEntries={dataEntries}
              setDataEntries={setDataEntries}
              required={key.required}
              setisFileSelected={setisFileSelected}
              documentId={documentId}
            />
          );
        }

      })}
    </>
  );
}

export default VariableSizedInputComponent;
