import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import Modal from '@material-ui/core/Modal';

import { UserService } from '../../utils/userServices';
import { useSnackbar } from "notistack";
import { API_WRONG } from '../../utils/messages';
import './around.scss';

import athenaLogo from '../../assets/image/athena-logo.png';
import bgBlue from '../../assets/image/bg-blue.png';
import digitalRisk from '../../assets/image/digital-risk-assement.png';
import bgLightBlue from '../../assets/image/bg-light-blue.png';
import redTeam from '../../assets/image/red-team-assessment.png';
import socialMedia from '../../assets/image/social-media.png';
import supplierRisk from '../../assets/image/supplier-risk.png';
import landingBg from '../../assets/image/landing-bg.png';

import Demo from './Demo';


const Athena = (props) => {

  const history = useHistory();
  const [modalState, setModalState] = useState(false);
  const { setClickedService, clickedService } = props;

  function directToAthena(serviceSelected) {
    setClickedService(serviceSelected);
    history.push('/athena');
  }

  function openMadalFun() {
    setModalState(true);
  }

  function closeReviewMadal() {
    setModalState(false);
  }

  return (
    <>
      <div className="landing-wrapper">
        <div className="athena-block">

          <div className="athen-info-wrapper">
            <div className="athena-image-container special-width">
              <img src={athenaLogo} alt="athena poster" />
            </div>
            <div className="athena-text-container">
              <div>
                <div className="athena-header">Athena </div>
                <div className="athena-info-text">
                  Athena is a cutting-edge SAAS-based digital risk management platform that offers a 360-degree view of an organization's brand, reputation, and online risks. Powered by Al and ML algorithms.
                </div>
                <div className="athena-info-text pt-5">
                  Athena helps businesses avoid hacktivists, state-sponsored attacks, and cyber threats like ransomware and crypto-jacking. With proactive and comprehensive Digital Risk Monitoring, Athena empowers organizations to identify, assess, and address online risks across various channels, ensuring a robust cybersecurity strategy and safeguarding their digital assets and reputation.
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="athena-landing-bg" style={{ backgroundImage: `url(${landingBg})` }} >
          <div className="athena-offers">What our product Athena offers?</div>

          <div className="risk-assessment">Digital Risk Monitoring Services</div>
        </div>

        <div className="product-block">

          <div className="product-item" style={{ backgroundImage: `url(${bgBlue})` }} >
            <div className="detail-section">
              <div>
                <div className="item-title">Digital <br />Risk Monitoring</div>
                <div className="item-description text-justify">
                  {/* Athena is a cutting-edge SAAS-based digital risk management platform that offers a 360-degree view of an organization's brand, reputation, and online risks. Powered by Al and ML algorithms, Athena helps businesses avoid hacktivists, state-sponsored attacks, and cyber threats like ransomware and crypto-jacking. With proactive and comprehensive Digital Risk Monitoring, Athena empowers organizations to identify, assess, and address online risks across various channels, ensuring a robust cybersecurity strategy and safeguarding their digital assets and reputation. */}
                  The Digital Risk Monitoring solution provides real-time visibility into potential digital threats and vulnerabilities across various online channels.
                  Leveraging advanced threat intelligence and
                  machine learning, the platform proactively identifies
                  and assesses risks to help organizations stay one
                  step ahead of cyber threats. With continuous
                  monitoring, customizable reports, and incident
                  response support, Athenian Tech's Digital Risk
                  Monitoring empowers businesses to protect their
                  digital assets effectively.
                </div>
                <div className="item-action">
                  <div className="try-now" onClick={directToAthena.bind('', 'Digital Risk Assessment')} >Try Now</div>
                  {/* <div className="request-demo" onClick={openMadalFun} >Request Demo</div> */}
                </div>
              </div>
            </div>

            <div className="image-section">
              <img src={digitalRisk} alt="digital-risk-assessment-image" />
            </div>

          </div>

          <div className="product-item product-switch" style={{ backgroundImage: `url(${bgLightBlue})` }} >
            <div className="detail-section">
              <div>
                {/* <div className="item-title">Social Media &amp; Brand <br /> Risk Monitoring</div> */}
                <div className="item-title">Dark Web Monitoring</div>
                <div className="item-description text-justify">
                  {/* Protect your brand, your hard-earned social presence, and customers across all social media and digital platforms from revenue-draining threats. */}

                  The Dark Web Monitoring solution is designed to track and analyze illegal activities that occur over the hidden dark net. This solution typically focuses on identifying compromised or breached credentials, sensitive information and potential threats to the organization, under study. The platform employed to monitor the dark web employs AI-powered solutions to scan dark web marketplaces, forums and onion websites to identify/locate an organization's data. Athenian Tech's dark web monitoring empowers organizations to protect their brand reputation, intellectual property and threat detection.

                </div>
                <div className="item-action">
                  <div className="try-now" onClick={directToAthena.bind('', 'Dark Web Monitoring')} >Try Now</div>
                  {/* <div className="request-demo" onClick={openMadalFun}>Request Demo</div> */}
                </div>
              </div>
            </div>

            <div className="image-section social-media-image">
              <img src={socialMedia} alt="digital-risk-assessment-image" />
            </div>

          </div>

          {/* <div className="product-item" style={{ backgroundImage: `url(${bgBlue})` }} >
            <div className="detail-section">
              <div>
                <div className="item-title">Red Team <br />Assessment</div>
                <div className="item-description text-justify">
                  Test your security team's effectiveness in dealing with a cyber-attack to identify and mitigate complex security vulnerabilities before an attacker exploits them.
                </div>
                <div className="item-action">
                  <div className="try-now" onClick={directToAthena.bind('', 'Red Team Assessment')} >Try Now</div>
                  <div className="request-demo" onClick={openMadalFun}>Request Demo</div>
                </div>
              </div>
            </div>

            <div className="image-section">
              <img src={redTeam} alt="digital-risk-assessment-image" />
            </div>

          </div> */}

          <div className="product-item product-switch h-auto" style={{ backgroundImage: `url(${bgLightBlue})` }} >
            <div className="detail-section">
              <div>
                <div className="item-title">Supplier Risk <br /> Monitoring</div>
                <div className="item-description text-justify">
                  {/* Identifying the risks that all of your third-party partnerships may entail. Vendors are categorized based on their access to your systems, networks, and data—examining service level agreements (SLAs) to ensure that vendors deliver on their promises. */}

                  The Supplier Risk Monitoring solution ensures the
                  security and compliance of third-party vendors and
                  suppliers. With comprehensive digital footprint
                  assessments, vulnerability scanning, and
                  compliance checks, the platform helps organizations
                  mitigate supply chain risks effectively. By facilitating
                  continuous monitoring, incident response
                  collaboration, and performance analysis, Athenian
                  Tech's Supplier Risk Monitoring enables businesses
                  to manage supplier-related risks and ensure a secure
                  supply chain proactively.

                </div>
                <div className="item-action">
                  <div className="try-now" onClick={directToAthena.bind('', 'Supplier Risk Assessment')} >Try Now</div>
                  {/* <div className="request-demo" onClick={openMadalFun}>Request Demo</div> */}
                </div>
              </div>
            </div>

            <div className="image-section social-media-image">
              <img src={supplierRisk} alt="digital-risk-assessment-image" />
            </div>

          </div>

        </div>

      </div>

      <Modal
        open={modalState}
        onClose={closeReviewMadal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Demo closeReviewMadal={closeReviewMadal} />
      </Modal>
    </>
  );

}

export default Athena;