import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router';
import { Link } from 'react-router-dom'
import { useSnackbar } from "notistack"
import { useSession } from 'react-use-session';

import { AuthServices } from '../../utils/auth';
import { apiErrorHandler } from '../../utils/helper';
import { INVALID_LOGIN } from "../../utils/messages";

import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import logonew from '../../assets/image/logo_new-white-fav.png';
import logoalt from '../../assets/image/illustration.svg';
import illustration from '../../assets/image/illustration.svg';
import logo from '../../assets/image/white-logo.png';
import Checkbox from "@material-ui/core/Checkbox";

const SignInForm = () => {

  const { enqueueSnackbar } = useSnackbar();
  const { save, session } = useSession("cyber-security");
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [reload, setReload] = useState(session);
  const [cookieCheck, setcookieCheck] = useState(false);
  const [loader, setLoader] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false);
  var checkValidEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var checkValidPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[@$!%*?&])[A-Za-zd@$!%*?&]{8,}$/;

  useEffect(() => {
    checkCookie();
  }, [0]);

  useEffect(() => {
    if (session !== null) {

      if (session.is_admin === false && session.is_analyst === false) {
        location.reload();
      } if (session.is_admin === true) {
        history.push('/home');
        location.reload();
      } else if (session.is_analyst === true) {
        history.push('/analyst-home');
        location.reload();
      }

    }
  }, [session]);

  function checkCookie() {
    // const cookie = document.cookie;
    // if (cookie) {
    //   const email = cookie.split(';')[1].split('=')[1];
    //   const password = cookie.split(';')[0].split('=')[1];
    //   var checkValidEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    //   if (checkValidEmailRegex.test(email) == false) {
    //     // login(password, email);
    //   } else {
    //     // login(email, password);
    //   }
    // }
  }

  const handleSubmit = (element) => {
    if (element !== undefined) {
      element.preventDefault();
    }
    login();
  };

  const login = async (cookieEmail, cookiePassword) => {

    setIsSubmitted(true);

    const formData = new FormData();

    if (cookieEmail == undefined && cookiePassword == undefined) {
      formData.append("username", email);
      formData.append("password", password);
      if (email == '' || password == '') {
        return;
      }
    } else {
      formData.append("username", cookieEmail);
      formData.append("password", cookiePassword);
    }

    setLoader(true);
    const authBool = await AuthServices.login(formData);
    setLoader(false);
    if (authBool.status === 200) {
      if (authBool.data.status === true) {


        if (cookieCheck == true) {
          const now = new Date();
          now.setTime(now.getTime() + 1 * 3600 * 1000);
          document.cookie = `username=${email}; expires=${now.toUTCString()};`;
          document.cookie = `password=${password}; expires=${now.toUTCString()};`;
        }

        if (authBool.data.data) {
          save({
            token: authBool.data.data.access,
            refresh: authBool.data.data.refresh,
            user_id: authBool.data.data.user_id,
            is_admin: authBool.data.data.is_admin,
            is_analyst: authBool.data.data.is_analyst
          });

        } else {
          enqueueSnackbar("Your must verify your email before login. An email has been sent by your email.", { variant: "error" });
        }
      } else {
        setLoader(false)
        if (authBool.data.errors && authBool.data.errors.length) {
          const errors = authBool.data.data.errors;
          for (let i = 0; i < errors.length; i++) {
            enqueueSnackbar(errors[i], { variant: "error" });
          }
        } else {
          enqueueSnackbar(INVALID_LOGIN, { variant: "error" });
        }
      }
    } else {
      apiErrorHandler(authBool, enqueueSnackbar);
    }

  };

  function submitionDirect(event) {
    if (event.keyCode == 13) {
      handleSubmit();
    }
  }

  return (
    <>
      <div className="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
        <div className="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
          <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">Sign In</h2>

          <div className="intro-x mt-2 text-gray-500 xl:hidden text-center">A few more clicks to sign in to your account. </div>
          <div className="intro-x mt-8">
            <TextField
              label="Email"
              variant="outlined"
              className="authInputPadding"
              type="email"
              fullWidth
              required
              value={email}
              onKeyDown={submitionDirect}
              onChange={e => setEmail(e.target.value)}
              helperText={
                <>
                  {!checkValidEmailRegex.test(email) && isSubmitted ? (
                    <span className="d-block text-danger fs12px text-left w-100">You must use valid email</span>
                  ) : null}
                </>
              }
            />

            <div className="authInput mt-34px w-100">
              <TextField
                label="Password"
                className="authInputPadding"
                variant="outlined"
                fullWidth
                type="password"
                required
                value={password}
                onKeyDown={submitionDirect}
                onChange={e => setPassword(e.target.value)}
                helperText={
                  <>
                    {password === "" && isSubmitted ? (
                      <span className="d-block text-danger fs12px"> You must use valid password </span>
                    ) : null}
                  </>
                }
              />
            </div>

          </div>
          <div className="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4">
            <div className="flex items-center mr-auto">
              <input id="remember-me" type="checkbox" className="form-check-input border mr-2" />
              <label className="cursor-pointer select-none" htmlFor="remember-me">Remember me</label>
            </div>
            <Link to="/forgot-password" className="authRemember">Forgot Password?</Link>

          </div>
          <div className="intro-x mt-5 xl:mt-8 text-center xl:text-left">

            <button
              className="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
              onClick={handleSubmit}
            >
              {loader == true ? <CircularProgress color="info" size="small" style={{ width: "20px", height: "20px" }} /> : "Login"}
            </button>

            <button
              className="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top"
              onClick={() => history.push("/signup")}
            >
              Sign up
            </button>
          </div>
          <div className="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left">
            By signin up, you agree to our
            <br />
            <a className="text-theme-1 dark:text-theme-10" href="">Terms and Conditions</a> & <a className="text-theme-1 dark:text-theme-10" href="">Privacy Policy</a>
          </div>
        </div>
      </div>
    </>

  );
};

export default SignInForm;