import React, { useState, useEffect } from "react";

import { useSession } from 'react-use-session';
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import CircularProgress from '@material-ui/core/CircularProgress';

import { apiErrorHandler, filterImageUrl } from '../../utils/helper';
import { SubscriptionService } from '../../utils/subscriptions';
import { SUBSCRIPTION_FOR_ALL } from '../../utils/messages';

import './subscription.scss';

const Subscription = (props) => {
  
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useSession("cyber-security");
  const history = useHistory();

  const { setMiddleData } = props;

  const [offers, setOffers] = useState([]);
  const [loader, setLoader] = useState(true);

  const [products, setProucts] = useState([]);

  useEffect(() => {
    checkPrevData();
    fetchSubscriptions();
  }, [0]);

  function checkPrevData() {

    const dataIgot = props.location.state;
    if (!dataIgot) {
      history.push('/');
    }

    let newArrray = [];
    const newOrgin = Object.create(dataIgot.selectedOne);
    for (let item of newOrgin) {
      newArrray.push({
        description: item.description,
        img: item.img,
        index: item.index,
        service_id: item.service_id,
        service_inputformjson: item.service_inputformjson,
        service_name: item.service_name,
        subscription_id: 0
      });
    }

    setProucts(newArrray);

  }

  async function fetchSubscriptions() {
    const apiResponse = await SubscriptionService.getSubscriptions(session.token);
    setLoader(false);
    // console.log('apiResponse', apiResponse);
    if (apiResponse.status === 200) {
      setOffers(apiResponse.data.data);
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  function selectSubs(subscription, toService) {

    // console.log('subscription', subscription);
    // console.log('toService', toService);
    
    let newArrray = [];
    const newOrgin = Object.create(products);
    console.log('newOrgin', newOrgin);
    for (let item of newOrgin) {

      if (toService.service_name == item.service_name) {
        newArrray.push({
          description: item.description,
          img: item.img,
          index: item.index,
          service_id: item.service_id,
          service_inputformjson: item.service_inputformjson,
          service_name: item.service_name,
          subscription_id: subscription.subscription_id,
          title: subscription.title
        });
      } else {
        newArrray.push({
          description: item.description,
          img: item.img,
          index: item.index,
          service_id: item.service_id,
          service_inputformjson: item.service_inputformjson,
          service_name: item.service_name,
          subscription_id: item.subscription_id,
          title: item.title
        });
      }

    }

    setProucts(newArrray);

  }


  function directToForms() {

    try {
      for (let item of products) {
        if (item.subscription_id === 0) {
          enqueueSnackbar(SUBSCRIPTION_FOR_ALL, { variant: "error" });
          customError;
        }
      }
      toSubmition();
    } catch (error) {
      console.log('error', error);
    }

  }

  function toSubmition() {

    history.replace({
      pathname: `/selected-products`,
      state: {
        selectedOne: products
      }
    });

    setMiddleData(products);

  }

  const correspondingDemoReport = (calledWith) => {
    if(calledWith === "Digital Risk Monitoring") return "https://athena-imageapi.s3.amazonaws.com/EBDigitalRiskMonitoring.pdf";
    if(calledWith === "Supplier Risk Monitoring") return "https://athena-imageapi.s3.amazonaws.com/EBSupplierRiskMonitoring.pdf";
    if(calledWith === "Dark Web Monitoring") return "https://athena-imageapi.s3.amazonaws.com/EBSocialMediaAndBrandRiskMonitoring.pdf";
    if(calledWith === "Executive Protection Monitoring") return "https://athena-imageapi.s3.amazonaws.com/EBExecutiveProtectionMonitoring.pdf";
  }

  // console.log('offers offers', offers);
  // console.log('selected offer', selectedOffer);
  // console.log('products', products);

  return (
    <>
      <div className="subscription-wrapper">

        {loader === true ?
          <div className="subscription-loader"> <CircularProgress /> </div>
          :
          <>
            {products.map((service, index) => {
              return (
                <div className="subs-block" key={index}>
                  <div className="subscription-card">
                    <div className="sub-title">{service.service_name}</div>
                  </div>

                  <div className="subs-item-block">

                    {offers.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`subs-item ${service.subscription_id === item.subscription_id ? 'active' : ''} `}
                          onClick={selectSubs.bind('', item, service)}
                        >
                          <div className="image-section">
                            <img src={item.image_url} alt="globe-image" />
                          </div>
                          <div className="subs-title">{item.title}</div>
                          {/* <div className="subs-description">{item.description}</div> */}
                          <div className="subs-action">
                            {/* <a href={filterImageUrl(item.entry)} target="_blank" > */}
                            <a href={correspondingDemoReport(service.service_name)} target="_blank" >
                              <div className="subs-download">Download sample</div>
                            </a>
                          </div>
                        </div>
                      )
                    })}

                  </div>
                </div>
              );
            })}
          </>
        }

        <div className="next-hit-wrapper">
          {loader === false ?
            <div className="next-page" onClick={directToForms} >Launch</div>
            : null}
        </div>

      </div>
    </>
  );
}

export default Subscription;
