import React, { useEffect, useState } from "react";

import { useSession } from "react-use-session";
import { useSnackbar } from "notistack";

import CircularProgress from "@material-ui/core/CircularProgress";

import NotificationTable from "./NotificationTable";
import { UserService } from "../../utils/userServices";
import { API_WRONG } from "../../utils/messages";

import './notificatation.scss';

function Notification({
  setPageTitleOne,
  setPageTitleTwo,
  setPageTitle,
  setPageTitleThree,
  setCommonPageTitleCheck,
  sethitNotification
}) {


  const { session } = useSession("cyber-security");
  const { enqueueSnackbar } = useSnackbar();

  const [notificationsList, setnotificationsList] = useState([]);

  const [loading, setLoading] = useState(false);

  const [paginationData, setPaginationData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  useEffect(() => {
    setPageTitleOne("Home");
    setPageTitleTwo("Notifications");
    setPageTitleThree("");
    setPageTitle("");
    setCommonPageTitleCheck(true);
  }, []);

  useEffect(() => {
    getNotification();
  }, [rowsPerPage, page]);

  async function getNotification() {
    setLoading(true);
    const notiData = await UserService.getUserNotification(session.user_id, rowsPerPage, (page+1), session.token);
    setLoading(false);
    if (notiData.status === 200) {
      setnotificationsList(notiData.data.data.all_list);
      setPaginationData(notiData.data.data.meta_data);
    } else {
      enqueueSnackbar(API_WRONG, { variant: "error" });
    }
  };


  return (
    <div className="notificatation-wrapper">
      {loading !== true ? (
        <>
          {paginationData !== null && notificationsList.length > 0 ? (
            <div className="notification-table">
              <NotificationTable
                rows={notificationsList}
                sethitNotification={sethitNotification}
                paginationData={paginationData}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            </div>
          ) : null}
        </>
      ) : (
        <div className="w-100 hcenter"><CircularProgress /></div>
      )}
    </div>
  );
}

export default Notification;
