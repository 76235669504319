import React from 'react';

import facebook from '../assets/image/facebook.svg';
import linkedin from '../assets/image/linkedin.svg';
import twitter from '../assets/image/twitter.svg';
import instagram from '../assets/image/instagram.png';

import { convertDate } from '../utils/helper';

const ReviewViewer = ({ data, formData }) => {
  return (
    <>
      {Object.keys(data).map((item, index) => {

        return (
          <>
            {data[item].title === 'Schedule Launch' ?

              <div className="w-100 border-bottom-review border-top-review d-flex reviewBg" key={index} >
                <div className="w-25 border-right text-left fs18px p-1">
                  {data[item].title}
                </div>
                <div className="w-75 text-left vcenter">
                  <div className=" w-100 fs18px ps-1">
                    {data[item].fields.map((obj, indexTwo) => {
                      console.log('will see tomorrow', obj.placeholder);
                      console.log('will see tomorrow', formData[obj.placeholder]);
                      return (
                        <>

                          {obj.placeholder == 'Start Date *' ?
                            <>
                              <span className="px-05 timeBorder ">Start Time </span>
                              <span className="px-05"> { formData[obj.placeholder] ? convertDate(formData[obj.placeholder]) : formData[obj.placeholder] } </span>
                            </>
                            : null}

                          {obj.placeholder == 'End Date' ?
                            <>
                              <span className="px-05 timeBorder">End Time </span>
                              {/* <span className="px-05"> { convertDate(formData[obj.placeholder]) } </span> */}
                              <span className="px-05"> { formData[obj.placeholder] ? convertDate(formData[obj.placeholder]) : formData[obj.placeholder] } </span>
                            </>
                            : null}

                        </>
                      )
                    })}
                  </div>
                </div>
              </div>

              :
              <>

                {data[item].title === 'Email addresses' ?


                  <div className="w-100 border-bottom-review d-flex reviewBg" key={index} >
                    <div className="w-25 border-right text-left fs18px p-1">
                      {data[item].title}
                    </div>
                    <div className="w-75 text-left vcenter">
                      <div className=" w-100 fs18px ps-1">
                        {data[item].fields.map((obj, indexTwo) => {
                          // console.log('will see tomorrow and againa', obj);
                          return (
                            <>
                              {obj.fields.map((val, itemThree) => {
                                return (
                                  <span className="px-05"> {formData[val.placeholder]} </span>
                                );
                              })}
                            </>
                          )
                        })}
                      </div>
                    </div>
                  </div>

                  :

                  <>

                    {data[item].title === 'Domain Social Media Presence' ?


                      <div className="w-100 d-flex reviewBg" key={index} >
                        <div className="w-25 border-right text-left fs18px p-1">
                          {data[item].title}
                        </div>
                        <div className="w-75 text-left">
                          <div className=" w-100 fs18px">
                            {data[item].fields.map((obj, indexTwo) => {
                              return (
                                <>
                                  {obj.fields.map((val, itemThree) => {
                                    // console.log('will see tomorrow and againa', indexTwo);
                                    return (
                                      <>
                                        {val.placeholder !== "Select social media platform 1"
                                          && val.placeholder !== "Select social media platform 2"
                                          && val.placeholder !== "Select social media platform 3"
                                          && val.placeholder !== "Select social media platform 4"
                                          ?
                                          <div className={` w-100 p-05 fs18px ${indexTwo !== 0 ? ' border-top-review' : ''} `}>
                                            <span className="px-05 vcenter">
                                              {val.placeholder == "User Name 1" ?
                                                <img src={facebook} className="px-05 " />
                                                : null}
                                              {val.placeholder == "User Name 2" ?
                                                <img src={twitter} className="px-05 " />
                                                : null}
                                              {val.placeholder == "User Name 3" ?
                                                <img src={linkedin} className="px-05 " />
                                                : null}
                                              {val.placeholder == "User Name 4" ?
                                                <img src={instagram} className="px-05" style={{ width: "2em" }} />
                                                : null}
                                              {formData[val.placeholder]}
                                            </span>
                                          </div>
                                          : null}
                                      </>
                                    );
                                  })}
                                </>
                              )
                            })}
                          </div>
                        </div>
                      </div>

                      :
                      <>

                        {data[item].title === 'Picture' ?

                          <div className="w-100 border-bottom-review d-flex reviewBg" key={index}>
                            <div className="w-25 border-right text-left p-1 fs18px">
                              {data[item].title}
                            </div>
                            <div className="w-75 text-left">
                              {data[item].fields.map((obj, indexTwo) => {
                                // console.log('will see tomorrow', obj.placeholder);
                                return (
                                  <>
                                    {obj.fields ?
                                      <>
                                        {obj.fields.map((val, itemThree) => {
                                          return (
                                            <div className={`border-top-review w-100 p-1 fs18px `}>
                                              <img src={formData[val.placeholder]} />
                                            </div>
                                          );
                                        })}
                                      </>
                                      :
                                      <div className={` w-100 p-1 fs18px ${indexTwo !== 0 ? ' border-top-review' : ''} `}>
                                        {/* {formData[obj.placeholder]} */}
                                        <img src={formData[obj.placeholder]} />
                                      </div>
                                    }
                                  </>
                                )
                              })}
                            </div>
                          </div>

                          :

                          <>

                            {data[item].title === "Upload list of IP's" || data[item].title === "Upload Audit Report" ?

                              <div className="w-100 border-bottom-review d-flex reviewBg" key={index}>
                                <div className="w-25 border-right text-left p-1 fs18px">
                                  {data[item].title}
                                </div>
                                <div className="w-75 text-left">
                                  {data[item].fields.map((obj, indexTwo) => {
                                    // console.log('will see tomorrow', 
                                    //   obj.placeholder, 
                                    //   formData[obj.placeholder],
                                    //   formData[obj.placeholder][1]
                                    // );
                                    return (
                                      <>
                                        {obj.fields ?
                                          <>
                                            {obj.fields.map((val, itemThree) => {
                                              return (
                                                <div className={`border-top-review w-100 p-1 fs18px `}>
                                                  {formData[val.placeholder]}
                                                  {/* {formData[val.placeholder][1].split('.').pop()} */}
                                                </div>
                                              );
                                            })}
                                          </>
                                          :
                                          <div className={` w-100 p-1 fs18px ${indexTwo !== 0 ? ' border-top-review' : ''} `}>
                                            {/* {formData[obj.placeholder]} */}
                                            {
                                              typeof (formData[obj.placeholder]) == "object" ?
                                                formData[obj.placeholder][1]
                                                :
                                                formData[obj.placeholder].split(',').pop()
                                            }
                                          </div>
                                        }
                                      </>
                                    )
                                  })}
                                </div>
                              </div>
                              :

                              <div className="w-100 border-bottom-review d-flex reviewBg" key={index}>
                                <div className="w-25 border-right text-left p-1 fs18px">
                                  {data[item].title}
                                </div>
                                <div className="w-75 text-left">
                                  {data[item].fields.map((obj, indexTwo) => {
                                    // console.log('will see tomorrow', obj.placeholder);
                                    return (
                                      <>
                                        {obj.fields ?
                                          <>
                                            {obj.fields.map((val, itemThree) => {
                                              return (
                                                <div className={`border-top-review w-100 p-1 fs18px `}>
                                                  {formData[val.placeholder]}
                                                </div>
                                              );
                                            })}
                                          </>
                                          :
                                          <div className={` w-100 p-1 fs18px ${indexTwo !== 0 ? ' border-top-review' : ''} `}>
                                            {formData[obj.placeholder]}
                                          </div>
                                        }
                                      </>
                                    )
                                  })}
                                </div>
                              </div>

                            }

                          </>

                        }
                      </>
                    }
                  </>

                }
              </>

            }
          </>
        );
      })}

    </>

  );
}

export default ReviewViewer;