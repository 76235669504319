import { combineReducers } from "redux";

import notification from "./notification.reducer";
import sidebar from "./sidebar.reducer";
import title from "./title.reducer";
import counter from "./counter.reducer";

// import storage from "redux-persist/lib/storage";
// import { persistReducer } from "redux-persist";

// const persistConfig = {
//   key:"root",
//   storage,
//   whitelist:['influencerData']
// }

const rootReducer = combineReducers({
  title: title,
  notification: notification,
  sidebar: sidebar,
  counter: counter
})

// export default persistReducer(persistConfig, rootReducer);
export default rootReducer;