import React, { useState, useEffect } from 'react';
import { Switch, Route } from "react-router-dom";
import { useSession } from 'react-use-session';
import { useHistory } from 'react-router';
import Loadable from "react-loadable";

import AppBarComponent from '../../Components/AdminComponents/AppBar/AppBarComponent';
import Sidebar from '../../Components/AdminComponents/Sidebar/Sidebar';

import '../Home/home.scss';
import '../../sass/pagination.scss';

const AdminRoutes = () => {

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { session } = useSession("cyber-security");
  const history = useHistory();

  useEffect(() => {
    if (session !== null) {
      if (session.is_admin === false && session.is_analyst === false) {
        location.reload();
      } else if (session.is_analyst === true) {
        history.push('/analyst-home');
        location.reload();
      }
    }
  }, [session]);

  function Loading({ error }) {

    function reload() {
      window.location.reload();
    }

    if (error) {
      return (
        <div className="reload-page">
          <div className="align-middle">
            <p>You have new changes to be loaded.</p>
            <button className="button blue" onClick={reload}>
              RELOAD
            </button>
          </div>
        </div>
      );
    } else {
      return <div className="component-loading"></div>;
    }

  }

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => <Component {...props} />}
    />
  );

  const DynamicLoadablePage = (page, path) => {
    return Loadable({
      loader: () => import(`../Admin/${path}/${page}`),
      loading: Loading
    });
  };

  return (
    <>

      <div className="home-container">

        <div className="welcome-text">

        </div>

          <div className="sidebar-wrapper">
            <div className={`sidebar-fixed ${isDrawerOpen === true ? 'close' : 'open'} `}>
              <Sidebar toggleDrawer={(val) => setIsDrawerOpen(val)} />
            </div>
          </div>

          <div className={`page-wrapper ${isDrawerOpen === true ? 'close' : 'open'} `}>

            <div className="content-area">

              <div className="appbar-wrapper-section">
                <AppBarComponent />
              </div>

              <Switch>
                <PrivateRoute
                  exact
                  path="/home"
                  component={DynamicLoadablePage("Home", "Home")}
                />

                <PrivateRoute
                  exact
                  path="/analyst"
                  component={DynamicLoadablePage("Analyst", "Analyst")}
                />

                <PrivateRoute
                  exact
                  path="/users"
                  component={DynamicLoadablePage("Users", "Users")}
                />

                <PrivateRoute
                  exact
                  path="/orders"
                  component={DynamicLoadablePage("Orders", "Orders")}
                />

                <PrivateRoute
                  exact
                  path="/profile-admin"
                  component={DynamicLoadablePage("AdminProfile", "AdminProfile")}
                />

                <PrivateRoute
                  exact
                  path="/files"
                  component={DynamicLoadablePage("Files", "Files")}
                />

                <PrivateRoute
                  exact
                  path="/inbox"
                  component={DynamicLoadablePage("AdminInbox", "AdminInbox")}
                />

                <PrivateRoute
                  exact
                  path="/feedbacks"
                  component={DynamicLoadablePage("Feedbacks", "Feedbacks")}
                />

                <PrivateRoute
                  exact
                  path="/chat-admin"
                  component={DynamicLoadablePage("Chat", "Chat")}
                />

                <PrivateRoute
                  exact
                  path="/communication"
                  component={DynamicLoadablePage("Email", "Email")}
                />

                <PrivateRoute
                  exact
                  path="/ticket-detail"
                  component={DynamicLoadablePage("TicketDetails", "TicketDetails")}
                />

                <PrivateRoute
                  exact
                  path="/demo-request"
                  component={DynamicLoadablePage("Demo", "Demo")}
                />


              </Switch>

            </div>
          </div>
      </div >
    </>
  );
}

export default AdminRoutes;