import React from "react";

import { useSession } from 'react-use-session';
import { useHistory } from 'react-router';

import rocket from '../../assets/image/rocket.png';


const NoMatch = () => {

  const { session } = useSession("cyber-security");
  const history = useHistory();

  function directTo() {

    if (session !== null) {

      if (session.is_admin === true) {
        history.push('/home');
        location.reload();
      } else if (session.is_analyst === true) {
        history.push('/analyst-home');
        location.reload();
      } else {
        window.location.replace(`https://${window.location.host}/`)
      }


    } else {
      window.location.replace(`https://${window.location.host}/`)
    }

  }

  return (
    <div className="bg-blue fd-row vhcenter h-screen">
      <div className="pic m-1 p-2">
        <img src={rocket} alt="rocket-logo" />
      </div>
      <div className="">
        <div className="text-404">404</div>
        <h2 className="text-oops" style={{ height: "60px" }}>Oops. This page has gone missing.</h2>
        <p className="text-oops-below">You may have mistyped the address or the page may have moved</p>
        <div className="back-to-home" onClick={directTo}> Back to Home</div>
      </div>
    </div>
  );
};

export default NoMatch;
