import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory } from 'react-router';
import { useSnackbar } from "notistack";
import { AuthServices } from '../../utils/auth';
import { API_WRONG } from "../../utils/messages";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton"
import PasswordMeter from './PasswordMeter';
import ReactTooltip from "react-tooltip";
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiErrorHandler } from '../../utils/helper';

const SignUpForm = () => {

  const [password, setPassword] = useState('');
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [submitted, setsubmitted] = useState(false);
  const [loader, setloader] = useState(false);
  const [strongPassword, setstrongPassword] = useState(false);
  const yesterday = new Date();
  yesterday.setDate(new Date().getDate() - 1);

  const [regFi, setregFi] = useState({
    name: { val: "", error: "" },
    lastName: { val: "", error: "" },
    dob: yesterday,
    type: { val: "Individual", error: "" },
    organization: { val: "", error: "" },
    email: { val: "", error: "" },
    phone: { val: "", error: "" },
    password: { val: "", error: "", visible: false },
    confirmPassword: { val: "", error: "", visible: false },
    checkbox: false,
  });

  const regFields = (e) => {
    e.preventDefault()
    if (e.target && e.target.name && e.target.name === "password") {
      // console.log("targetname ", e.target.name);
      setregFi({
        ...regFi,
        [e.target.name]: {
          val: e.target.value,
          error: "",
          visible: regFi.password.visible,
        },
      });
    } else if (
      e.target &&
      e.target.name &&
      e.target.name === "confirmPassword"
    ) {
      setregFi({
        ...regFi,
        [e.target.name]: {
          val: e.target.value,
          error: "",
          visible: regFi.confirmPassword.visible,
        },
      });
    } else if (e.target) {
      // console.log("targetname ", e.target.name);
      setregFi({
        ...regFi,
        [e.target.name]: { val: e.target.value, error: "" },
      });
    } else {
      setregFi({ ...regFi, dob: e });
    }
  };

  // console.log("strongPassword", strongPassword);

  var checkSpecialCharacterRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  const checkForNumber = (myString) => {
    return /\d/.test(myString);
  };

  var checkValidEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var checkCharacterInStringRegex = /[a-zA-z]/i;
  var singleSpace = /\s\s+/g;
  var checkValidPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[@$!%*?&])[A-Za-zd@$!%*?&]{8,}$/;

  const checkForSpace = (myString) => {
    return /\s\s+/g.test(myString);
  };

  const applyRegistraion = async () => {
    setsubmitted(true);

    if (
      regFi.name.val.length === 0 ||
      regFi.name.val.length < 4 ||
      singleSpace.test(regFi.name.val) ||
      regFi.name.val.length > 30 ||
      checkForNumber(regFi.name.val) ||
      checkSpecialCharacterRegex.test(regFi.name.val)
    ) {
      return;
    }

    if (regFi.checkbox !== true) {
      return;
    }


    if (
      regFi.email.val.length === 0 ||
      !checkValidEmailRegex.test(regFi.email.val)
    ) {
      return;
    }

    if (
      regFi.phone.val.length < 10 ||
      regFi.phone.val.length > 10 ||
      checkSpecialCharacterRegex.test(regFi.phone.val) ||
      checkCharacterInStringRegex.test(regFi.phone.val)
    ) {
      return;
    }

    if (regFi.password.val.length < 7 || regFi.password.val.length > 45) {
      return;
    }



    if (regFi.confirmPassword.val !== regFi.password.val) {
      return;
    }

    if (strongPassword == false) {
      return;
    }

    setloader(true);
    const regParams = {
      first_name: regFi.name.val,
      email: regFi.email.val,
      password: regFi.password.val,
      password2: regFi.confirmPassword.val,
      last_name: regFi.lastName.val,
      profile: {
        organization_type: '',
        organization_name: '',
        date_of_birth: '',
        phone_number: regFi.phone.val,
      },
    };

    // console.log("final data to send to api ", regParams);


    const registrationBool = await AuthServices.registraion(regParams);

    if (
      registrationBool &&
      registrationBool.data &&
      registrationBool.data.status === true
    ) {
      // console.log("registrationBool ", registrationBool);
      enqueueSnackbar(registrationBool.data.message, { variant: "success" });
      history.push("/");
    } else {
      setloader(false);

      apiErrorHandler(registrationBool, enqueueSnackbar);
    }

  };


  const passwordVisibilityToggle = () => {
    setregFi({
      ...regFi,
      password: {
        val: regFi.password.val,
        error: "",
        visible: !regFi.password.visible,
      },
    });
  };

  const confirmPasswordVisibilityToggle = () => {
    setregFi({
      ...regFi,
      confirmPassword: {
        val: regFi.confirmPassword.val,
        error: "",
        visible: !regFi.confirmPassword.visible,
      },
    });
  };

  function isGood(password) {
    var password_strength = document.getElementById("password-text");

    //TextBox left blank.
    if (password.length == 0) {
      password_strength.innerHTML = "";
      return;
    }

    //Regular Expressions.
    var regex = new Array();
    regex.push("[A-Z]"); //Uppercase Alphabet.
    regex.push("[a-z]"); //Lowercase Alphabet.
    regex.push("[0-9]"); //Digit.
    regex.push("[$@$!%*#?&]"); //Special Character.

    var passed = 0;

    //Validate for each Regular Expression.
    for (var i = 0; i < regex.length; i++) {
      if (new RegExp(regex[i]).test(password)) {
        passed++;
      }
    }

    //Display status.
    var strength = "";
    switch (passed) {
      case 0:
      case 1:
      case 2:
        strength = "<small class='progress-bar bg-weak' style='width: 40%'>Weak</small>";
        break;
      case 3:
        strength = "<small class='progress-bar bg-moderate' style='width: 60%'>Medium</small>";
        break;
      case 4:
        strength = "<small class='progress-bar bg-strong' style='width: 100%'>Strong</small>";
        break;
    }
    password_strength.innerHTML = strength;
  }

  function forSubmition(event) {
    event.preventDefault();
  }


  function registerDirect(event) {
    if (event.keyCode == 13) {
      applyRegistraion();
    }
  }
  return (
    <>
      <div className="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
        <div className="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
          <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
            Sign Up
          </h2>
          <div className="intro-x mt-2 text-gray-500 dark:text-gray-500 xl:hidden text-center"></div>

          <div className="intro-x mt-8">

            <div className="authInput">
              <TextField
                label="First Name"
                variant="outlined"
                className="authInputPadding"
                required
                name="name"
                onKeyDown={registerDirect}
                fullWidth
                inputProps={{ maxLength: 250 }}
                value={regFi.name.val}
                onChange={regFields}
                error={
                  regFi.name.val.length < 4 ||
                    regFi.name.val.length > 30 ||
                    checkForNumber(regFi.name.val) ||
                    singleSpace.test(regFi.name.val) ||
                    checkSpecialCharacterRegex.test(regFi.name.val)
                    ? submitted === true
                      ? true
                      : false
                    : false
                }
                helperText={
                  <>
                    {checkSpecialCharacterRegex.test(regFi.name.val) &&
                      submitted && (
                        <span className="d-block text-danger fs12px">
                          {" "}
                          Name can&apos;t contain any special character.
                        </span>
                      )}
                    {checkForNumber(regFi.name.val) && submitted && (
                      <span className="d-block text-danger fs12px">
                        {" "}
                        Name can&apos;t contain numeric value.
                      </span>
                    )}
                    {regFi.name.val.length < 4 && submitted && (
                      <span className="d-block text-danger fs12px">
                        {" "}
                        Name must be more than 4 characters.
                      </span>
                    )}
                    {regFi.name.val.length > 30 && submitted && (
                      <span className="d-block text-danger fs12px">
                        {" "}
                        Only 30 characters are allowed.
                      </span>
                    )}
                    {checkForSpace(regFi.name.val) && submitted && (
                      <span className="d-block text-danger fs12px">
                        {" "}
                        Only single space is allowed between characters.
                      </span>
                    )}
                  </>
                }
              />
            </div>

            <div className="authInput">
              <TextField
                label="Last Name"
                name="lastName"
                variant="outlined"
                className="authInputPadding mt-1"
                fullWidth
                onKeyDown={registerDirect}
                value={regFi.lastName.val}
                // onChange={e => setLastName(e.target.value)}
                onChange={regFields}
              />
            </div>

            <div className="authInput">
              <TextField
                label="Email"
                fullWidth
                variant="outlined"
                className="authInputPadding"
                type="email"
                onKeyDown={registerDirect}
                name="email"
                required
                autoComplete="new-email"
                inputProps={{ maxLength: 250 }}
                value={regFi.email.val}
                onChange={regFields}
                error={
                  regFi.email.val.length === 0 ||
                    !checkValidEmailRegex.test(regFi.email.val)
                    ? submitted === true
                      ? true
                      : false
                    : false
                }
                helperText={
                  <>
                    {regFi.email.val.length === 0 && submitted && (
                      <span className="d-block text-danger fs8px">
                        Please enter e-mail address
                      </span>
                    )}
                    {regFi.email.val.length > 0 &&
                      !checkValidEmailRegex.test(regFi.email.val) &&
                      submitted && (
                        <span className="d-block text-danger fs8px">
                          Please enter valid e-mail address
                        </span>
                      )}
                  </>
                }
              />
            </div>

            <div className="authInput">
              <TextField
                label="Phone Number"
                fullWidth
                type="number"
                onKeyDown={registerDirect}
                variant="outlined"
                className="authInputPadding"
                required
                name="phone"
                value={regFi.phone.val}
                inputProps={{ maxLength: 10 }}
                onChange={regFields}
                error={
                  regFi.phone.val.length < 10 ||
                    regFi.phone.val.length > 10 ||
                    checkSpecialCharacterRegex.test(regFi.phone.val) ||
                    checkCharacterInStringRegex.test(regFi.phone.val)
                    ? submitted === true
                      ? true
                      : false
                    : false
                }
                helperText={
                  <>
                    {regFi.phone.val.length < 10 && submitted && (
                      <span className="d-block text-danger fs8px">
                        Phone number must contain 10 digits
                      </span>
                    )}
                    {regFi.phone.val.length > 10 && submitted && (
                      <>
                        <br />
                        <span className="d-block text-danger fs8px">
                          Phone number must contain 10 digits
                        </span>
                      </>
                    )}
                    {checkSpecialCharacterRegex.test(regFi.phone.val) &&
                      submitted && (
                        <>
                          <br />
                          <span className="d-block text-danger fs8px">
                            Phone number can&apos;t contain any special character
                          </span>
                        </>
                      )}
                    {checkCharacterInStringRegex.test(regFi.phone.val) &&
                      submitted && (
                        <>
                          <br />
                          <span className="d-block text-danger fs8px">
                            Phone number can&apos;t contain any alpha character
                          </span>
                        </>
                      )}
                  </>
                }
              />
            </div>

            <div className="authInput">
              <TextField
                onKeyDown={registerDirect}
                label="Password"
                fullWidth
                className="authInputPadding"
                variant="outlined"
                required
                name="password"
                type={regFi.password.visible ? "text" : "password"}
                value={regFi.password.val}
                autoComplete="new-password"
                inputProps={{ maxLength: 30 }}
                onChange={regFields}
                onKeyUp={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={passwordVisibilityToggle}
                      >
                        {regFi.password.visible ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={
                  regFi.password.val.length < 4 ||
                    strongPassword == false ||
                    regFi.password.val.length > 30
                    ? submitted === true
                      ? true
                      : false
                    : false
                }
                helperText={
                  <>
                    {regFi.password.val.length < 5 && submitted && (
                      <span className="d-block text-danger fs8px">
                        Password should be atleast length of 8 characters.
                      </span>
                    )}
                    {regFi.password.val.length > 30 && submitted && (
                      <span className="d-block text-danger fs8px">
                        Password more then 45 character is not allowed.
                      </span>
                    )}
                    {strongPassword == false && submitted && (
                      <span className="d-block text-danger fs8px"> Password is not secure. </span>
                    )}
                  </>
                }
              />
            </div>

            {!password ? "" : <PasswordMeter password={password} passwordPower={setstrongPassword} />}
            <ReactTooltip id="secure-password" type='dark' place='top' effect='solid' multiline={true}  >
              <div className=" text-dark fs12px textDark" style={{ width: "20em" }}>Password should be Minimum of 8 Characters(min. 1 special character like @, #, $, !, &, *), Minimum 1 Capital letter, Minimum 1 integer and  Maximum of 45 characters </div>
            </ReactTooltip>
            <div data-tip data-for="secure-password" >
              <div className="securePwd">What is a secure password? </div>
            </div>

            <div className="authInput">
              <TextField
                label="Confirm Password"
                fullWidth
                onKeyDown={registerDirect}
                className="authInputPadding"
                variant="outlined"
                required
                placeholder="Confirm Password"
                type={regFi.confirmPassword.visible ? "text" : "password"}
                inputProps={{ maxLength: 30 }}
                autoComplete="confirm-password"
                name="confirmPassword"
                value={regFi.confirmPassword.val}
                onChange={regFields}
                error={
                  regFi.confirmPassword.val !== regFi.password.val
                    ? submitted === true
                      ? true
                      : false
                    : false
                }
                helperText={
                  <>
                    {regFi.password.val !== regFi.confirmPassword.val &&
                      submitted && (
                        <span className="d-block text-danger fs8px">
                          Passwords do not match.
                        </span>
                      )}
                  </>
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={confirmPasswordVisibilityToggle}
                      >
                        {regFi.confirmPassword.visible ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="vcenter authagree">
              <div>
                <Checkbox
                  checked={regFi.checkbox}
                  className="auth-agree-checkbox"
                  color="default"
                  onChange={() =>
                    setregFi({ ...regFi, checkbox: !regFi.checkbox })
                  }
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>
              <div className="authAgreeText">
                I agree to the Athenian Tech <span className="authtncheighlight">Privacy Policy</span>
              </div>
            </div>

            {regFi.checkbox == false && submitted == true ? (
              <span className="d-block text-danger fs8px">
                You have to agree to the terms and conditions.
              </span>
            ) : null}

          </div>

          <div className="intro-x mt-5 xl:mt-8 text-center xl:text-left">

            <button
              className="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
              onClick={applyRegistraion}
            >
              {loader == true ? <CircularProgress color="info" size="small" style={{ width: "20px", height: "20px" }} /> : "Register"}
            </button>

            <button
              onClick={() => history.push("/")}
              className="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top"
            >Sign in</button>

          </div>

        </div>
      </div>
    </>
  );
};

export default SignUpForm;