import React, { useEffect, useState } from "react";

import { useSnackbar } from "notistack";

import { IMAGE_SIZE_2MB, FILE_FORMAT, ALLOWED_DOCS_FILES } from "../../utils/messages";
import { extractFileName } from '../../utils/helper';

function UploadButton({
  label,
  placeholder,
  width,
  formData,
  setFormData,
  disabled,
  dataEntries,
  setDataEntries,
  setisFileSelected,
  documentId
}) {

  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState(null);

  let dataRequiredFromLocalStorage = JSON.parse(localStorage.getItem("dataEntriesInLocal"));

  async function addDocument(file, id) {

    // console.log('file original details ', file);

    const extension = file.name.split('.').pop();

    setisFileSelected(true);
    if (file.size < 2e+6) {
      setFile(file);
    } else {
      enqueueSnackbar(IMAGE_SIZE_2MB, { variant: "error" });
    }

  };

  useEffect(() => {
    if (file !== null) {

      let blob, fileReader = new FileReader();

      blob = new Blob([file]);

      fileReader.readAsDataURL(blob);

      fileReader.onload = function (evt) {
        const result = evt.target.result;

        const obj = { ...formData, [placeholder]: [result, file.name, file.type] };
        setFormData(obj);

        setDataEntries(obj);
        const objForImage = { ...dataRequiredFromLocalStorage, [placeholder]: [result, file.name, file.type] };
        localStorage.setItem("dataEntriesInLocal", JSON.stringify(objForImage));
        // console.log('will see', result);
      };

    }
  }, [file]);

  return (
    <>

      {file === null ?
        <>
          {dataRequiredFromLocalStorage[placeholder] ?
            extractFileName(dataRequiredFromLocalStorage[placeholder])
            : null}
        </>
        : file.name}

      <div className="uploadButtonCss">

        <label className="vhcenter" htmlFor={label} >Upload list of IP's</label>

        <input
          type="file"
          name={label}
          id={label}
          placeholder={placeholder}
          accept={ALLOWED_DOCS_FILES}
          className="h38px br6px vcenter px-1 mt-1 jc-fs d-none"
          onChange={(e) => addDocument(e.target.files[0], e.target)}
        />
      </div>
    </>
  );
}

export default UploadButton;
