import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { useSession } from 'react-use-session';

import { apiErrorHandler } from '../../utils/helper';
import { ChatService } from '../../utils/chat';

import atLogoSmall from "../../assets/image/logo_new-white.png";
import homeImage from '../../assets/image/home-white.png';
import homeImageBlack from '../../assets/image/homeSide.png';
import productImage from '../../assets/image/product-white.png';
import productImageBlack from '../../assets/image/product.png';
import profileImage from '../../assets/image/profile-white.png';
import dashboardImage from '../../assets/image/dashboard-white.png';
import dashboardImageBlack from '../../assets/image/dashboard.png';
import inboxImage from '../../assets/image/inbox-white.png';
import inboxImageBlack from '../../assets/image/inbox-black.png';
import profileImageBlack from '../../assets/image/edit-black.png';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import collapseLogo from '../../assets/image/logo_new-white-fav.png';

// import menu from '../../assets/image/menu.svg';
// import atLogo from "../../assets/image/logo_new-white.png";

import './sidebar.scss';

const Sidebar = (props) => {

  const { toggleDrawer, chatSidebar, setChatSidebar } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useSession("cyber-security");

  const [open, setOpen] = useState(false);
  const [borderControl, setborderControl] = useState("home");
  
  const [unreadCount, setUnreadCount] = useState(0);
  
  const currentPath = history.location.pathname;

  const toggleMenu = () => {
    toggleDrawer(!open);
    setOpen(!open);
  };

  function reRoute(location, activeMenu) {
    history.push(location);
    setborderControl(activeMenu);
  }

  useEffect(() => {
    if (chatSidebar === true) {
      setborderControl('chat');
      setChatSidebar(false);
    }
  }, [chatSidebar]);


  useEffect(() => {
    const currentUrl = history.location.pathname;
    if (currentUrl.length === 1) {
      setborderControl("home");
    } else {
      setborderControl(history.location.pathname.substring(1));
    }
  }, [history.location.pathname]);


  function directAndToggle() {
    // history.push('/');
    toggleMenu();
  }
  
  useEffect(() => {
    checkForNewMsg();
    setInterval(function () { checkForNewMsg(); }, 5000);
  }, [0]);

  async function checkForNewMsg() {
    const apiResponse = await ChatService.getNewMsgCount(session.user_id, session.token);
    if (apiResponse.status === 200) {
      setUnreadCount(apiResponse.data.data.newMessages);
    } else {
      apiErrorHandler(apiResponse, enqueueSnackbar);
    }
  }

  return (
    <>

      <div className="sidebar-header-section">

        <div className="sidebar-header-wrapper">
          {open === true ?
            <img
              className="sidebar-logo collapsed-view"
              src={collapseLogo}
              onClick={directAndToggle}
            />
            :
            <img
              className="sidebar-logo"
              src={atLogoSmall}
              onClick={directAndToggle}
            />
          }
        </div>
      </div>

      <div className="sidebar-item-wrapper">

        <div className={`menu-items disabled ${borderControl === "home" ? 'borderless-bottom' : ''} `}>
          <div className="item-image d-none">
            <img src={homeImage} alt="Home icon" className="image-white" />
            <img src={homeImageBlack} alt="Home icon" className="image-black" />
          </div>
          <div className="item-name"></div>
        </div>

        <Tooltip
          title="Home"
          arrow
          TransitionComponent={Zoom}
        >
          <div
            className={`menu-items 
            ${currentPath === '/' ? 'active' : ''} 
            ${borderControl === 'notification' ? 'borderless-bottom' : ''} 
            `}
            onClick={reRoute.bind('', "/", "home")}
          >
            <div className="menu-items-child">
              <div className="item-image">
                <img src={homeImage} alt="Home icon" className="image-white" />
                <img src={homeImageBlack} alt="Home icon" className="image-black" />
              </div>
              <div className="item-name">Home</div>
            </div>
          </div>
        </Tooltip>

        <Tooltip
          title="Inbox"
          arrow
          TransitionComponent={Zoom}
        >
          <div
            className={`menu-items 
              ${borderControl === "home" ? 'borderless-top' : ''} 
              ${borderControl === "chat" ? 'borderless-bottom' : ''} 
              ${currentPath === '/notification' ? 'active' : ''} 
            `}
            onClick={reRoute.bind('', "/notification", "notification")}
          >
            <div className="menu-items-child">
              <div className="item-image">
                <img src={inboxImage} alt="Inbox icon" className="image-white" />
                <img src={inboxImageBlack} alt="Inbox icon" className="image-black" />
              </div>
              <div className="item-name">Inbox</div>
            </div>
          </div>
        </Tooltip>

        <Tooltip
          title="Live Chat"
          arrow
          TransitionComponent={Zoom}
        >
          <div
            className={`menu-items 
            ${currentPath === '/chat' ? 'active' : ''} 
            ${borderControl === 'notification' ? 'borderless-top' : ''} 
            ${borderControl === 'product' ? 'borderless-bottom' : ''} 
            `}
            onClick={reRoute.bind('', "/chat", "chat")}
          >
            <div className="menu-items-child">
              <div className="item-image">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 image-white"
                  fill="#ffffff"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"></path>
                </svg>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 image-black"
                  fill="#000000"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"></path>
                </svg>

              </div>

              <div className="item-name">
                Chat
                {unreadCount > 0 ?
                  <sup className="unread-count"> {unreadCount} </sup>
                  : null}
              </div>

            </div>
          </div>
        </Tooltip>


        <Tooltip title="Product" arrow TransitionComponent={Zoom}>
          <div
            className={`
              menu-items 
              ${currentPath === '/product' ? 'active' : ''} 
              ${borderControl === 'chat' ? 'borderless-top' : ''} 
              ${borderControl === 'profile' ? 'borderless-bottom' : ''} 
            `}
            onClick={reRoute.bind('', "/product", "product")}
          >
            <div className="menu-items-child">
              <div className="item-image">
                <img src={productImage} alt="Product icon" className="image-white" />
                <img src={productImageBlack} alt="Inbox icon" className="image-black" />
              </div>
              <div className="item-name">Products</div>
            </div>
          </div>
        </Tooltip>


        <Tooltip
          title="Profile"
          arrow
          TransitionComponent={Zoom}
        >
          <div
            className={`
            menu-items 
            ${currentPath === '/profile' ? 'active' : ''} 
            ${borderControl === 'product' ? 'borderless-top' : ''} 
            ${borderControl === 'dashboard' ? 'borderless-bottom' : ''} 
          `}
            onClick={reRoute.bind('', "/profile", "profile")}
          >
            <div className="menu-items-child">
              <div className="item-image">
                <img src={profileImage} alt="profile icon" className="image-white" />
                <img src={profileImageBlack} alt="profile icon" className="image-black" />
              </div>
              <div className="item-name">Profile</div>
            </div>
          </div>
        </Tooltip>


        <Tooltip
          title="Dashboard"
          arrow
          TransitionComponent={Zoom}
        >
          <div
            className={`
              menu-items 
              ${currentPath === '/dashboard' ? 'active' : ''} 
              ${borderControl === 'profile' ? 'borderless-top' : ''} 
              ${borderControl === 'dashboard' ? 'borderless-bottom' : ''} 
            `}
            onClick={reRoute.bind('', "/dashboard", "dashboard")}
          >
            <div className="menu-items-child">
              <div className="item-image">
                <img src={dashboardImage} alt="dashboard icon" className="image-white" />
                <img src={dashboardImageBlack} alt="dashboard icon" className="image-black" />
              </div>
              <div className="item-name">Dashboard</div>
            </div>
          </div>
        </Tooltip>

        <div
          className={`
            menu-items disabled
            ${borderControl === 'dashboard' ? 'borderless-top' : ''} 
          `}
        >
          <div className="menu-items-child">
            <div className="item-image d-none">
              <img src={homeImage} alt="Home icon" className="image-white" />
              <img src={homeImageBlack} alt="Home icon" className="image-black" />
            </div>
            <div className="item-name"></div>
          </div>
        </div>

      </div>

    </>
  );
};

const mapStateToProps = (state) => ({
  sidebar: state.sidebar
});


export default connect(mapStateToProps)(Sidebar);